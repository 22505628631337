import { LexicalComposer } from '@lexical/react/LexicalComposer';
import WhatsappContent from '../../components/campaign-details/whatsapp';
import { WhatsAppEditorProvider } from '../../context/whatsapp-editor-context';
import { createWhatsAppEditorConfig } from '../../components/whatsapp-editor/nodes/WhatsAppNodes';

function WhatsappDetail() {
  return (
    <LexicalComposer initialConfig={createWhatsAppEditorConfig('whatsapp editor')}>
      <WhatsAppEditorProvider>
        <WhatsappContent />
      </WhatsAppEditorProvider>
    </LexicalComposer>
  );
}

export default WhatsappDetail;
