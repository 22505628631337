import {
  WhatsAppButtonGroupEnum,
  WhatsAppButtonTypeEnum,
  WhatsAppURLButtonTypeEnum
} from './button.interface';

export const BUTTON_LIMIT = {
  [WhatsAppButtonTypeEnum.PHONE_NUMBER]: 1,
  [WhatsAppButtonTypeEnum.URL]: 2
};

export const BUTTON_TOTAL_LIMIT = 10;

export const URL_BUTTON_MAP = {
  [WhatsAppURLButtonTypeEnum.DYNAMIC]: 'Dinamis',
  [WhatsAppURLButtonTypeEnum.STATIC]: 'Statis'
};

export const URL_BUTTON_TYPE_OPTIONS = [
  {
    key: WhatsAppURLButtonTypeEnum.DYNAMIC,
    label: URL_BUTTON_MAP[WhatsAppURLButtonTypeEnum.DYNAMIC]
  },
  {
    key: WhatsAppURLButtonTypeEnum.STATIC,
    label: URL_BUTTON_MAP[WhatsAppURLButtonTypeEnum.STATIC]
  }
];

export const CTA_BUTTON_MAP = {
  [WhatsAppButtonTypeEnum.PHONE_NUMBER]: 'Kontak',
  [WhatsAppButtonTypeEnum.URL]: 'Visit website'
};

export const BUTTON_GROUP = {
  [WhatsAppButtonGroupEnum.CTA]: [
    WhatsAppButtonTypeEnum.PHONE_NUMBER,
    WhatsAppButtonTypeEnum.URL,
    WhatsAppButtonTypeEnum.UNDEFINED
  ],
  [WhatsAppButtonGroupEnum.QUICK_REPLY]: [WhatsAppButtonTypeEnum.QUICK_REPLY]
};
