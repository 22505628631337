import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const EmailChannelSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" fill="none">
    <path
      d="M24.6673 8H7.33398C6.20898 8 5.33398 8.91667 5.33398 10V22C5.33398 23.125 6.20898 24 7.33398 24H24.6673C25.7507 24 26.6673 23.125 26.6673 22V10C26.6673 8.91667 25.7507 8 24.6673 8ZM24.6673 10V11.7083C23.709 12.5 22.209 13.6667 19.0423 16.1667C18.334 16.7083 16.959 18.0417 16.0007 18C15.0007 18.0417 13.6257 16.7083 12.9173 16.1667C9.75065 13.6667 8.25065 12.5 7.33398 11.7083V10H24.6673ZM7.33398 22V14.2917C8.25065 15.0417 9.62565 16.125 11.6673 17.75C12.584 18.4583 14.209 20.0417 16.0007 20C17.7507 20.0417 19.334 18.4583 20.2923 17.75C22.334 16.125 23.709 15.0417 24.6673 14.2917V22H7.33398Z"
      fill="#313131"
    />
  </svg>
);

const EmailChannelIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EmailChannelSVG} {...props} style={{ fontSize: '32px', ...props.style }} />
);

export default EmailChannelIcon;
