import { Divider, Tooltip } from 'antd';
import { ReactComponent as Clone } from '../../../assets/icons/clone.svg';
import { useState } from 'react';
function CodeCard({ curl }: any) {
  const [open, setOpen] = useState(false);
  const onCopyCode = () => {
    setOpen(true);
    navigator.clipboard.writeText(curl || '');
    setTimeout(() => {
      setOpen(false);
    }, 1000);
  };
  return (
    <div className="flex w-full rounded-lg bg-black-90">
      <div className="flex w-full shrink-0 flex-grow basis-0 flex-col items-start justify-center gap-2 p-4">
        <div className="flex items-center gap-1 text-green-500">
          <Clone />
          <Tooltip
            placement="bottom"
            color="#4C4C4C"
            arrow={false}
            title="copied"
            trigger={['click']}
            onOpenChange={onCopyCode}
            open={open}
          >
            <span
              className="cursor-pointer rounded-none border-0 border-b border-solid border-green-500 leading-4"
              onClick={onCopyCode}
            >
              Copy Code
            </span>
          </Tooltip>
        </div>
        <Divider className="m-0 bg-grey-100" />
        <p className="m-0 whitespace-pre-line font-Courier text-white-background">{curl}</p>
      </div>
    </div>
  );
}

export default CodeCard;
