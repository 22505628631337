import { Icon } from '@squantumengine/horizon';
import { CommentInterface } from '../checker.interface';

interface CommentBoxTriggerProps {
  comment?: CommentInterface;
  number?: number;
}

const CommentBoxTrigger = ({ comment, number }: Readonly<CommentBoxTriggerProps>) => {
  return !comment ? (
    <span className="z-10">
      <Icon name="plus" color="white" />
    </span>
  ) : (
    <span className="z-10 text-center text-base leading-6 text-white">{number}</span>
  );
};

export default CommentBoxTrigger;
