import { FileUploadInterface } from '../../../file-upload/file-upload.interface';

export enum EmailAttachmentErrorEnum {
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',
  INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
  FAILED_TO_UPLOAD = 'FAILED_TO_UPLOAD'
}

export interface EmailAttachmentInterface {
  // this id doesn't necessarily maps to the BE id. it's just a unique identifier.
  // the actual BE id is defined on meta when the file is already uploaded
  id: string;
  size: number;
  meta: FileUploadInterface;
  errorType?: EmailAttachmentErrorEnum;
  isLoading: boolean;
  thumbnail: string;
}

export interface EmailAttachmentInterfaceWithFile extends EmailAttachmentInterface {
  file: File;
}
