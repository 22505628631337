import { useState } from 'react';
import { getContactUploadUrl } from '../api/contact';
import { handleAxiosError } from '../api/error-handler/error-handler';
import {
  getEmailDownloadUrl,
  getEmailPresignedUrl,
  getSoundPresignedUrl,
  getWhatsAppThumbnail,
  pushNotifPresignedUrl,
  uploadFile
} from '../api/file-upload';
import { getUploadUnsubscribePresignedUrl } from '../api/unsubscribe';
import { getPresignedURL } from '../api/whatsapp';
import { FileUploadInterface } from '../components/file-upload/file-upload.interface';
import { useToastMessage } from '../hooks/useQueryHelper';
import { GetPresignedURLResponseInterface } from '../shared/automated-campaign.interface';
import { UploadEmailAttachmentResponse } from '../shared/file-upload.interface';
import { getFileExtension } from './fileUtils';
import { retry } from './retryAPI';
import { createPushNotifSound } from '../api/sounds';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_ENUM } from '../static/query-keys.enum';

const getFileData = (file: File) => {
  return {
    fileName: file.name,
    contentType: file.type,
    fileExtension: getFileExtension(file.name),
    size: file.size
  };
};

async function uploadToSignedUrl(
  file: File,
  signedUrl?: { message: string; data?: GetPresignedURLResponseInterface }
) {
  const { contentType } = getFileData(file);
  if (!signedUrl?.data?.upload_url) throw new Error();
  const { upload_url, id } = signedUrl.data;
  await uploadFile(file, upload_url, contentType);
  return { url: upload_url, id };
}

const fetchWhatsAppThumbnail = async (id: string) => {
  const response = await getWhatsAppThumbnail(id);
  if (!response?.data?.thumbnail) throw new Error();
  return response;
};

// initially created for WhatsApp templates
export const uploadToCloudStorage = async (file: File, base64: string) => {
  const { fileName, contentType, fileExtension } = getFileData(file);
  const resPresignedUrl = await getPresignedURL({
    name: fileName,
    extension: fileExtension
  });

  if (!resPresignedUrl) throw new Error();

  const url = resPresignedUrl.upload_url;
  await uploadFile(file, url, contentType);

  const resThumbnail = await retry(fetchWhatsAppThumbnail, [resPresignedUrl.id], 10);

  const responseData: FileUploadInterface = {
    id: resPresignedUrl.id,
    url: resThumbnail.data.thumbnail,
    name: fileName
  };

  return responseData;
};

// created for Push Notification image file
export const uploadPushNotificationImage = async (file: File, base64: string) => {
  const { fileName, fileExtension } = getFileData(file);
  const resPresignedUrl = await pushNotifPresignedUrl({
    name: fileName,
    extension: fileExtension
  });

  const { id } = await uploadToSignedUrl(file, resPresignedUrl);
  const responseData: FileUploadInterface = {
    id,
    url: base64,
    name: fileName
  };

  return responseData;
};

export const emailUploadHandler = async (
  file: File,
  fn: (params: { name: string; extension: string }) => Promise<UploadEmailAttachmentResponse>
) => {
  const { fileName: name, fileExtension: extension } = getFileData(file);
  const resPresignedUrl = await fn({ name, extension });
  return await uploadToSignedUrl(file, resPresignedUrl);
};

// created for Email template images & files
export const uploadEmailFile = async (file: File): Promise<FileUploadInterface> => {
  const { id } = await emailUploadHandler(file, getEmailPresignedUrl);
  const { download_url } = await retry(getEmailDownloadUrl, [id], 10);
  return { id, url: download_url, name: file.name };
};

export const uploadContactFile = async (file: File) => {
  const { fileName: name } = getFileData(file);
  const resPresignedUrl = await getContactUploadUrl({ name });
  const response = await uploadToSignedUrl(file, resPresignedUrl);

  const responseData: FileUploadInterface = {
    ...response,
    name,
    file
  };

  return responseData;
};

export const uploadUnsubscribeFile = async (file: File) => {
  const { fileName: name } = getFileData(file);
  const resPresignedUrl = await getUploadUnsubscribePresignedUrl(name);
  const response = await uploadToSignedUrl(file, resPresignedUrl);
  const responseData: FileUploadInterface = { ...response, name };
  return responseData;
};

function formatFileSize(bytes: number) {
  if (bytes < 1024 * 1024) {
    const kb = bytes / 1024; // 1 KB = 1024 bytes
    return `${kb.toFixed(1)}KB`;
  } else {
    const mb = bytes / (1024 * 1024); // 1 MB = 1024 * 1024 bytes
    return `${mb.toFixed(1)}MB`;
  }
}
export const useUploadSoundFile = () => {
  const queryClient = useQueryClient();
  const { toastMessage } = useToastMessage();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showBar, setShowBar] = useState(false);
  const [fileSize, setFileSize] = useState('');
  const [fileName, setFileName] = useState('');
  const handleShowBar = () => {
    setShowBar((prev) => !prev);
    setIsSuccess(false);
    setLoading(false);
    setFileSize('');
    setFileName('');
  };
  const handleUploadSound = async (file: File) => {
    try {
      setLoading(true);
      setShowBar(true);
      const { fileName: name, size } = getFileData(file);
      const formatedSize = formatFileSize(size);
      setFileSize(formatedSize);
      setFileName(name);
      const resPresignedUrl = await getSoundPresignedUrl(name);
      const response = await uploadToSignedUrl(file, resPresignedUrl);
      await retry(createPushNotifSound, [response.id], 5, undefined, 400, 2);
      setIsSuccess(true);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.getSoundList] });
      toastMessage('success', 'Sukses upload suara');
    } catch (error) {
      handleShowBar();
      const message = handleAxiosError(error);
      setIsSuccess(false);
      toastMessage('error', message);
    } finally {
      setLoading(false);
    }
  };
  return {
    handleUploadSound,
    handleShowBar,
    loading,
    isSuccess,
    showBar,
    fileSize,
    fileName
  };
};
