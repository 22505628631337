const baseTextStyle = `
.PlaygroundEditorTheme__paragraph,
.SQEMP_EmailEditorTheme__paragraph {
  min-height: 25.5px;
  margin: 0;
  position: relative;
}

.SQEMP_EmailEditorTheme__paragraph {
  text-align: center;
}

.PlaygroundEditorTheme__textBold,
.SQEMP_EmailEditorTheme__textBold {
  font-weight: bold;
}

.PlaygroundEditorTheme__textItalic,
.SQEMP_EmailEditorTheme__textItalic {
  font-style: italic;
}

.PlaygroundEditorTheme__textCode,
.SQEMP_EmailEditorTheme__textCode {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

hr {
  padding: 1px 1px;
  border: none;
  margin: 16px 0;
  cursor: pointer;
  background-color: #ccc;
}
`;

export default baseTextStyle;
