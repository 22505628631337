import { Button, Icon, Notification, Token } from '@squantumengine/horizon';
import { Badge, Dropdown } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { useGetNotificationList } from '../../../hooks/notification/useGetNotificationList';
import useInfiniteScrollDropdown from '../../../hooks/useInfiniteScrollDropdown';
import {
  NotificationCountResponse,
  NotificationInterface
} from '../../../shared/notification.interface';
import { normalizeNotificationList } from '../../../utils/notification';
import useNotificationComponent, { NotificationActionEnum } from './useNotificationComponent';
import { useGetNotificationCount } from '../../../hooks/notification/useGetNotificationCount';

const NotificationComponent = () => {
  const notificationsRef = useRef<HTMLUListElement>(null);
  const [openNotif, setOpenNotif] = useState<boolean>(false);
  const queryData = useGetNotificationList();
  const notificationCount = useGetNotificationCount();
  const dropdownData = useInfiniteScrollDropdown<NotificationInterface>(queryData);
  const notificationList = useMemo(
    () => normalizeNotificationList(dropdownData.data),
    [dropdownData.data]
  );
  const { onClick, onActionClick } = useNotificationComponent({ setOpenNotif });
  const parsedNotificationCount: number = (notificationCount.data as NotificationCountResponse)
    .unseen;

  return (
    <Dropdown
      open={openNotif}
      onOpenChange={setOpenNotif}
      trigger={['click']}
      dropdownRender={() => (
        <div className="flex w-[388px] self-start overflow-hidden rounded-xl bg-white-100 shadow-options">
          <Notification sync>
            <Notification.Header
              title="Notifications"
              unreadCount={parsedNotificationCount}
              actions={[{ key: NotificationActionEnum.READ, label: 'Mark all as read' }]}
              onActionClick={onActionClick}
            />
            <Notification.List
              notifications={notificationList}
              onRender={({ notification }) => (
                <Notification.Card {...notification} onClick={onClick} />
              )}
              className="max-h-[50vh] overflow-auto"
              ref={notificationsRef}
              onScroll={dropdownData.handleScroll}
            />
          </Notification>
        </div>
      )}
    >
      <Button variant="text" className="h-fit rounded-full p-1">
        <Badge color={Token.COLORS.red[500]} count={parsedNotificationCount}>
          <Icon name="bell" size="lg" />
        </Badge>
      </Button>
    </Dropdown>
  );
};

export default NotificationComponent;
