import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CLEAR_HISTORY_COMMAND, LexicalEditor } from 'lexical';
import { useCallback, useEffect } from 'react';

export const setInitialEditorState = (editor: LexicalEditor, jsonString: string) => {
  const editorJSONState = editor.parseEditorState(jsonString);
  editor.setEditorState(editorJSONState);

  // reset state after initialization
  editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
};

function useInitialJson(initialJson?: string) {
  const [editor] = useLexicalComposerContext();

  const initializeEditorContent = useCallback(
    (jsonString: string) => setInitialEditorState(editor, jsonString),
    [editor]
  );

  useEffect(() => {
    if (initialJson) {
      initializeEditorContent(initialJson);
    }
  }, [initializeEditorContent, initialJson]);
}

export default useInitialJson;
