import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const GreenCheckCircleSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      d="M16.0001 3.16675C8.64008 3.16675 2.66675 9.14008 2.66675 16.5001C2.66675 23.8601 8.64008 29.8334 16.0001 29.8334C23.3601 29.8334 29.3334 23.8601 29.3334 16.5001C29.3334 9.14008 23.3601 3.16675 16.0001 3.16675ZM12.3867 22.2201L7.60008 17.4334C7.08008 16.9134 7.08008 16.0734 7.60008 15.5534C8.12008 15.0334 8.96008 15.0334 9.48008 15.5534L13.3334 19.3934L22.5067 10.2201C23.0267 9.70008 23.8668 9.70008 24.3867 10.2201C24.9067 10.7401 24.9067 11.5801 24.3867 12.1001L14.2667 22.2201C13.7601 22.7401 12.9067 22.7401 12.3867 22.2201Z"
      fill="#007869"
    />
  </svg>
);

const GreenCheckCircleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={GreenCheckCircleSVG} {...props} />
);

export default GreenCheckCircleIcon;
