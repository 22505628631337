import { Button, Icon, Token } from '@squantumengine/horizon';
import ScopeWrapper from '../../../components/role-wrapper/scope-wrapper';
import { ALL_CHANNEL_SCOPES, AuthActionScopeEnum } from '../../../shared/user.interface';
import { useCampaignDetailContext } from '../context/campaign-detail-context';
import { ReactNode } from 'react';
import PageHeaderAutomatedActions from './page-header-automated-actions';
import { CampaignTypeEnum } from '../../../shared/automated-campaign.interface';
import { Link } from 'react-router-dom';
import useCampaignParams from '../../../hooks/useCampaignParams';

export const IconWrapper = ({ children }: { children: ReactNode }) => (
  <span className="h-6 w-6 shrink-0 -translate-x-1">{children}</span>
);

const ButtonContent = () => {
  return (
    <>
      <span className="h-6 w-6 shrink-0 -translate-x-1 -translate-y-[2px]">
        <Icon name="plus" color={Token.COLORS.neutral[0]} />
      </span>
      <span>Buat Konten Campaign</span>
    </>
  );
};

const PageHeaderCreateButton = () => {
  const { openModal, campaignDetail, channelList } = useCampaignDetailContext();
  const { campaignType } = useCampaignParams();
  const campaignTag = campaignDetail?.tag;

  if (campaignTag && !channelList.length) {
    return (
      <ScopeWrapper scope={AuthActionScopeEnum.CHANNEL_WA_READ}>
        <Link to={`/campaign/${campaignType}/whatsapp`}>
          <Button variant="primary">
            <ButtonContent />
          </Button>
        </Link>
      </ScopeWrapper>
    );
  }

  if (!campaignTag && channelList.length < 10) {
    return (
      <ScopeWrapper scope={ALL_CHANNEL_SCOPES.CHANNEL_CREATE}>
        <Button variant="primary" onClick={openModal}>
          <ButtonContent />
        </Button>
      </ScopeWrapper>
    );
  }

  return null;
};

const PageHeaderActions = () => {
  const { campaignType } = useCampaignParams();

  return (
    <div className="flex items-center gap-3">
      {campaignType === CampaignTypeEnum.AUTOMATED && <PageHeaderAutomatedActions />}
      <PageHeaderCreateButton />
    </div>
  );
};

export default PageHeaderActions;
