import { useMemo, useState } from 'react';
import TestDataListItem from './test-data-list-item';
import DropdownToggleIcon from '../../../components/whatsapp-editor/editor/icons/dropdown-toggle-icon';

interface TestDataListProps {
  testDataList: string[];
  onDelete: (testData: string) => void;
}

const COLLAPSE_LENGTH = 3;

function TestDataList({ testDataList, onDelete }: TestDataListProps) {
  const [showAll, setShowAll] = useState<boolean>(false);

  const hasEnough = useMemo(() => testDataList.length > COLLAPSE_LENGTH, [testDataList]);
  const renderedTestDatas = useMemo(
    () => testDataList.slice(0, showAll ? testDataList.length : COLLAPSE_LENGTH),
    [testDataList, showAll]
  );

  const toggleSetShowAll = () => {
    setShowAll((prev) => !prev);
  };

  return (
    <div className="flex w-full flex-col gap-3">
      {renderedTestDatas.map((testData) => (
        <TestDataListItem key={testData} testData={testData} onDelete={onDelete} />
      ))}
      {hasEnough && (
        <div
          onClick={toggleSetShowAll}
          className="flex w-full cursor-pointer items-center justify-center gap-2 rounded-md p-2 text-blue-500 hover:bg-grey-10"
        >
          {showAll ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          <DropdownToggleIcon isCollapsed={!showAll} />
        </div>
      )}
    </div>
  );
}

export default TestDataList;
