import { Button, Icon, Token } from '@squantumengine/horizon';
import { Popover } from 'antd';
import { MouseEventHandler, useState } from 'react';
import ScopeWrapper from '../../role-wrapper/scope-wrapper';
import { logicalOperatorWrapper } from '../../../utils/logicalOperator';
import { AuthActionScopeEnum } from '../../../shared/user.interface';

const { OR } = logicalOperatorWrapper;

interface CommentBoxHeaderInterface {
  isOpen: boolean;
  isEdit: boolean;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onResolved?: () => void;
  isResolved?: boolean;
  isCanEditDelete?: boolean;
}

const CommentBoxHeader = ({
  isOpen,
  isEdit,
  onClickDelete,
  onClickEdit,
  onResolved,
  isResolved,
  isCanEditDelete
}: Readonly<CommentBoxHeaderInterface>) => {
  const [openMenu, setOpenMenu] = useState(false);

  if (!isOpen && !isEdit) return null;

  const handleOpenMenuChange: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setOpenMenu(!openMenu);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="text-base font-bold text-blue-600">Komentar</div>
      {!isEdit && (
        <div className="flex gap-2">
          {!isResolved && (
            <ScopeWrapper scope={AuthActionScopeEnum.COMMENT_RESOLVE}>
              <Button
                data-testid="resolve-comment"
                onClick={onResolved}
                variant="text"
                className="h-6 w-6 rounded-none px-0"
              >
                <Icon name="check" color={Token.COLORS.blue[500]} />
              </Button>
            </ScopeWrapper>
          )}
          <ScopeWrapper
            scope={OR(AuthActionScopeEnum.COMMENT_DELETE, AuthActionScopeEnum.COMMENT_WRITE)}
          >
            <Popover
              open={openMenu}
              trigger="click"
              placement="bottomRight"
              onOpenChange={setOpenMenu}
              content={
                <div className="flex flex-col">
                  <ScopeWrapper scope={AuthActionScopeEnum.COMMENT_DELETE}>
                    <Button
                      variant="text"
                      onClick={(e) => {
                        onClickEdit();
                        handleOpenMenuChange(e);
                      }}
                      className="min-w-40 justify-start px-2 font-normal text-black-rich hover:text-blue-600"
                    >
                      Edit komentar
                    </Button>
                  </ScopeWrapper>
                  <ScopeWrapper scope={AuthActionScopeEnum.COMMENT_WRITE}>
                    <Button
                      variant="text"
                      onClick={(e) => {
                        onClickDelete();
                        handleOpenMenuChange(e);
                      }}
                      className="min-w-40 justify-start px-2 font-normal text-black-rich hover:text-blue-600"
                    >
                      Hapus komentar
                    </Button>
                  </ScopeWrapper>
                </div>
              }
            >
              {isCanEditDelete && (
                <Button
                  variant="text"
                  className="h-6 w-6 rounded-none px-0"
                  data-testid="open-comment-menu-button"
                  onClick={handleOpenMenuChange}
                >
                  <Icon name="kebab-vertical" />
                </Button>
              )}
            </Popover>
          </ScopeWrapper>
        </div>
      )}
    </div>
  );
};

export default CommentBoxHeader;
