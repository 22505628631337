import axiosInstance from '../config/axios/axiosWithToken';
import { GetSettingsInterface } from '../shared/settings.interface';

export const setCampaignApprovalConfig = async (approval: boolean) => {
  await axiosInstance.patch('settings/campaign_approval', { campaign_approval: approval });
};

export const getSettings = async (): Promise<GetSettingsInterface> => {
  const response = await axiosInstance.get('settings');
  return response.data.data;
};
