import { useCallback, useEffect } from 'react';
import { useEditorConfigContext } from '../../../../../context/editor-config-context';
import { EmailDetailInterface } from '../../../../../shared/automated-campaign.interface';
import { attachmentListToMap } from '../../AttachmentPlugin/attachment-plugin.helper';

export const useGenerateChannelDetail = ({
  channelDetail,
  channelId
}: Readonly<{
  channelDetail?: EmailDetailInterface;
  channelId: string;
}>) => {
  const {
    setTitle,
    setDescription,
    setSenderId,
    setEmailSubject,
    initPrecodingMap,
    setCategory,
    setAttachments
  } = useEditorConfigContext();

  const generateChannelDetail = useCallback(() => {
    if (channelDetail) {
      const { name, description, sender, template, attachment } = channelDetail;

      setDescription(description);
      setTitle(name);
      setSenderId(sender.id);
      setEmailSubject(template.subject);
      initPrecodingMap(template.precoding_example);
      setCategory(template.category);
      setAttachments(attachmentListToMap(attachment));
    }
  }, [
    channelDetail,
    setDescription,
    setTitle,
    setSenderId,
    setEmailSubject,
    initPrecodingMap,
    setCategory,
    setAttachments
  ]);

  useEffect(() => {
    if (channelId) {
      generateChannelDetail();
    }
  }, [channelId, generateChannelDetail]);
};
