import { useNavigate } from 'react-router';
import { useToastMessage } from '../../hooks/useQueryHelper';
import { useCheckerContext } from '../checker/checker-context';
import useModal from '../../hooks/useModal';
import { useSetCheckerReviewAction } from '../../hooks/checker-maker-query/useSetCheckerMakerReviewAction';
import { getErrorMessage } from '../../utils/error';
import useCampaignParams from '../../hooks/useCampaignParams';

const useHeaderActionComment = () => {
  const navigate = useNavigate();
  const { campaignType } = useCampaignParams();
  const confirmApproveModal = useModal();
  const confirmRejectModal = useModal();

  const { openToast } = useToastMessage();
  const { disabled, setDisabled, reviewId, channelType } = useCheckerContext();
  const toggleCommentMode = () => setDisabled((prev) => !prev);
  const { mutate, isPending } = useSetCheckerReviewAction(channelType, reviewId);

  const returnToCampaignPage = () => {
    confirmApproveModal.closeModal();
    confirmRejectModal.closeModal();
    navigate(`/campaign/${campaignType}`);
  };

  const onError = (error: unknown) => {
    openToast({
      isError: true,
      label: `Terjadi kesalahan: ${getErrorMessage(error)}`
    });
  };

  const approveChannel = () => {
    mutate('approve', {
      onSuccess: confirmApproveModal.openModal,
      onError
    });
  };

  const rejectChannel = () => {
    mutate('decline', {
      onSuccess: returnToCampaignPage,
      onError
    });
  };

  return {
    disabled,
    confirmApproveModal,
    confirmRejectModal,
    returnToCampaignPage,
    toggleCommentMode,
    approveChannel,
    rejectChannel,
    isLoading: isPending
  };
};

export default useHeaderActionComment;
