import { PrecodingNode } from '../../rich-text/nodes/PrecodingNode';
import PlaygroundEditorTheme from '../../rich-text/themes/PlaygroundEditorTheme';
import { EditorThemeClasses } from 'lexical/LexicalEditor';

const WhatsAppNodes = [PrecodingNode];

export const createWhatsAppEditorConfig = (
  namespace: string,
  theme: EditorThemeClasses = PlaygroundEditorTheme
) => ({
  editorState: undefined,
  namespace,
  nodes: WhatsAppNodes,
  // eslint-disable-next-line no-console
  onError: console.error,
  theme
});
