import { RichTextPrecodingMap } from '../components/rich-text-input/rich-text-input.interface';
import { PrecodingInterface } from '../shared/precoding.interface';

export const precodingArrayToMap = (precodingArray: PrecodingInterface[]) => {
  const map: RichTextPrecodingMap = {};
  precodingArray.forEach(({ example, key }) => {
    map[key] = example;
  });
  return map;
};

export const precodingMapToArray = (precodingMap: RichTextPrecodingMap) => {
  if (precodingMap && Object.keys(precodingMap).length === 0) return [];
  return Object.keys(precodingMap).map((key) => ({
    key,
    example: precodingMap[key]
  }));
};
