import axiosInstance from '../config/axios/axiosWithToken';
import {
  AuditExportParamsInterface,
  AuditLogParamsInterface,
  AuditLogResponseInterface
} from '../shared/audit.interface';

export const fetchAuditLog: (
  params: AuditLogParamsInterface
) => Promise<AuditLogResponseInterface> = async (params) => {
  const response = await axiosInstance.get('auth/audit/log', { params });
  return response.data;
};

export const exportAuditLog: (params: AuditExportParamsInterface) => Promise<any> = async ({
  from,
  to
}) => {
  const response = await axiosInstance.get(`auth/audit/export?from=${from}&to=${to}`);
  return response.data.data;
};

export const fetchAuditExportStatus: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.get(`auth/audit/export/${id}/status`);
  return response.data.data;
};
