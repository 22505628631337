import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { readNotification } from '../../api/notification';

export const useReadNotification = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.readNotification],
    mutationFn: (id?: string) => readNotification(id || ''),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.getNotificationList] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.getNotificationCount] });
    }
  });
};
