import dayjs from 'dayjs';
import { useWhatsappEditorContext } from '../../../../context/whatsapp-editor-context';

function FooterPreview() {
  const { state } = useWhatsappEditorContext();
  return (
    <div className="flex items-end justify-between gap-1.5 self-stretch">
      <span className="text-xs text-grey-100">{state.footer}</span>
      <span className="min-w-fit text-xs text-grey-100">{dayjs(new Date()).format('hh:mm')}</span>
    </div>
  );
}

export default FooterPreview;
