import axiosInstance from '../config/axios/axiosWithToken';
import {
  DeleteSoundInterface,
  SoundDetailResponseInterface,
  SoundListInterface,
  SoundListQuery
} from '../shared/sound.interface';

export const getSoundList: (query: SoundListQuery) => Promise<SoundListInterface> = async (
  query
) => {
  const { page, limit = 100, q = '' } = query;
  const response = await axiosInstance.get(
    `push_notification/sound?page=${page}&limit=${limit}&q=${q}`
  );
  return response.data;
};

export const deleteSound: (id: string) => Promise<DeleteSoundInterface> = async (id) => {
  const response = await axiosInstance.delete(`push_notification/sound/${id}`);
  return response.data;
};

export const getSoundDetail: (id: string) => Promise<SoundDetailResponseInterface> = async (id) => {
  const response = await axiosInstance.get(`push_notification/sound/${id}`);
  return response.data;
};

export const createPushNotifSound: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.post(`push_notification/sound`, { file_id: id });
  return response.data;
};
