import { useMemo } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { isScopeAuthorized, RouteScopeType } from '../../utils/route';

const useIsScopeAuthorized = (scope: RouteScopeType) => {
  const { userAccess } = useAuth();

  const hasAccess = useMemo(() => {
    return isScopeAuthorized(scope, userAccess.scopes);
  }, [scope, userAccess.scopes]);

  return hasAccess;
};

export default useIsScopeAuthorized;
