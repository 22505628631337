import NotFoundImg from '../../assets/image/Photo-min.png';
import Button from '../../components/buttons';
import { ArrowLeft } from '../../assets/icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

export const REDIRECT_TO_403_KEY = 'redirectTo403';

function Page403() {
  const navigate = useNavigate();

  const onClickBack = useCallback(() => {
    const isRedirected = !!sessionStorage.getItem(REDIRECT_TO_403_KEY);
    const referrer = document.referrer;

    if (isRedirected) {
      sessionStorage.removeItem(REDIRECT_TO_403_KEY);
      navigate(-2);
      return;
    }

    if (referrer.indexOf(window.location.host) !== -1) {
      navigate(-1);
      return;
    }

    navigate('/campaign');
  }, [navigate]);

  return (
    <div data-testid="403-page" className="flex h-screen flex-col gap-8 p-6">
      <button
        className="w-fit cursor-pointer rounded-md border-none bg-transparent p-2 outline-none hover:bg-gray-100"
        onClick={onClickBack}
        data-testid="back-button"
      >
        <ArrowLeft />
      </button>
      <div className="flex w-full flex-col items-center gap-6 rounded-xl border border-solid border-grey-50 py-6">
        <img src={NotFoundImg} alt="not-found" className="md:w-[360px] w-[300px]" />
        <p className="my-0 max-w-[700px] text-center text-2xl font-bold text-black-90">
          Akun Anda tidak memiliki akses ke halaman ini.
        </p>
        <p className="my-0 max-w-[700px] text-center text-black-90">
          Segera hubungi administrator untuk mendapatkan akses.
        </p>
        <Button onClick={onClickBack} type="primary" size="large" className="px-12">
          Kembali
        </Button>
      </div>
    </div>
  );
}

export default Page403;
