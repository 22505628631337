import { useCallback } from 'react';
import { downloadFileFromUrl } from '../../../../../utils/download';
import { FileUploadInterface } from '../../../../file-upload/file-upload.interface';

const AttachmentDownloadWrapper = ({
  file,
  children
}: Readonly<{
  file: FileUploadInterface;
  children: React.ReactNode;
}>) => {
  const handleDownload = useCallback(() => {
    downloadFileFromUrl(file.url, file.name);
  }, [file]);

  return (
    <div onClick={handleDownload} className="cursor-pointer">
      {children}
    </div>
  );
};

export default AttachmentDownloadWrapper;
