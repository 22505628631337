import { Spin } from 'antd';
import './loader.css';
import { HTMLProps } from 'react';

function Loader(props: Readonly<HTMLProps<HTMLDivElement>>) {
  return (
    <div
      {...props}
      className={'flex h-[50vh] w-full items-center justify-center ' + props.className}
      data-testid="loader-el"
    >
      <Spin size="large" />
    </div>
  );
}

export default Loader;
