import { ReactNode, useMemo } from 'react';
import TestDataList from './test-data-list';

interface LeftSectionProps {
  children: ReactNode;
  testDataSet: Set<string>;
  setTestDataSet: React.Dispatch<React.SetStateAction<Set<string>>>;
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  maximumTestReceiverError: string;
  onSubmitValidation?: () => boolean;
}

function LeftSection({
  children,
  testDataSet,
  setTestDataSet,
  input,
  setInput,
  setErrorMessage,
  maximumTestReceiverError,
  onSubmitValidation
}: LeftSectionProps) {
  const testDataArray = useMemo(() => Array.from(testDataSet), [testDataSet]);
  const allowAddTestData = useMemo(() => testDataArray.length < 10, [testDataArray]);

  const addTestData = (testData: string) => {
    if (!testData || testDataSet.has(testData)) return;
    setTestDataSet((prev) => {
      prev.add(testData);
      return new Set(prev);
    });
  };

  const removeTestData = (testData: string) => {
    setTestDataSet((prev) => {
      prev.delete(testData);
      return new Set(prev);
    });
    setErrorMessage('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!input) return;
    if (!allowAddTestData) {
      setErrorMessage(maximumTestReceiverError);
      return;
    }
    if (onSubmitValidation && onSubmitValidation() === false) return;
    addTestData(input);
    setInput('');
  };

  return (
    <div className="flex h-fit w-80 flex-col gap-4 rounded-lg bg-white px-4 py-6 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.04)]">
      <div className="flex h-fit w-full flex-col gap-6" data-testid="left-section">
        <div className="flex w-full flex-col gap-2">
          <form onSubmit={handleSubmit}>
            {children}
            <button type="submit" className="hidden" />
          </form>
          <TestDataList testDataList={testDataArray} onDelete={removeTestData} />
        </div>
      </div>
    </div>
  );
}

export default LeftSection;
