import { NoticeType } from 'antd/es/message/interface';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getWhatsAppSenderList } from '../../api/whatsapp';
import { getErrorMessage } from '../../utils/error';

export const useGetWhatsAppSenderList = (
  toastMessage?: (type: NoticeType, message: string) => void
) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getWhatsAppSenderList],
    queryFn: async () => {
      try {
        return (await getWhatsAppSenderList()).data;
      } catch (error) {
        toastMessage?.('error', `Gagal memuat data pengirim: ${getErrorMessage(error)}`);
      }
    }
  });
};
