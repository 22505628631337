import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EditorConfigContextInterface } from './editor-config-context.interface';
import { RichTextPrecodingMap } from '../components/rich-text-input/rich-text-input.interface';
import { PrecodingInterface } from '../shared/precoding.interface';
import { precodingArrayToMap } from '../utils/precoding';
import {
  CampaignTypeEnum,
  EmailDetailInterface,
  SendersInterface
} from '../shared/automated-campaign.interface';
import { useGetSenderList } from '../hooks/automated-campaign-query/useGetListSender';
import useInfiniteScrollDropdown from '../hooks/useInfiniteScrollDropdown';
import { useGetCategoryList } from '../hooks/category-query/useGetCategoryList';
import { CategoryInterface } from '../shared/category.interface';
import { EmailAttachmentInterface } from '../components/rich-text/plugins/AttachmentPlugin/attachment-plugin.interface';
import useCampaignParams from '../hooks/useCampaignParams';

export const EditorConfigContext = createContext<EditorConfigContextInterface>(
  {} as EditorConfigContextInterface
);

const INITIAL_TEMPLATE_WIDTH = 600;

export const EditorConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const campaignURLParams = useCampaignParams();
  const [senderId, setSenderId] = useState<string | null>(null);
  const [category, setCategory] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorForm, setErrorForm] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailPreheader, setEmailPreheader] = useState<string>('');
  const [maxWidth, setMaxWidth] = useState<number | undefined>(INITIAL_TEMPLATE_WIDTH);
  const [campaignType, setCampaignType] = useState<CampaignTypeEnum>(
    campaignURLParams.campaignType as CampaignTypeEnum
  );
  const [precodingMap, setPrecodingMap] = useState<RichTextPrecodingMap>({});
  const [initialEmailChannelData, setInitialEmailChannelData] =
    useState<EmailDetailInterface | null>(null);
  const [emailSize, setEmailSize] = useState<number>(0);
  const [attachments, setAttachments] = useState<Map<string, EmailAttachmentInterface>>(new Map());
  const queryData = useGetSenderList();
  const { handleScroll, data } = useInfiniteScrollDropdown<SendersInterface>(queryData);

  const senderList = useMemo(() => {
    return data?.map((item: SendersInterface) => ({
      label: item.email,
      value: item.id
    }));
  }, [data]);

  const categoryData = useGetCategoryList();
  const { handleScroll: handleScrollCategory, data: dataCategory } =
    useInfiniteScrollDropdown<CategoryInterface>(categoryData);

  const categoryList = useMemo(() => {
    return dataCategory?.map((item: CategoryInterface) => ({
      label: item.name,
      value: item.name
    }));
  }, [dataCategory]);

  const initPrecodingMap = useCallback((precodingArray: PrecodingInterface[]) => {
    setPrecodingMap(precodingArrayToMap(precodingArray));
  }, []);

  // set default category
  useEffect(() => {
    setCategory((prev) => {
      if (prev) return prev;
      return categoryList?.[0]?.value ?? null;
    });
  }, [categoryList, category]);

  const contextValue = useMemo(() => {
    return {
      campaignType,
      category,
      categoryList,
      description,
      emailPreheader,
      emailSize,
      setEmailSize,
      emailSubject,
      error,
      errorForm,
      handleScroll,
      handleScrollCategory,
      initialEmailChannelData,
      initPrecodingMap,
      maxWidth,
      precodingMap,
      senderId,
      senderList,
      setCampaignType,
      setCategory,
      setDescription,
      setEmailPreheader,
      setEmailSubject,
      setError,
      setErrorForm,
      setInitialEmailChannelData,
      setMaxWidth,
      setPrecodingMap,
      setSenderId,
      setTitle,
      title,
      attachments,
      setAttachments
    };
  }, [
    campaignType,
    category,
    categoryList,
    description,
    emailPreheader,
    emailSize,
    emailSubject,
    error,
    errorForm,
    handleScroll,
    handleScrollCategory,
    initialEmailChannelData,
    initPrecodingMap,
    maxWidth,
    precodingMap,
    senderId,
    senderList,
    title,
    attachments
  ]);

  return (
    <EditorConfigContext.Provider value={contextValue}>{children}</EditorConfigContext.Provider>
  );
};

export const useEditorConfigContext = () => useContext(EditorConfigContext);
