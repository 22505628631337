import useSimasTokens, { useLogoutUserApp } from '@squantumengine/sqeid-react-browser';
import { useLogout } from './auth-query';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router';
import { useToastMessage } from './useQueryHelper';
import { handleAxiosError } from '../api/error-handler/error-handler';
import { useState } from 'react';

const useLogoutHook = (savePath: boolean = false) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { mutateAsync } = useLogout();
  const { toastMessage } = useToastMessage();
  const logoutSQEID = useLogoutUserApp();
  const simasToken = useSimasTokens();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await mutateAsync({ refresh_token: simasToken.refreshToken ?? '' });
      logout();
      logoutSQEID({ successCallbackFn: savePath ? undefined : () => navigate('/login') });
    } catch (error) {
      const message = handleAxiosError(error);
      toastMessage('error', message);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleLogout, isLoading };
};

export default useLogoutHook;
