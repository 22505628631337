import { LexicalEditor } from 'lexical';
import { RichTextPrecodingMap } from '../rich-text-input/rich-text-input.interface';
import { useCallback, useEffect, useState } from 'react';
import { parseEditorToString } from '../../utils/lexical';

function useEditorListener(editor: LexicalEditor, precodingMap: RichTextPrecodingMap) {
  const [editorContent, setEditorContent] = useState<string>('');

  const generateEditorContent = useCallback(() => {
    setEditorContent(parseEditorToString(editor, precodingMap));
  }, [editor, precodingMap]);

  useEffect(() => {
    generateEditorContent();

    editor.registerUpdateListener(generateEditorContent);
  }, [generateEditorContent, editor]);

  // might return undefined on unit test when lexical is mocked
  return editorContent ?? '';
}

export default useEditorListener;
