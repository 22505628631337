import { NoticeType } from 'antd/lib/message/interface';
import { HandleErrorEnum } from '../static/add-channel-modal.enum';
import { ChannelInterface, ChannelKeyEnum } from './master-api.interface';
import { PrecodingInterface } from './precoding.interface';
import { PaginatedAPIResponseInterface } from './general.interface';
import { CampaignTagBackendEnum } from './campaign.interface';
import { TaggingInterface } from './tagging-config.interface';

export enum CampaignTypeEnum {
  AUTOMATED = 'automated',
  MANUAL = 'manual'
}

export enum CampaignSortByEnum {
  CREATED_AT = 'created_at',
  LAST_EXECUTION_AT = 'last_execution_at',
  UPDATED_AT = 'updated_at'
}

export enum CampaignSortDirEnum {
  ASC = 'asc',
  DESC = 'desc'
}

export interface CampaignDetailInterface {
  created_at: number;
  description: string;
  id: string;
  name: string;
  status: string;
  updated_at: number;
  example_code: {
    curl: any;
  };
  type: CampaignTypeEnum;
  tag?: CampaignTagBackendEnum;
  labels?: TaggingInterface[];
}

export interface ResponseCampaignDetailInterface {
  data: CampaignDetailInterface;
  message: string;
}

export interface SenderInterface {
  id: string;
  name: string;
  contact: string;
  email: string;
}

export interface ResponseSenderInterface {
  data: SenderInterface[];
  message: string;
}

export interface SendersInterface {
  created_at: string;
  email: string;
  id: string;
}

export type ResponseSenderListInterface = PaginatedAPIResponseInterface<SendersInterface>;

export interface SenderListPayload {
  page: number;
  limit?: number;
}

export interface ResponseAddChannelInterface {
  message: string;
  data: ChannelListInterface;
}

export interface TemplateInterface {
  configuration: any;
  content: string;
  content_json: string;
  language: string;
  last_status_change: number;
  precoding_example: any;
  precodings: any;
  subject: string;
  category: string;
}

export interface ChannelListInterface {
  channel: ChannelInterface;
  description: string;
  id: string;
  name: string;
  sender: SenderInterface;
  status: ChannelStatusEnum;
  updated_at: number;
  tag?: CampaignTagBackendEnum;
  template: TemplateInterface;
}

export interface ResponseChannelListInterface {
  message: string;
  data: ChannelListInterface[];
}

export interface ResponseEditAutomateCampaignInterface {
  message: string;
  data: {
    created_at: number;
    updated_at: number;
    description: string;
    id: string;
    name: string;
    status: string;
  };
}

export interface PrecodingExampleInterface {
  key: string;
  example: string;
}

export interface CreateEmailChannelPayload {
  name: string;
  description: string;
  attachment_id: string[];
  sender: {
    id: string;
  };
  type: CampaignTypeEnum;
  status: ChannelStatusEnum;
  template: {
    subject: string;
    category: string;
    preheader: string;
    content: string;
    content_json: string;
    width: EmailTemplateWidthStringType;
    precoding_example: Omit<PrecodingInterface, 'id'>[];
  };
}

export interface PayloadEditChannelInterface {
  id: string;
  name: string;
  description: string;
  sender: SenderInterface;
}

export interface ResponseEditChannelInterface {
  message: string;
  errors: string[];
  data: ChannelListInterface;
}

export type EmailTemplateWidthStringType = `${number}px` | '100%' | number | string;

interface EmailTemplateConfigInterface {
  width: EmailTemplateWidthStringType;
}

interface EditEmailTemplateConfigInterface extends Partial<EmailTemplateConfigInterface> {
  width: EmailTemplateWidthStringType;
}

export interface EditEmailTemplateRequestInterface {
  configuration: EditEmailTemplateConfigInterface;
  content: string;
  content_json: string;
}

export interface FetchEmailTemplateResponseInterface {
  message: string;
  errors?: string[];
  data?: {
    id: string;
    content: string;
    content_json: string;
    precodings: string[];
    configuration: EmailTemplateConfigInterface;
  };
}

export interface AutomatedCampaignPayloadInterface {
  name?: string;
  description?: string;
  labels?: string[];
}

export interface HandleErrorAddInterface {
  title: string;
  desc: string;
  type: HandleErrorEnum.channel | HandleErrorEnum.campaign;
  toastMessage: (type: NoticeType, content: string) => void;
}

export interface ChannelDetailInterface {
  message: string;
  errors: string[];
  data: {
    name: string;
    description: string;
    channel: ChannelInterface;
    sender: SenderInterface;
    updated_at: number;
    attachment: EmailDetailAttachmentInterface[];
    template: {
      category: string;
      subject: string;
      content: string;
      content_json: string;
      width: string;
      last_status_change: number;
      precodings: string[];
      precoding_example: PrecodingInterface[];
    };
    status: ChannelStatusEnum;
    type: CampaignTypeEnum;
    id?: string;
    creator?: string;
    example_code: {
      curl: string;
    };
    campaign_id: string;
  };
}

export interface EmailChannelInterface {
  key: ChannelKeyEnum;
}

export interface EmailSenderInterface {
  id: string;
  email: string;
}

export interface EmailDetailAttachmentInterface {
  id: string;
  download_url: string;
  name: string;
  size: string;
  thumbnail: string;
  extension: string;
}

export interface EmailDetailInterface {
  name: string;
  description: string;
  channel: EmailChannelInterface;
  sender: EmailSenderInterface;
  updated_at: number;
  attachment: EmailDetailAttachmentInterface[];
  template: {
    subject: string;
    content: string;
    content_json: string;
    preheader: string;
    category: string;
    width: string;
    last_status_change: number;
    precodings: string[];
    precoding_example: PrecodingInterface[];
  };
  status: ChannelStatusEnum;
  type: CampaignTypeEnum;
  creator?: string;
  example_code: {
    curl: string;
  };
  campaign_id?: string;
}

export interface EmailDetailResponseInterface {
  message: string;
  errors: string[];
  data: EmailDetailInterface;
}

export interface WhatsappDetailInterface {
  name: string;
  description: string;
  channel: ChannelInterface;
  sender: SenderInterface;
  updated_at: number;
  template: {
    subject: string;
    content: string;
    content_json: string;
    configuration: {
      footer_url: string;
      header_url: string;
      width: string;
    };
    precodings: string[];
  };
  status: ChannelStatusEnum;
}

export enum ChannelStatusEnum {
  DRAFT = 'draft',
  WAITING_REVIEW = 'waiting_review',
  REJECTED_REVIEW = 'rejected_review',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  // for manual campaigns
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  FINISH = 'finish',
  STOPPED = 'stopped',
  // merged from CheckerMakerReviewStatusEnum
  ADJUSTMENT = 'cm_adjustment',
  REVIEW = 'cm_review',
  EXPIRED = 'cm_expired',
  // used in FE only,
  UNDEFINED = ''
}

export interface ChannelListParamsInterface {
  status: ChannelStatusEnum;
}

export interface CreateWhatsAppChannelPayloadInterface {
  name: string;
  description: string;
  sender: any;
  status?: ChannelStatusEnum;
  tag?: CampaignTagBackendEnum;
  type: CampaignTypeEnum;
  labels?: string;
  template: {
    category?: string;
    language?: string | null;
    precoding_example?: PrecodingExampleInterface[];
    content: string | any;
    content_json: string | any;
  };
}

export interface GetPresignedURLPayloadInterface {
  name: string;
  extension: string;
}

export interface GetPresignedURLResponseInterface {
  id: string;
  upload_url: string;
}

export interface ResponseCampaignDeleteInterface {
  message: string;
  request_id: string;
}
