import axiosInstance from '../config/axios/axiosWithToken';
import {
  GetNotificationListResponseInterface,
  NotificationCountResponse
} from '../shared/notification.interface';

export const getNotificationList: (
  page?: number,
  limit?: number
) => Promise<GetNotificationListResponseInterface> = async (page = 1, limit = 10) => {
  const response = await axiosInstance.get(`notification?page=${page}&limit=${limit}`);
  return response.data;
};

// empty id means all notifications
export const readNotification: (id?: string) => Promise<void> = async (id) => {
  await axiosInstance.post('notification/read', { id });
};

export const getNotificationCount: () => Promise<NotificationCountResponse> = async () => {
  const response = await axiosInstance.get('notification/counter');
  return response.data.data;
};
