import baseStyle from './base-style';
import baseTextStyle from './base-text-style';
import { Token } from '@squantumengine/horizon';

const whatsAppBaseStyle = `
.selected-precoding {
  color: ${Token.COLORS.blue[500]};
}
`;

const headStyle = `
.PlaygroundEditorTheme__paragraph {
  margin: 0;
  position: relative;
  font-weight: bold;
}
`;

export const whatsAppBodyStyle = baseStyle + baseTextStyle + whatsAppBaseStyle;
export const whatsAppHeadStyle = baseStyle + headStyle + whatsAppBaseStyle;
