import { Button } from '@squantumengine/horizon';
import { useCheckerContext } from '../checker/checker-context';
import ConfirmationModal from '../modal/confirmation-modal';
import useHeaderActionComment from './useHeaderActionComment';
import ScopeWrapper from '../role-wrapper/scope-wrapper';
import { AuthActionScopeEnum } from '../../shared/user.interface';
import ChannelScopeWrapper from '../role-wrapper/channel-scope-wrapper';
import { ChannelKeyEnum } from '../../shared/master-api.interface';

function HeaderActionComment() {
  const { disabled, commentMap, canBeReviewed, channelType } = useCheckerContext();
  const {
    confirmApproveModal,
    confirmRejectModal,
    returnToCampaignPage,
    toggleCommentMode,
    approveChannel,
    rejectChannel,
    isLoading
  } = useHeaderActionComment();

  if (!canBeReviewed) return null;

  if (disabled) {
    return (
      <>
        <ScopeWrapper scope={AuthActionScopeEnum.COMMENT_WRITE}>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={toggleCommentMode}
            className="text-nowrap bg-red-500">
            Tolak dan Beri Komentar
          </Button>
        </ScopeWrapper>
        <ChannelScopeWrapper
          channelType={channelType}
          channelScopeMap={{
            [ChannelKeyEnum.EMAIL]: AuthActionScopeEnum.REVIEW_EMAIL_APPROVE,
            [ChannelKeyEnum.WHATSAPP]: AuthActionScopeEnum.REVIEW_WA_APPROVE,
            [ChannelKeyEnum.PUSH_NOTIFICATION]: AuthActionScopeEnum.REVIEW_PN_APPROVE
          }}>
          <ConfirmationModal
            title="Konten campaign berhasil disetujui"
            message="Notifikasi persetujuan berhasil akan dikirim ke editor untuk dilanjutkan ke tahap berikutnya."
            okText="OK"
            modalState={confirmApproveModal.visible}
            confirmationCloseOk={returnToCampaignPage}
            loadingConfirmation={isLoading}
          />
          <Button
            disabled={isLoading}
            variant="primary"
            className="text-nowrap"
            onClick={approveChannel}>
            Setujui Konten
          </Button>
        </ChannelScopeWrapper>
      </>
    );
  }

  return (
    <>
      <ScopeWrapper scope={AuthActionScopeEnum.COMMENT_WRITE}>
        <Button
          variant="primary"
          disabled={!commentMap.size || isLoading}
          className="text-nowrap"
          onClick={confirmRejectModal.openModal}>
          Kirim ke Editor untuk direvisi
        </Button>
      </ScopeWrapper>
      <ConfirmationModal
        isDanger
        title="Kirim konten ke editor untuk direvisi?"
        message="Konten campaign yang dibuat tidak dapat lanjut ke broadcast sebelum direvisi dan disetujui."
        cancelText="Batalkan"
        okText="Kirim ke Editor"
        modalState={confirmRejectModal.visible}
        confirmationCloseCancel={confirmRejectModal.closeModal}
        confirmationCloseOk={rejectChannel}
        loadingConfirmation={isLoading}
      />
    </>
  );
}

export default HeaderActionComment;
