import { Typography } from '@squantumengine/horizon';
import Empty from '../../assets/image/illustration.png';

export default function EmptyState({ emptyText }: Readonly<{ emptyText?: string }>) {
  const { Paragraph } = Typography;
  return (
    <div className="flex h-[375px] flex-col items-center justify-center">
      <img src={Empty} alt="Empty" />
      <Paragraph size="l" className="text-neutral-600" data-testid="add-contact-empty-state">
        {emptyText || 'Belum ada data yang dapat ditampilkan.'}
      </Paragraph>
    </div>
  );
}
