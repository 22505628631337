import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ChooseEmailSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      d="M37 12H11C9.3125 12 8 13.375 8 15V33C8 34.6875 9.3125 36 11 36H37C38.625 36 40 34.6875 40 33V15C40 13.375 38.625 12 37 12ZM37 15V17.5625C35.5625 18.75 33.3125 20.5 28.5625 24.25C27.5 25.0625 25.4375 27.0625 24 27C22.5 27.0625 20.4375 25.0625 19.375 24.25C14.625 20.5 12.375 18.75 11 17.5625V15H37ZM11 33V21.4375C12.375 22.5625 14.4375 24.1875 17.5 26.625C18.875 27.6875 21.3125 30.0625 24 30C26.625 30.0625 29 27.6875 30.4375 26.625C33.5 24.1875 35.5625 22.5625 37 21.4375V33H11Z"
      fill="currentColor"
    />
  </svg>
);

const ChooseEmailIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ChooseEmailSVG} {...props} />
);

export default ChooseEmailIcon;
