import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const InfoSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M9.99984 2.16699C5.39984 2.16699 1.6665 5.90033 1.6665 10.5003C1.6665 15.1003 5.39984 18.8337 9.99984 18.8337C14.5998 18.8337 18.3332 15.1003 18.3332 10.5003C18.3332 5.90033 14.5998 2.16699 9.99984 2.16699ZM9.99984 14.667C9.5415 14.667 9.1665 14.292 9.1665 13.8337V10.5003C9.1665 10.042 9.5415 9.66699 9.99984 9.66699C10.4582 9.66699 10.8332 10.042 10.8332 10.5003V13.8337C10.8332 14.292 10.4582 14.667 9.99984 14.667ZM10.8332 8.00033H9.1665V6.33366H10.8332V8.00033Z"
      fill="#006CEB"
    />
  </svg>
);

const InfoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={InfoSVG} {...props} />
);

export default InfoIcon;
