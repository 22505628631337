import { SetURLSearchParams } from 'react-router-dom';
import useModal, { UseModalInterface } from '../useModal';
import { useCallback, useEffect, useMemo } from 'react';
import { useAuth } from '../useAuth';
import { isScopeAuthorized } from '../../utils/route';
import { AuthActionScopeEnum } from '../../shared/user.interface';
import { CampaignTagEnum } from '../../shared/campaign.interface';
import useLogoutHook from '../useLogoutHook';

interface useCampaignPageQueryProps {
  searchParams: [URLSearchParams, SetURLSearchParams];
  openModal?: () => void;
}

export interface useCampaignPageQueryReturn {
  createCampaignAccessValidationModal: UseModalInterface;
  createCampaignUserValidationModal: UseModalInterface;
  isTaggedParamsValid: boolean;
  closeModal: () => void;
  redirectToLoginPage: () => void;
  redirectToSourcePage: () => void;
  params: {
    redirectUrl: string;
    organizationId: string;
    source: '' | CampaignTagEnum;
    email: string;
  };
}

function useCampaignPageQuery({
  searchParams,
  openModal
}: useCampaignPageQueryProps): useCampaignPageQueryReturn {
  const [URLSearchParams, setURLSearchParams] = searchParams;
  const { handleLogout: logout } = useLogoutHook(true);

  const redirectUrl = URLSearchParams.get('redirectUrl') || '';
  const organizationId = URLSearchParams.get('orId') || '';
  const source = (URLSearchParams.get('sc') as CampaignTagEnum) || '';
  const email = (URLSearchParams.get('email') as CampaignTagEnum) || '';

  const { userAccess, userData } = useAuth();
  const createCampaignAccessValidationModal = useModal();
  const createCampaignUserValidationModal = useModal();

  const clearParams = useCallback(() => {
    URLSearchParams.delete('redirectUrl');
    URLSearchParams.delete('orId');
    URLSearchParams.delete('sc');
    URLSearchParams.delete('email');
    setURLSearchParams(URLSearchParams);
  }, [URLSearchParams, setURLSearchParams]);

  const redirectToSourcePage = useCallback(() => {
    window.location.href = redirectUrl;
  }, [redirectUrl]);

  const isParamsValid = useMemo(() => {
    const isValueValid = !!redirectUrl && !!organizationId && !!source;
    const isSourceValid = Object.values(CampaignTagEnum).includes(source as CampaignTagEnum);
    return isValueValid && isSourceValid;
  }, [organizationId, redirectUrl, source]);

  const isOrganizationValid = !!(organizationId && userData.organization_id === organizationId);

  const isAccessValid = useMemo(() => {
    return isScopeAuthorized(AuthActionScopeEnum.CHANNEL_WA_CREATE, userAccess.scopes);
  }, [userAccess.scopes]);

  useEffect(() => {
    if (!isParamsValid) clearParams();
  }, [clearParams, isParamsValid]);

  useEffect(() => {
    if (isParamsValid && !isOrganizationValid) {
      createCampaignUserValidationModal.openModal();
      return;
    }

    if (isParamsValid && !isAccessValid) {
      createCampaignAccessValidationModal.openModal();
      return;
    }

    if (isParamsValid) openModal?.();
  }, [
    organizationId,
    createCampaignUserValidationModal,
    createCampaignAccessValidationModal,
    isOrganizationValid,
    isAccessValid,
    isParamsValid,
    openModal
  ]);

  const closeModal = useCallback(() => {
    clearParams();
    createCampaignAccessValidationModal.closeModal();
    createCampaignUserValidationModal.closeModal();
  }, [clearParams, createCampaignAccessValidationModal, createCampaignUserValidationModal]);

  return {
    createCampaignAccessValidationModal,
    createCampaignUserValidationModal,
    closeModal,
    redirectToLoginPage: logout,
    redirectToSourcePage,
    isTaggedParamsValid: isParamsValid && isOrganizationValid && isAccessValid,
    params: { redirectUrl, organizationId, source, email }
  };
}

export default useCampaignPageQuery;
