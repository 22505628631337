import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CheckerBubbleSVG = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#prefix__filter0_d_19307_20181)" clipPath="url(#prefix__clip0_19307_20181)">
      <path
        d="M20.037 6c-8.875 0-16 5.875-16 13 0 3 1.188 5.75 3.25 7.938-.875 2.437-2.812 4.562-2.875 4.562-.437.438-.5 1.063-.312 1.625.25.563.812.875 1.437.875 3.813 0 6.875-1.563 8.688-2.875a19.51 19.51 0 005.812.875c8.813 0 16-5.813 16-13 0-7.125-7.187-13-16-13z"
        fill="#006CEB"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_19307_20181">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
      <filter
        id="prefix__filter0_d_19307_20181"
        x={-2}
        y={2}
        width={44.037}
        height={40}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={3} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_19307_20181" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_19307_20181" result="shape" />
      </filter>
    </defs>
  </svg>
);

const CheckerBubbleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CheckerBubbleSVG} {...props} />
);

export default CheckerBubbleIcon;
