import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCampaignParams from './useCampaignParams';

function useReadOnlyURL(): [boolean, (isReadOnly: boolean) => void] {
  const navigate = useNavigate();
  const { campaignType, channelType, channelId } = useCampaignParams();
  const isEdit = window.location.pathname.endsWith('edit') || !channelId;
  const [isReadOnly, setIsReadOnly] = useState<boolean>(!isEdit);

  const setReadOnly = useCallback(
    (isReadOnly: boolean) => {
      navigate(`/campaign/${campaignType}/${channelType}/${channelId}${isReadOnly ? '' : '/edit'}`);
      setIsReadOnly(isReadOnly);
    },
    [navigate, campaignType, channelType, channelId]
  );

  return [isReadOnly, setReadOnly];
}

export default useReadOnlyURL;
