import { CheckerMakerActivityActionEnum } from './checker-maker.interface';
import { PaginatedAPIResponseInterface } from './general.interface';
import { ChannelKeyEnum } from './master-api.interface';

export enum CheckerMakerNotificationEventEnum {
  PN_APPROVAL_REQUEST = 'push_notification_approval_request',
  PN_APPROVAL_STATUS = 'push_notification_approval_status',
  EMAIL_APPROVAL_REQUEST = 'email_approval_request',
  EMAIL_APPROVAL_STATUS = 'email_approval_status',
  WA_APPROVAL_REQUEST = 'whatsapp_approval_request',
  WA_APPROVAL_STATUS = 'whatsapp_approval_status'
}

export type NotificationEventEnum = CheckerMakerNotificationEventEnum; // | AnotherNotificationEventEnum | SomeOtherNotificationEventEnum

export interface CheckerMakerNotificationData {
  action: CheckerMakerActivityActionEnum;
  channel_type: ChannelKeyEnum;
  channel_name: string;
  review_id: string;
  user_id: string;
  user_name: string;
}

export type NotificationData = CheckerMakerNotificationData; // | AnotherNotificationData | SomeOtherNotificationData

interface NotificationBaseData {
  id: string;
  created_at: string;
  event: NotificationEventEnum;
  data: NotificationData;
  is_seen: boolean;
}

export interface CheckerMakerNotificationInterface extends NotificationBaseData {
  event: CheckerMakerNotificationEventEnum;
  data: CheckerMakerNotificationData;
}

export type NotificationInterface = CheckerMakerNotificationInterface; // | AnotherNotificationInterface | SomeOtherNotificationInterface

export type GetNotificationListResponseInterface =
  PaginatedAPIResponseInterface<NotificationInterface>;

export interface NotificationCountResponse {
  unseen: number;
}
