import { ConfigProvider, Button as AntdBtn } from 'antd';
import type { ButtonProps } from 'antd';
import { colors } from '../../static/colors';
import { Token } from '@squantumengine/horizon';

const { COLORS } = Token;

export interface ButtonInterface extends ButtonProps {
  focusable?: boolean;
  isFormItem?: boolean;
}

const getButtonBorder = (isPrimary: boolean, disabled?: boolean) => {
  if (isPrimary) return 'none';
  if (disabled) return COLORS.neutral[100];
  return COLORS.blue[500];
};

function Button({ children, className, focusable, isFormItem, ...props }: ButtonInterface) {
  const isPrimary = props.type === 'primary';

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorBgContainer: 'transparent',
            colorBorder: getButtonBorder(isPrimary, props.disabled),
            colorPrimary: COLORS.blue[500],
            colorPrimaryActive: COLORS.blue[500],
            colorPrimaryBorder: COLORS.blue[500],
            colorPrimaryHover: COLORS.blue[500],
            colorError: colors.orange.danger,
            colorErrorActive: colors.orange.danger,
            colorErrorBg: colors.orange.danger,
            colorErrorBorderHover: colors.orange.danger,
            colorErrorHover: colors.orange.danger,
            colorErrorOutline: colors.orange.danger,
            controlOutline: isFormItem ? undefined : COLORS.blue[500],
            colorBgContainerDisabled: isPrimary ? COLORS.neutral[100] : 'transparent',
            colorTextDisabled: isPrimary ? COLORS.neutral[400] : colors.grey[50],
            colorText: isPrimary ? COLORS.neutral[0] : COLORS.blue[500],
            paddingContentHorizontalLG: 16,
            controlHeightLG: 48,
            controlHeight: 40,
            fontSize: 16,
            borderRadiusLG: 8,
            borderRadius: 8
          }
        }
      }}
    >
      <AntdBtn
        className={`flex items-center justify-center font-semibold ${
          focusable && 'focus:border-transparent focus:ring-4 focus:ring-emerald-green'
        } ${className}`}
        {...props}
      >
        {children}
      </AntdBtn>
    </ConfigProvider>
  );
}

export default Button;
