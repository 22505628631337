import {
  CheckerMakerReviewInterface,
  CheckerMakerReviewStatusEnum
} from '../../shared/checker-maker.interface';
import { Button, Label, LabelType, Paragraph } from '@squantumengine/horizon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CampaignTypeEnum } from '../../shared/automated-campaign.interface';
import ShowMoreText from '../../components/show-more-text';
import dayjs from 'dayjs';
import { dateFromToday } from '../../utils/date';
import { useAppConfig } from '../../hooks/useAuth';
import { getChannelConfig } from '../../utils/channel';

interface WaitForApprovalCardProps {
  data: CheckerMakerReviewInterface;
}

interface ChannelDataProps {
  label: string;
  children: React.ReactNode;
}

const ChannelData = ({ label, children }: Readonly<ChannelDataProps>) => {
  return (
    <div className="flex w-full gap-[34px]">
      <Paragraph className="w-[140px] shrink-0" weight="bold">
        {label}
      </Paragraph>
      <div className="text-sm">{children}</div>
    </div>
  );
};

export const checkerMakerChannelLabelType: { [key in CheckerMakerReviewStatusEnum]: LabelType } = {
  [CheckerMakerReviewStatusEnum.ACTIVE]: 'info',
  [CheckerMakerReviewStatusEnum.ADJUSTMENT]: 'warning',
  [CheckerMakerReviewStatusEnum.EXPIRED]: 'danger',
  [CheckerMakerReviewStatusEnum.REVIEW]: 'warning',
  [CheckerMakerReviewStatusEnum.DELETED]: 'danger'
};

export const checkerMakerChannelLabelText: { [key in CheckerMakerReviewStatusEnum]: string } = {
  [CheckerMakerReviewStatusEnum.ACTIVE]: 'Aktif',
  [CheckerMakerReviewStatusEnum.ADJUSTMENT]: 'Menunggu revisi',
  [CheckerMakerReviewStatusEnum.EXPIRED]: 'Gagal terbit',
  [CheckerMakerReviewStatusEnum.REVIEW]: 'Menunggu persetujuan',
  [CheckerMakerReviewStatusEnum.DELETED]: 'Dihapus'
};

const renderDate = (date: string) =>
  dayjs(+date).tz('Asia/Jakarta').format('DD MMMM YYYY, HH:mm') + ' WIB';

const WaitForApprovalCard = ({ data }: Readonly<WaitForApprovalCardProps>) => {
  const { isRoleChecker, isCheckerMaker } = useAppConfig();
  const isStatusReview = data.status === CheckerMakerReviewStatusEnum.REVIEW;
  const isStatusAdjustment = data.status === CheckerMakerReviewStatusEnum.ADJUSTMENT;

  const { icon, label, redirect } = useMemo(
    () =>
      getChannelConfig({
        channelType: data.channel_type,
        campaignType: CampaignTypeEnum.MANUAL,
        isCheckerMaker
      }),
    [data.channel_type, isCheckerMaker]
  );

  const broadcastDate =
    dayjs(+data.expires_at).tz('Asia/Jakarta').format('DD MMM YYYY, HH:mm') + ' WIB';

  const daysLeft = useMemo(() => dateFromToday(+data.expires_at), [data.expires_at]);

  const broadcastList = useMemo(() => {
    const broadcastNameList: string[] = [];
    let broadcastContactCount = 0;

    data.broadcast.contact.forEach((contact) => {
      broadcastContactCount += +contact.total_user;
      broadcastNameList.push(contact.name);
    });

    return broadcastNameList.join(', ') + ' (' + broadcastContactCount + ' penerima)';
  }, [data.broadcast]);

  const senderText = useMemo(() => {
    switch (data.status) {
      case CheckerMakerReviewStatusEnum.ACTIVE:
        return `Konten telah disetujui pada ${renderDate(data.last_activity.timestamp)}`;
      case CheckerMakerReviewStatusEnum.EXPIRED:
        return `Tanggal broadcast telah berarkhir pada ${renderDate(
          data.broadcast.next_execution_at
        )}`;
      case CheckerMakerReviewStatusEnum.REVIEW:
        return `${data.last_activity.user_name} mengirim konten pada ${renderDate(
          data.last_activity.timestamp
        )}`;
      case CheckerMakerReviewStatusEnum.ADJUSTMENT:
        return `${data.last_activity.user_name} meminta revisi pada ${renderDate(
          data.last_activity.timestamp
        )}`;
    }
  }, [data]);

  return (
    <Link
      to={
        `/campaign/manual/${data.channel_type}/${data.channel.id}` +
        redirect.read.url +
        '?ref=approval'
      }
      className="group flex w-full cursor-pointer flex-col gap-3 rounded-lg border border-solid border-neutral-100 bg-neutral-0 pb-3 pt-4 text-neutral-950 shadow-card hover:bg-neutral-50 focus:border-2 focus:border-blue-500 focus:bg-neutral-50"
      data-testid="waiting-for-approval-channel-card"
    >
      <div className="flex items-center gap-4 px-4">
        <div className="grid h-12 w-12 shrink-0 place-items-center rounded-full bg-neutral-100 group-hover:bg-blue-100 group-focus:bg-blue-100">
          <div className="scale-75">{icon}</div>
        </div>
        <Paragraph size="l" weight="semibold">
          {data.channel.name}
        </Paragraph>
        <div className="flex flex-1 flex-col gap-2">
          <Label
            border
            type={checkerMakerChannelLabelType[data.status]}
            label={checkerMakerChannelLabelText[data.status]}
          />
        </div>
        <div className="flex">
          <Button size="md" variant={isStatusReview && isRoleChecker ? 'primary' : 'secondary'}>
            {isStatusAdjustment && isRoleChecker ? 'Lihat Komentar Manajer' : 'Lihat Konten'}
          </Button>
        </div>
      </div>
      <div className="border-0 border-t border-solid border-neutral-50" />
      <div className="flex w-full flex-col gap-2 px-4 py-3">
        <ChannelData label="Channel">{label}</ChannelData>
        <ChannelData label="Tanggal broadcast">
          {broadcastDate} <span className="text-sm font-bold text-red-500">{daysLeft}</span>
        </ChannelData>
        <ChannelData label="Daftar broadcast ">{broadcastList}</ChannelData>
        <ChannelData label="Deskripsi">
          <ShowMoreText
            showMoreText="Selengkapnya"
            showLessText="Tutup"
            text={data.channel.description || '-'}
          />
        </ChannelData>
      </div>
      <div className="border-0 border-t border-solid border-neutral-50" />
      <div className="px-4">
        <Paragraph size="s" className="text-neutral-600">
          {senderText}
        </Paragraph>
      </div>
    </Link>
  );
};

export default WaitForApprovalCard;
