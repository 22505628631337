import { useState } from 'react';
import { useToastMessage } from './useQueryHelper';

interface ArgsProps {
  copiedText: string | null;
  copy: (text: string) => Promise<boolean>; // Return success
}

export function useCopyToClipboard(): ArgsProps {
  const [copiedText, setCopiedText] = useState<ArgsProps['copiedText']>(null);
  const { openToast } = useToastMessage();

  const copy: ArgsProps['copy'] = async (text: string) => {
    if (!navigator?.clipboard) {
      openToast({
        isError: true,
        label: 'Fitur clipboard tidak didukung oleh perangkat Anda.'
      });

      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);

      openToast({ label: 'Berhasil disalin ke clipboard' });

      return true;
    } catch (error) {
      openToast({ label: 'Gagal menyalin data. Silakan coba lagi.' });

      setCopiedText(null);
      return false;
    }
  };

  return { copiedText, copy };
}
