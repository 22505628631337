import { create } from 'zustand';
import {
  WhatsAppButtonGroupEnum,
  WhatsAppButtonInterface,
  WhatsAppButtonTypeEnum,
  WhatsAppButtonWithIdInterface,
  WhatsAppQuickReplyButtonInterface
} from '../components/whatsapp-editor/components/section/button/button.interface';
import { BUTTON_GROUP } from '../components/whatsapp-editor/components/section/button/button.static';
import { randomID } from '../utils/math';
import { urlRegexWithPrecoding } from '../components/whatsapp-editor/helper/regex';

export type ButtonMapType = { [key: string]: WhatsAppButtonWithIdInterface };

interface WhatsAppButtonStoreInterface {
  buttonMap: ButtonMapType;
  quickReplyIds: string[];
  ctaIds: string[];
  isCtaOnTop: boolean;
  reset: () => void;
  init: (buttonList: WhatsAppButtonInterface[]) => void;
  export: () => WhatsAppButtonInterface[];
  createButton: (button: WhatsAppButtonWithIdInterface) => void;
  deleteButton: (id: string) => void;
  updateButton: (id: string, button: WhatsAppButtonWithIdInterface) => void;
  toggleGroupOrder: () => void;
  updateButtonOrder: (type: WhatsAppButtonGroupEnum, buttonIds: string[]) => void;
  checkEmpty: () => boolean;
}

const initButtonList = (buttonList: WhatsAppButtonInterface[]) => {
  const buttonMap: ButtonMapType = {};
  const quickReplyIds: string[] = [];
  const ctaIds: string[] = [];
  let isCtaOnTop = false;

  if (buttonList.length) {
    isCtaOnTop = BUTTON_GROUP[WhatsAppButtonGroupEnum.CTA].includes(buttonList[0].type);
  }

  buttonList.forEach((button) => {
    const id = randomID();
    buttonMap[id] = { ...button, id };

    if (BUTTON_GROUP[WhatsAppButtonGroupEnum.QUICK_REPLY].includes(button.type)) {
      quickReplyIds.push(id);
    } else {
      ctaIds.push(id);
    }
  });

  return { buttonMap, quickReplyIds, ctaIds, isCtaOnTop };
};

const exportButtonList = (get: () => WhatsAppButtonStoreInterface) => {
  const { buttonMap, quickReplyIds, ctaIds, isCtaOnTop } = get();

  const quickReplyButtons = quickReplyIds.map((id) => {
    const { id: _, ...res } = buttonMap[id];
    return { ...(res as WhatsAppQuickReplyButtonInterface) };
  });

  const ctaButtons = ctaIds.flatMap((id) => {
    const { id: _, ...res } = buttonMap[id];
    if (res.type === WhatsAppButtonTypeEnum.UNDEFINED) return [];
    return { ...(res as WhatsAppButtonInterface) };
  });

  return isCtaOnTop ? [...ctaButtons, ...quickReplyButtons] : [...quickReplyButtons, ...ctaButtons];
};

const createButton =
  (button: WhatsAppButtonWithIdInterface) => (state: WhatsAppButtonStoreInterface) => {
    const quickReplyIds = [...state.quickReplyIds];
    const ctaIds = [...state.ctaIds];

    const { id } = button;

    if (button.type === WhatsAppButtonTypeEnum.QUICK_REPLY) {
      quickReplyIds.push(id);
    } else {
      ctaIds.push(id);
    }

    const buttonMap = {
      ...state.buttonMap,
      [id]: button
    };

    return { buttonMap, quickReplyIds, ctaIds };
  };

const deleteButton = (id: string) => (state: WhatsAppButtonStoreInterface) => {
  const quickReplyIds = [...state.quickReplyIds];
  const ctaIds = [...state.ctaIds];
  const buttonMap = { ...state.buttonMap };

  if (quickReplyIds.includes(id)) {
    quickReplyIds.splice(quickReplyIds.indexOf(id), 1);
  } else {
    ctaIds.splice(ctaIds.indexOf(id), 1);
  }

  delete buttonMap[id];

  return { buttonMap, quickReplyIds, ctaIds };
};

const updateButton =
  (id: string, button: WhatsAppButtonWithIdInterface) => (state: WhatsAppButtonStoreInterface) => {
    const buttonMap = { ...state.buttonMap };
    buttonMap[id] = button;
    return { buttonMap };
  };

const resetStore = () => {
  return {
    buttonMap: {},
    quickReplyIds: [],
    ctaIds: [],
    isCtaOnTop: false
  };
};

const updateButtonOrder = (type: WhatsAppButtonGroupEnum, buttonIds: string[]) => {
  if (type === WhatsAppButtonGroupEnum.QUICK_REPLY) {
    return { quickReplyIds: buttonIds };
  } else {
    return { ctaIds: buttonIds };
  }
};

const checkEmptyButton = (get: () => WhatsAppButtonStoreInterface) => {
  const { buttonMap } = get();

  for (let button of Object.values(buttonMap)) {
    switch (button.type) {
      case WhatsAppButtonTypeEnum.UNDEFINED:
        return true;
      case WhatsAppButtonTypeEnum.QUICK_REPLY:
        if (!button.text.trim()) return true;
        break;
      case WhatsAppButtonTypeEnum.PHONE_NUMBER:
        if (!button.phone_number.trim() || !button.text.trim()) return true;
        break;
      case WhatsAppButtonTypeEnum.URL:
        if (!button.text.trim() || !button.url.trim()) return true;
        if (!urlRegexWithPrecoding.test(button.url)) return true;
        break;
    }
  }

  return false;
};

export const useWhatsAppButtonStore = create<WhatsAppButtonStoreInterface>((set, get) => ({
  buttonMap: {},
  quickReplyIds: [],
  ctaIds: [],
  isCtaOnTop: false,
  reset: () => set(resetStore()),
  init: (buttonList) => set(initButtonList(buttonList)),
  export: () => exportButtonList(get),
  createButton: (button) => set(createButton(button)),
  deleteButton: (id) => set(deleteButton(id)),
  updateButton: (id, button) => set(updateButton(id, button)),
  toggleGroupOrder: () => set((state) => ({ isCtaOnTop: !state.isCtaOnTop })),
  updateButtonOrder: (type, buttonIds) => set(updateButtonOrder(type, buttonIds)),
  checkEmpty: () => checkEmptyButton(get)
}));

export const getWhatsappButtonStoreState = useWhatsAppButtonStore.getState;
