import {
  AttachmentCardProps,
  EditorAttachmentCardProps,
  ReadOnlyAttachmentCardProps
} from './attachment-card.interface';
import EditorAttachmentCard from './editor-attachment-card';
import ReadOnlyAttachmentCard from './read-only-attachment-card';

const AttachmentCard = (props: Readonly<AttachmentCardProps>) => {
  return (props as ReadOnlyAttachmentCardProps).readOnly ? (
    <ReadOnlyAttachmentCard {...(props as ReadOnlyAttachmentCardProps)} />
  ) : (
    <EditorAttachmentCard {...(props as EditorAttachmentCardProps)} />
  );
};

export default AttachmentCard;
