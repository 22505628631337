import {
  CheckerMakerActivityActionEnum,
  ReviewActivityInterface
} from '../../shared/checker-maker.interface';
import { IMetaBroadcast } from '../campaign-details/detail-broadcast';

export const findElementById = (
  rootElement?: Element | HTMLDivElement | null,
  id?: Array<string | number>
) => {
  if (!rootElement || !id) return null;
  let element = rootElement;
  for (const index of id) {
    if (!element?.children) return;
    element = element.children[Number(index)];
  }
  return element;
};

export const generateId = (rootElement: Element, element: Element, prefix: string) => {
  const id: number[] = [];
  let parentElement = element.parentElement;
  let currentElement = element;
  while (currentElement !== rootElement) {
    if (!parentElement) break;
    const index = Array.prototype.indexOf.call(parentElement.children, currentElement);
    if (index > -1) {
      id.unshift(index);
    }
    currentElement = parentElement;
    parentElement = currentElement.parentElement;
  }
  return prefix + id.join('_');
};

export const findElementByPrefix = (rootElement: Element, element: Element, prefix: string) => {
  let parentElement = element.parentElement;
  let currentElement = element;
  let selected;
  while (currentElement !== rootElement) {
    if (!parentElement) break;
    const isContains = currentElement.className.includes(prefix);
    if (isContains) {
      selected = currentElement.className
        .split(' ')
        .find((className) => className.startsWith(prefix));
      break;
    }
    currentElement = parentElement;
    parentElement = currentElement.parentElement;
  }
  return selected;
};

const getActivityHistoryLabel = (type: CheckerMakerActivityActionEnum) => {
  switch (type) {
    case CheckerMakerActivityActionEnum.REQUEST:
      return 'Konten telah dikirim untuk ditinjau';
    case CheckerMakerActivityActionEnum.APPROVE:
      return 'Konten telah disetujui';
    case CheckerMakerActivityActionEnum.DECLINE:
      return 'Konten telah ditolak';
    case CheckerMakerActivityActionEnum.EXPIRED:
      return 'Waktu broadcast konten sudah berlalu';
  }
};

export const generateActivityHistory = (
  lastActivities: ReviewActivityInterface[]
): IMetaBroadcast['history'] => {
  return lastActivities.map((activity) => ({
    id: activity.id,
    date: activity.created_at,
    description: getActivityHistoryLabel(activity.action),
    author: activity.creator
  }));
};
