import axiosInstance from '../config/axios/axiosWithToken';
import {
  ChanneLabelInterface,
  ListChannelResponse,
  ResponseChannelInterface
} from '../shared/channel.interface';
import { ChannelKeyEnum } from '../shared/master-api.interface';

export const createTestEmail: (id: string, to: string[]) => Promise<void> = async (id, to) => {
  const response = await axiosInstance.post(`channel/${id}/test-email`, { to });
  return response.data;
};

export const createTestWhatsApp: (id: string, receiver: string[]) => Promise<void> = async (
  id,
  receiver
) => {
  const response = await axiosInstance.post(`whatsapp/channel/${id}/send_test`, { receiver });
  return response.data;
};

export const createTestPushNotification: (id: string, user_id: string[]) => Promise<void> = async (
  id,
  user_id
) => {
  const response = await axiosInstance.post(`push_notification/channel/${id}/send_test`, {
    user_id
  });
  return response.data;
};

export const createTestEmailChannel: (id: string, to: string[]) => Promise<void> = async (
  id,
  to
) => {
  const response = await axiosInstance.post(`email/channel/${id}/send_test`, { to });
  return response.data;
};

export const getEmailDetail: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.get(`email/channel/${id}`);
  return response.data;
};

export const getPushNotifDetail: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.get(`push_notification/channel/${id}`);
  return response.data;
};

export const getWhatsAppDetail: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.get(`whatsapp/channel/${id}`);
  return response.data;
};

export const deleteEmailContent: (id: string) => Promise<ResponseChannelInterface> = async (id) => {
  const response = await axiosInstance.delete(`/email/channel/${id}`);
  return response.data;
};

export const deletePushNotifContent: (id: string) => Promise<ResponseChannelInterface> = async (
  id
) => {
  const response = await axiosInstance.delete(`/push_notification/channel/${id}`);
  return response.data;
};

export const deleteWhatsappContent: (id: string) => Promise<ResponseChannelInterface> = async (
  id
) => {
  const response = await axiosInstance.delete(`/whatsapp/channel/${id}`);
  return response.data;
};

export const stopEmailContent: (id: string) => Promise<ResponseChannelInterface> = async (id) => {
  const response = await axiosInstance.patch(`/email/channel/${id}/stop`);
  return response.data;
};

export const stopPushNotifContent: (id: string) => Promise<ResponseChannelInterface> = async (
  id
) => {
  const response = await axiosInstance.patch(`/push_notification/channel/${id}/stop`);
  return response.data;
};

export const stopWhatsappContent: (id: string) => Promise<ResponseChannelInterface> = async (
  id
) => {
  const response = await axiosInstance.patch(`/whatsapp/channel/${id}/stop`);
  return response.data;
};

export const getChannelList: (params: string) => Promise<ListChannelResponse> = async (params) => {
  const response = await axiosInstance.get(`channel${params}`);
  return response.data;
};

export const putChannelLabel: (
  id: string,
  channelType: ChannelKeyEnum,
  payload: ChanneLabelInterface
) => Promise<ResponseChannelInterface> = async (id, channelType, payload) => {
  const response = await axiosInstance.put(`/${channelType}/channel/${id}/label`, payload);
  return response.data;
};
