import { useMemo } from 'react';
import { EmailAttachmentInterface } from './attachment-plugin.interface';
import { Paragraph } from '@squantumengine/horizon';
import AttachmentCard from './attachment-card';

interface AttachmentPreviewGridProps {
  attachments: Map<string, EmailAttachmentInterface>;
}

const AttachmentPreviewGrid = ({ attachments }: Readonly<AttachmentPreviewGridProps>) => {
  const attachmentArray = useMemo(
    () =>
      Array.from(attachments).filter(
        ([_, attachment]) => !attachment.errorType && !attachment.isLoading
      ),
    [attachments]
  );

  const attachmentCount = useMemo(() => attachmentArray.length, [attachmentArray]);

  return (
    <div className="flex flex-col gap-2">
      <Paragraph weight="semibold">
        {attachmentCount} Attachment{attachmentCount > 1 ? 's' : ''}
      </Paragraph>
      <div className="flex flex-wrap gap-2">
        {attachmentArray.map(([id, attachment]) => (
          <div key={id} className="max-w-[220px]">
            <AttachmentCard readOnly file={attachment} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AttachmentPreviewGrid;
