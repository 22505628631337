import { useCallback } from 'react';
import { useAppConfig } from '../../hooks/useAuth';
import { useToastMessage } from '../../hooks/useQueryHelper';
import { GetSettingsInterface } from '../../shared/settings.interface';
import { useGetSettings } from '../../hooks/settings/useGetSettings';

const UpdateCheckerMaker = ({
  refetchInterval = 60_000
}: Readonly<{ refetchInterval?: number }>) => {
  const { isCheckerMaker, setIsCheckerMaker, isSettingsInitialized } = useAppConfig();
  const { openToast } = useToastMessage();

  const onSuccess = useCallback(
    (data: GetSettingsInterface) => {
      const { campaign_approval } = data;

      if (campaign_approval === isCheckerMaker) return;

      if (isSettingsInitialized.current)
        openToast({
          label: campaign_approval
            ? 'Fitur campaign approval diaktifkan.'
            : 'Fitur campaign approval telah dinonaktifkan.'
        });

      isSettingsInitialized.current = true;
      setIsCheckerMaker(campaign_approval);
    },
    [isCheckerMaker, openToast, setIsCheckerMaker, isSettingsInitialized]
  );

  useGetSettings({ refetchInterval, onSuccess });

  return null;
};

export default UpdateCheckerMaker;
