import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { getWhatsAppChannelDetail } from '../../api/whatsapp';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { redirectTo404 } from '../../utils/error';

interface OptionsInterface {
  enabled?: boolean;
}
export const useGetWhatsAppChannelDetail = (
  id: string = '',
  { enabled = true }: OptionsInterface = {}
) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.whatsappChannelDetail, id],
    queryFn: async () => {
      try {
        return (await getWhatsAppChannelDetail(id)).data;
      } catch (error) {
        redirectTo404(navigate, error);
      }
    },
    enabled: !!id && enabled,
    retry: 1
  });
};
