import { useRoutes } from 'react-router-dom';
import { generateRouterList } from './static/router-list';
import { filterRoutes } from './utils/route';
import { useAuth } from './hooks/useAuth';
import { useMemo } from 'react';

function Router() {
  const { userAccess, userData, appConfig } = useAuth();

  const filteredRouter = useMemo(() => {
    const routerList = generateRouterList({ isCheckerMaker: appConfig.isCheckerMaker });
    if (!userData.id) return routerList;
    return filterRoutes(routerList, userAccess);
  }, [userAccess, userData, appConfig.isCheckerMaker]);

  let element = useRoutes(filteredRouter);
  return element;
}

export default Router;
