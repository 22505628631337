import { UploadProps } from 'antd';
import FileUpload from '.';
export interface FileUploadInterface {
  id: string;
  url: string;
  name: string;
  file?: File;
}

export enum FILE_TYPE_ENUM {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT'
}

export interface FilePreviewInterface {
  file: FileUploadInterface | null;
  loading?: boolean;
  disabled?: boolean;
  isFileReady: boolean;
  uploadProps: UploadProps;
  onDeleteFile: () => void;
}

export type FileUploadProps<T = any> = Parameters<typeof FileUpload<T & FileUploadInterface>>[0];
