import { NoticeType } from 'antd/lib/message/interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { fetchListSender } from '../../api';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getErrorMessage } from '../../utils/error';
import { getListSender } from '../../api/automated-campaign';
import { getInfiniteQueryNextParams } from '../../utils/getInfiniteQueryNextParams';

export const useGetListSender = (
  id: string,
  toastMessage?: (type: NoticeType, message: string) => void
) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.senderList, id],
    queryFn: async () => {
      try {
        return (await fetchListSender(id)).data;
      } catch (error) {
        toastMessage?.('error', `Gagal memuat data pengirim: ${getErrorMessage(error)}`);
      }
    },
    enabled: !!id
  });
};

export const useGetSenderList = () => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS_ENUM.emailSenderList],
    queryFn: ({ pageParam }) => getListSender({ page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: getInfiniteQueryNextParams
  });
};
