import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
const { version } = require('../package.json');

const setupDatadog = () => {
  const {
    REACT_APP_DATADOG_LOG_ENABLED,
    REACT_APP_DATADOG_RUM_APP_ID,
    REACT_APP_DATADOG_CLIENT_TOKEN,
    REACT_APP_DATADOG_SERVICE,
    REACT_APP_DATADOG_SITE,
    REACT_APP_DATADOG_ENV
  } = process.env;

  const initConfig = {
    clientToken: REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: REACT_APP_DATADOG_SITE,
    service: REACT_APP_DATADOG_SERVICE,
    env: REACT_APP_DATADOG_ENV
  };

  if (REACT_APP_DATADOG_LOG_ENABLED)
    datadogLogs.init({ ...initConfig, forwardErrorsToLogs: true, sessionSampleRate: 100 });

  if (REACT_APP_DATADOG_RUM_APP_ID) {
    datadogRum.init({
      ...initConfig,
      applicationId: REACT_APP_DATADOG_RUM_APP_ID,
      // Specify a version number to identify the deployed version of your application in Datadog
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
  }
};

export default setupDatadog;
