import { CommentInterface } from '../checker.interface';
import {
  FormEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useRef,
  useState
} from 'react';
import { EmojiClickData } from 'emoji-picker-react';

export interface UseCommentBoxContentProps {
  comment?: CommentInterface;
  isEdit: boolean;
  onCancel: () => void;
  onSubmit: (content: string) => void;
}

const useCommentBoxContent = ({ comment, onSubmit }: Readonly<UseCommentBoxContentProps>) => {
  const commentValue = comment?.value || '';
  const [commentText, setCommentText] = useState(commentValue);
  const cursorPosition = useRef<[number, number]>([commentValue.length, commentValue.length]);

  const onEmojiClick = useCallback(
    (emojiData: EmojiClickData) => {
      const { emoji } = emojiData;
      const [cursorStart, cursorEnd] = cursorPosition.current;

      setCommentText((prev) => {
        return prev.substring(0, cursorStart) + emoji + prev.substring(cursorEnd);
      });
      cursorPosition.current = [cursorStart + emoji.length, cursorStart + emoji.length];
    },
    [cursorPosition]
  );

  const onKeyUp: KeyboardEventHandler<HTMLTextAreaElement> &
    MouseEventHandler<HTMLTextAreaElement> = useCallback((e) => {
    const cursorStart = (e.target as HTMLTextAreaElement).selectionStart;
    const cursorEnd = (e.target as HTMLTextAreaElement).selectionEnd;

    cursorPosition.current = [cursorStart, cursorEnd];
  }, []);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit(commentText);
    setCommentText('');
    cursorPosition.current = [0, 0];
  };

  return {
    onEmojiClick,
    onKeyUp,
    handleSubmit,
    commentValue,
    commentText,
    setCommentText,
    cursorPosition
  };
};

export default useCommentBoxContent;
