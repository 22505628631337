import { useWhatsappEditorContext } from '../../../../context/whatsapp-editor-context';
import { renderMediaIconPreview } from '../../../file-upload/file-upload.util';
import Thumbnail from './thumbnail';

function MediaPreview() {
  const { state, thumbnail } = useWhatsappEditorContext();
  return (
    <div
      data-testid="media-preview"
      className={`${state.mediaType ? 'visible' : 'hidden'} overflow-hidden rounded-t-lg`}
    >
      {thumbnail?.url ? (
        <Thumbnail url={thumbnail.url} type={state.mediaType} />
      ) : (
        <div className="flex h-32 items-center justify-center bg-neutral-100 ">
          {renderMediaIconPreview({ type: state.mediaType })}
        </div>
      )}
    </div>
  );
}

export default MediaPreview;
