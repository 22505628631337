import { AuthActionScopeEnum, UserAccessInterface, UserRoleEnum } from '../shared/user.interface';
import { LogicalOpResultType, LogicalOperatorEnum } from './logicalOperator';

export type RouteScopeType = AuthActionScopeEnum | LogicalOpResultType<AuthActionScopeEnum>;

export interface RouteFilterInterface {
  scope?: RouteScopeType;
  roles?: UserRoleEnum[];
  enable?: boolean;
}

export function isScopeAuthorized(
  scope: RouteScopeType,
  authorizedScopes: Set<AuthActionScopeEnum>
): boolean {
  if (typeof scope === 'string') return authorizedScopes.has(scope);

  if (scope.operator === LogicalOperatorEnum.OR)
    return scope.children.some((child) => isScopeAuthorized(child, authorizedScopes));

  if (scope.operator === LogicalOperatorEnum.AND)
    return scope.children.every((child) => isScopeAuthorized(child, authorizedScopes));

  return false;
}

function isRouteAuthorized(route: RouteFilterInterface, user: UserAccessInterface): boolean {
  if (route?.scope && !isScopeAuthorized(route.scope, user.scopes)) return false;
  if (route?.roles && !route.roles.includes(user.role)) return false;
  if (route?.enable === false) return false;
  return true;
}

interface ExtendedRouteFilterInterface<T> extends RouteFilterInterface {
  children?: T[];
}

export function filterRoute<T>(route: T, user: UserAccessInterface): T | undefined {
  const newRoute = route as ExtendedRouteFilterInterface<T>;
  if (!isRouteAuthorized(newRoute, user)) return undefined;

  if (newRoute?.children) {
    const children = filterRoutes(newRoute.children, user);
    return { ...route, children };
  }

  return route;
}

export function filterRoutes<T>(routes: T[], user: UserAccessInterface): T[] {
  const filteredRoutes: T[] = [];

  routes.forEach((route) => {
    const filteredRoute = filterRoute(route, user);
    if (filteredRoute) filteredRoutes.push(filteredRoute);
  });

  return filteredRoutes;
}
