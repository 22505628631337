import { Dialog, DialogBody, DialogHeader, Paragraph } from '@squantumengine/horizon';
import { InfoChannelModalProps } from './info-channel-modal.interface';

export default function InfoChannelModal({
  visible,
  title,
  content,
  onClose
}: Readonly<InfoChannelModalProps>) {
  return (
    <Dialog open={visible} onClose={onClose} className="w-[500px]">
      <DialogHeader className="border-0 border-b border-solid border-neutral-200">
        <Paragraph className="text-lg font-semibold">{title}</Paragraph>
      </DialogHeader>
      <DialogBody className="w-full p-0">
        <div className="flex w-full items-center justify-center px-6 py-4">{content}</div>
      </DialogBody>
    </Dialog>
  );
}
