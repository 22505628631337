import { Modal } from 'antd';
import CloseIcon from '../../../../assets/icons/close-icon';
import Button from '../../../buttons';

function ModalEdit({
  open,
  onCancel,
  onClickContinue
}: Readonly<{
  open: boolean;
  onCancel: () => void;
  onClickContinue: () => void;
}>) {
  return (
    <Modal
      title={<h2 className="m-0 p-0 text-2xl font-semibold">Lanjutkan Edit?</h2>}
      centered
      open={open}
      onCancel={onCancel}
      maskClosable={false}
      width={521}
      closeIcon={<CloseIcon />}
      footer={null}
      forceRender
    >
      <div className="flex flex-col gap-4">
        <p className="m-0 text-base">
          Apakah yakin ingin melakukan edit pada konten campaign? jika klik Lanjutkan maka konten
          campaign ini akan mulai dari ulang untuk dikirimkan ke Meta
        </p>
        <Button type="primary" onClick={onClickContinue}>
          Lanjutkan
        </Button>
        <Button onClick={onCancel}>Batalkan</Button>
      </div>
    </Modal>
  );
}

export default ModalEdit;
