import {
  DeviceEnum,
  PushNotificationPreviewInterface
} from './push-notification-preview.interface';

interface PreviewContentFullProps extends PushNotificationPreviewInterface {
  device: DeviceEnum;
}

function PreviewContentFull({
  image,
  header,
  body,
  device,
  button,
  truncate
}: PreviewContentFullProps) {
  const isIOS = device === DeviceEnum.IOS;

  return (
    <div className="flex w-full flex-col gap-[6px]" data-testid="preview-content-full">
      <div
        className="flex w-full gap-[2px]"
        style={{
          flexDirection: isIOS ? 'column-reverse' : 'column'
        }}
      >
        <div className="w-full flex-1 overflow-hidden">
          <p className={'my-1 font-semibold' + (truncate ? ' truncate' : '')}>{header}</p>
          <p className={'my-1' + (truncate ? ' truncate' : '')}>{body}</p>
        </div>
        {image && <img className="h-20 w-full rounded object-cover" src={image} alt="" />}
      </div>
      {button && (
        <p
          style={{ textAlign: isIOS ? 'center' : 'left' }}
          className="m-0 text-[8px] font-semibold text-green-emerald"
        >
          {button}
        </p>
      )}
    </div>
  );
}

export default PreviewContentFull;
