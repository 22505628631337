export function sleep(duration: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}

export async function retry<T extends (...arg0: any[]) => any>(
  fn: T,
  args: Parameters<T>,
  maxTry: number,
  retryCount = 1,
  delay = 400,
  delayMultiplier = 1
): Promise<Awaited<ReturnType<T>>> {
  const currRetry = typeof retryCount === 'number' ? retryCount : 1;
  try {
    const result = await fn(...args);
    return result;
  } catch (e) {
    if (currRetry > maxTry) {
      throw e;
    }
    await sleep(delay);
    return retry(fn, args, maxTry, currRetry + 1, delay * delayMultiplier);
  }
}
