import { useMemo } from 'react';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { AuthActionScopeEnum } from '../../shared/user.interface';
import ScopeWrapper from './scope-wrapper';

interface ChannelScopeWrapperProps {
  children: React.ReactNode;
  channelType?: ChannelKeyEnum;
  defaultValue?: any;
  channelScopeMap: { [key in ChannelKeyEnum]: AuthActionScopeEnum };
}

function ChannelScopeWrapper({
  children,
  channelType,
  channelScopeMap,
  defaultValue = null
}: Readonly<ChannelScopeWrapperProps>) {
  const scope: AuthActionScopeEnum | undefined = useMemo(() => {
    return channelScopeMap[channelType as ChannelKeyEnum];
  }, [channelScopeMap, channelType]);

  if (!scope) return defaultValue;

  return (
    <ScopeWrapper scope={scope} defaultValue={defaultValue}>
      {children}
    </ScopeWrapper>
  );
}

export default ChannelScopeWrapper;
