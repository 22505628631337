import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CommentLinesSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Communication/Comment Lines">
      <path
        id="comment-lines"
        d="M15.5 9.25H8.50003C8.21878 9.25 8.00003 9.5 8.00003 9.75V10.25C8.00003 10.5312 8.21878 10.75 8.50003 10.75H15.5C15.75 10.75 16 10.5312 16 10.25V9.75C16 9.5 15.75 9.25 15.5 9.25ZM12.5 12.25H8.50003C8.21878 12.25 8.00003 12.5 8.00003 12.75V13.25C8.00003 13.5312 8.21878 13.75 8.50003 13.75H12.5C12.75 13.75 13 13.5312 13 13.25V12.75C13 12.5 12.75 12.25 12.5 12.25ZM12 5C7.56253 5 4.00003 7.9375 4.00003 11.5C4.00003 13 4.59378 14.375 5.62503 15.4688C5.18753 16.6875 4.21878 17.75 4.18753 17.75C3.96878 17.9688 3.93753 18.2812 4.03128 18.5625C4.15628 18.8438 4.43753 19 4.75003 19C6.65628 19 8.18753 18.2188 9.09378 17.5625C10 17.8438 10.9688 18 12 18C16.4063 18 20 15.0938 20 11.5C20 7.9375 16.4063 5 12 5ZM12 16.5C11.1563 16.5 10.3125 16.375 9.53128 16.125L8.81253 15.9062L8.21878 16.3438C7.78128 16.6562 7.15628 17 6.40628 17.25C6.65628 16.875 6.87503 16.4375 7.03128 16L7.37503 15.125L6.71878 14.4375C6.15628 13.8438 5.50003 12.8438 5.50003 11.5C5.50003 8.75 8.40628 6.5 12 6.5C15.5625 6.5 18.5 8.75 18.5 11.5C18.5 14.2812 15.5625 16.5 12 16.5Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

const CommentLinesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CommentLinesSVG} {...props} />
);

export default CommentLinesIcon;
