import { ReactComponent as Code } from '../../../assets/icons/code.svg';
import { ReactComponent as Clone } from '../../../assets/icons/clone.svg';
import CopyInstructionModal from '../../../components/modal/copy-instruction-modal';
import { useCampaignDetailContext } from '../context/campaign-detail-context';
import { Button } from '@squantumengine/horizon';
import { IconWrapper } from './page-header-actions';

const PageHeaderAutomatedActions = () => {
  const { curl, campaignId, visibleCode, openModalCode, closeModalCode, copy } =
    useCampaignDetailContext();

  return (
    <div className="flex flex-col items-end gap-1">
      <Button variant="text" size="sm" onClick={openModalCode}>
        <IconWrapper>
          <Code />
        </IconWrapper>
        Instruksi Copy Code
      </Button>
      <Button variant="text" size="sm" onClick={() => copy(campaignId)}>
        <IconWrapper>
          <Clone />
        </IconWrapper>
        Copy Campaign ID
      </Button>
      <CopyInstructionModal open={visibleCode} onCancel={closeModalCode} curl={curl} />
    </div>
  );
};

export default PageHeaderAutomatedActions;
