import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  CodeMessageEnum,
  SecurityMessageEnum,
  StateInterface,
  WhatsAppEditorContextInterface,
  WhatsAppEditorProviderProps
} from './whatsapp-editor-context.interface';
import { WhatsAppButtonInterface } from '../components/whatsapp-editor/components/section/button/button.interface';
import {
  getWhatsappButtonStoreState,
  useWhatsAppButtonStore
} from '../store/whatsapp-button-store';
import useCreateEmptyEditor from '../hooks/useCreateEmptyEditor';
import { RichTextPrecodingMap } from '../components/rich-text-input/rich-text-input.interface';
import { PrecodingNode } from '../components/rich-text/nodes/PrecodingNode';
import { PrecodingInterface } from '../shared/precoding.interface';
import { HEADER_TYPE_ENUM } from '../components/whatsapp-editor/helper/header.enum';
import { precodingArrayToMap } from '../utils/precoding';
import { FileUploadInterface } from '../components/file-upload/file-upload.interface';
import { ChannelStatusEnum } from '../shared/automated-campaign.interface';
import dayjs from 'dayjs';
import useReadOnlyURL from '../hooks/useReadOnlyURL';
import { parseEditorToString } from '../utils/lexical';
import {
  useMergePrecodingValidationErrors,
  useValidatePrecoding
} from '../components/rich-text/plugins/InvalidPrecodingPlugin/useValidatePrecoding';

export const initialState = {
  // add more state according to whatsapp content fields
  selectedCategory: 'MARKETING',
  disabledTitle: false,
  title: '',
  description: '',
  headerType: null,
  mediaType: '',
  codeDeliveryType: 'COPY_CODE',
  addSecurityRecommendation: false,
  addCodeExpiration: false,
  codeExpiration: undefined,
  footer: '',
  autoBtnText: '',
  copyBtnText: '',
  language: 'en_US',
  body: '',
  content_json: '',
  headerText: '',
  initalBodyJson: '',
  initalHeaderJson: '',
  header_json: '',
  alreadyExistTitle: false,
  thumbnail: null,
  isUnEdited: false,
  status: ChannelStatusEnum.UNDEFINED,
  // subtracted to prevent glitch from the calculated present time
  lastStatusChange: dayjs().subtract(25, 'h')
};

// @ts-ignore
export const WhatsAppEditorContext = createContext<WhatsAppEditorContextInterface>({
  state: initialState
});

export const WhatsAppEditorProvider = ({ children }: WhatsAppEditorProviderProps) => {
  const [precodingMap, setPrecodingMap] = useState<RichTextPrecodingMap>({});
  const [selectedPrecoding, setSelectedPrecoding] = useState<PrecodingNode | null>(null);
  const [buttons, setButtons] = useState<WhatsAppButtonInterface[]>([]);
  const [hasEmptyButton, setHasEmptyButton] = useState<boolean>(false);
  const [state, setState] = useState<StateInterface>(initialState);
  const [thumbnail, setThumbnail] = useState<FileUploadInterface | null>(null);

  const [headerContent, setHeaderContent] = useState<string>('');
  const [bodyContent, setBodyContent] = useState<string>('');

  const resetState = (exclude: any) => {
    setState({ ...initialState, ...exclude });
  };

  const [readOnly, setReadOnly] = useReadOnlyURL();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const disabledButton = useMemo(() => {
    const {
      title,
      body,
      language,
      selectedCategory,
      addCodeExpiration,
      codeExpiration,
      sender,
      headerType,
      headerText
    } = state;

    if (!title.trim() || !language || !sender?.id) return false;

    if (selectedCategory === 'AUTHENTICATION') {
      return !!((!addCodeExpiration && !codeExpiration) || (addCodeExpiration && codeExpiration));
    }

    // for Marketing and Utility
    if (headerType) {
      if (headerType === HEADER_TYPE_ENUM.text && !headerText.trim()) return false;
      if (headerType !== HEADER_TYPE_ENUM.text && !thumbnail) return false;
    }

    if (hasEmptyButton) return false;

    return !!body.trim();
  }, [state, hasEmptyButton, thumbnail]);

  const [headerEditor, resetHeaderEditor] = useCreateEmptyEditor('whatsapp header');
  const [bodyEditor, resetBodyEditor] = useCreateEmptyEditor('whatsapp body');

  const generateHeaderContent = useCallback(() => {
    if (state.headerType !== HEADER_TYPE_ENUM.text) setHeaderContent('');
    setHeaderContent(parseEditorToString(headerEditor));
  }, [headerEditor, state.headerType]);

  const generateBodyContent = useCallback(() => {
    setBodyContent(parseEditorToString(bodyEditor));
  }, [bodyEditor]);

  useEffect(() => {
    generateHeaderContent();
    generateBodyContent();

    headerEditor.registerUpdateListener(generateHeaderContent);
    bodyEditor.registerUpdateListener(generateBodyContent);
  }, [generateHeaderContent, generateBodyContent, headerEditor, bodyEditor]);

  const headerPrecodingError = useValidatePrecoding(headerContent, precodingMap);
  const bodyPrecodingError = useValidatePrecoding(bodyContent, precodingMap);
  const precodingError = useMergePrecodingValidationErrors([
    headerPrecodingError,
    bodyPrecodingError
  ]);

  const resetAllEditor = useCallback(() => {
    resetHeaderEditor();
    resetBodyEditor();
    setButtons([]);
    getWhatsappButtonStoreState().reset();
  }, [resetHeaderEditor, resetBodyEditor]);

  const authPreviewMessage = `${CodeMessageEnum[state.language as keyof typeof CodeMessageEnum]}${
    state.addSecurityRecommendation
      ? SecurityMessageEnum[state.language as keyof typeof SecurityMessageEnum]
      : ''
  } `;

  const initPrecodingMap = useCallback((precodingArray: PrecodingInterface[]) => {
    setPrecodingMap(precodingArrayToMap(precodingArray));
  }, []);

  const isFieldDisabled = useMemo(() => readOnly || isSubmitting, [readOnly, isSubmitting]);

  const contextValue = useMemo(() => {
    return {
      thumbnail,
      setThumbnail,
      state,
      setState,
      resetState,
      headerEditor,
      bodyEditor,
      resetAllEditor,
      buttons,
      authPreviewMessage,
      disabledButton,
      precodingMap,
      setPrecodingMap,
      selectedPrecoding,
      setSelectedPrecoding,
      initPrecodingMap,
      resetHeaderEditor,
      readOnly,
      setReadOnly,
      precodingError,
      isSubmitting,
      setIsSubmitting,
      isFieldDisabled
    };
  }, [
    thumbnail,
    authPreviewMessage,
    bodyEditor,
    buttons,
    disabledButton,
    headerEditor,
    resetAllEditor,
    state,
    precodingMap,
    setPrecodingMap,
    selectedPrecoding,
    setSelectedPrecoding,
    initPrecodingMap,
    resetHeaderEditor,
    readOnly,
    setReadOnly,
    precodingError,
    isSubmitting,
    isFieldDisabled
  ]);

  const updateButton = () => {
    const buttonList = getWhatsappButtonStoreState().export();
    const isButtonEmpty = getWhatsappButtonStoreState().checkEmpty();
    setButtons(buttonList);
    setHasEmptyButton(isButtonEmpty);
  };

  useEffect(() => {
    updateButton();
    const unsub = useWhatsAppButtonStore.subscribe(updateButton);
    return () => {
      unsub();
    };
  }, []);

  return (
    <WhatsAppEditorContext.Provider value={contextValue}>{children}</WhatsAppEditorContext.Provider>
  );
};

export const useWhatsappEditorContext: () => WhatsAppEditorContextInterface = () =>
  useContext(WhatsAppEditorContext);
