import logo from '../../assets/image/logo.svg';
import { useNavigate } from 'react-router';
import { PeopleCircle } from '../../assets/icons';
import UserDropdown from './user-dropdown';
import { useIsLoggedIn } from '@squantumengine/sqeid-react-browser';
import { Header } from '@squantumengine/horizon';

function HeaderComponent() {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  return (
    <Header className="!max-h-[72px] !min-h-[72px] shadow-header">
      <div
        data-testid="layout-header"
        className="flex h-full w-full flex-row items-center justify-between">
        <img src={logo} alt="SQEMP" width={193} height={56} />
        {isLoggedIn ? (
          <UserDropdown />
        ) : (
          <div className="flex items-center justify-center gap-2">
            <PeopleCircle />
            <div
              className="cursor-pointer text-base font-semibold text-blue-500"
              onClick={() => navigate('/login')}>
              Login
            </div>
          </div>
        )}
      </div>
    </Header>
  );
}

export default HeaderComponent;
