import { useMemo } from 'react';
import { useGetPushNotifBroadcastDetail } from '../../hooks/broadcast/useGetPushNotifBroadcastDetail';
import useCampaignParams from '../../hooks/useCampaignParams';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { useGetEmailBroadcastDetail } from '../../hooks/broadcast/useGetEmailBroadcastDetail';
import { useGetWhatsappBroadcastDetail } from '../../hooks/broadcast/useGetWhatsappBroadcastDetail';

export const useGetBroadcastDetails = () => {
  const { channelId, channelType } = useCampaignParams();
  const isEmail = channelType === ChannelKeyEnum.EMAIL;
  const isPushNotif = channelType === ChannelKeyEnum.PUSH_NOTIFICATION;
  const isWhatsapp = channelType === ChannelKeyEnum.WHATSAPP;

  const { data: dataPushNotif } = useGetPushNotifBroadcastDetail(channelId, isPushNotif);
  const { data: dataEmail } = useGetEmailBroadcastDetail(channelId, isEmail);
  const { data: dataWhatsapp } = useGetWhatsappBroadcastDetail(channelId, isWhatsapp);

  const data = useMemo(() => {
    if (isPushNotif) return dataPushNotif?.data;
    if (isEmail) return dataEmail?.data;
    if (isWhatsapp) return dataWhatsapp?.data;
  }, [dataEmail?.data, dataPushNotif?.data, dataWhatsapp?.data, isEmail, isPushNotif, isWhatsapp]);
  return { data };
};
