import { createContext, useContext, useMemo, useEffect, useState, useRef } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { AuthActionScopeEnum, AuthContextProps, UserRoleEnum } from '../shared/user.interface';
import { local } from '../utils/storage';
import useSimasTokens from '@squantumengine/sqeid-react-browser';
import { setAuthorizationHeader } from '../config/axios/axiosWithToken';
import useIsScopeAuthorized from '../components/role-wrapper/useIsScopeAuthorized';
import { logicalOperatorWrapper } from '../utils/logicalOperator';

const { OR, AND } = logicalOperatorWrapper;

export const AuthContext = createContext<AuthContextProps>({
  userData: {
    email: '',
    name: '',
    first_name: '',
    id: '',
    last_name: '',
    organization_id: '',
    profile_photo: '',
    role: UserRoleEnum.EDITOR,
    scopes: []
  },
  userAccess: {
    scopes: new Set(),
    role: UserRoleEnum.EDITOR
  },
  appConfig: {
    isCheckerMaker: false,
    setIsCheckerMaker: () => {},
    isSettingsInitialized: { current: false }
  },
  login: () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }: { children: any }) => {
  const { accessToken } = useSimasTokens();
  const [isCheckerMaker, setIsCheckerMaker] = useState<boolean>(false);
  const isSettingsInitialized = useRef<boolean>(false);
  const [userData, setUserData] = useLocalStorage(
    'user',
    {
      email: '',
      name: '',
      first_name: '',
      id: '',
      last_name: '',
      organization_id: '',
      profile_photo: '',
      // lowest role level as a default value
      role: UserRoleEnum.EDITOR,
      scopes: []
    },
    true
  );

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    setUserData(data.data);
  };

  useEffect(() => {
    accessToken && setAuthorizationHeader(accessToken);
  }, [accessToken]);

  // call this function to sign out logged in user
  const logout = () => {
    setUserData({
      email: '',
      name: '',
      first_name: '',
      id: '',
      last_name: '',
      organization_id: '',
      profile_photo: '',
      role: UserRoleEnum.EDITOR,
      scopes: []
    });

    sessionStorage.removeItem('search-params');
    local.removeItem('user');
    // `token` is no longer used, but is kept to handle existing users
    local.removeItem('token');
  };

  const value: any = useMemo(
    () => ({
      userData,
      login,
      logout,
      appConfig: { isCheckerMaker, setIsCheckerMaker, isSettingsInitialized },
      userAccess: {
        scopes: new Set(userData.scopes),
        role: userData.role
      }
    }),

    // eslint-disable-next-line
    [userData, isCheckerMaker]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const useAppConfig = () => {
  const { appConfig } = useAuth();

  const isRoleMaker = useIsScopeAuthorized(
    OR(
      AND(
        AuthActionScopeEnum.CHANNEL_EMAIL_WRITE,
        AuthActionScopeEnum.CHANNEL_EMAIL_READ,
        AuthActionScopeEnum.CHANNEL_EMAIL_CREATE
      ),
      AND(
        AuthActionScopeEnum.CHANNEL_PN_WRITE,
        AuthActionScopeEnum.CHANNEL_PN_READ,
        AuthActionScopeEnum.CHANNEL_PN_CREATE
      ),
      AND(
        AuthActionScopeEnum.CHANNEL_WA_WRITE,
        AuthActionScopeEnum.CHANNEL_WA_READ,
        AuthActionScopeEnum.CHANNEL_WA_CREATE
      )
    )
  );

  const isRoleChecker = useIsScopeAuthorized(
    OR(
      AuthActionScopeEnum.REVIEW_EMAIL_APPROVE,
      AuthActionScopeEnum.REVIEW_PN_APPROVE,
      AuthActionScopeEnum.REVIEW_WA_APPROVE
    )
  );

  return { ...appConfig, isRoleChecker, isRoleMaker };
};
