export enum ErrorMessage {
  EmptyCredentials = 'Email dan password tidak boleh kosong',
  InvalidCredentials = 'Email atau password yang Anda masukkan salah. Mohon coba lagi.',
  SystemError = 'Sistem kami sedang terkendala. Mohon kembali dalam beberapa saat lagi.',
  NoServerResponse = 'Tidak ada respons dari server. Mohon cek koneksi internet Anda.',
  RequestError = 'Terjadi kesalahan saat melakukan permintaan. Mohon coba lagi.',
  SubmitFailed = 'Data gagal disimpan. Coba dalam beberapa saat lagi',
  CampaignAlreadyExist = 'Nama campaign ini telah digunakan',
  ChannelTitleAlreadyExist = 'Nama channel ini telah digunakan',
  ChannelAlreadyExist = 'Subjek email ini telah digunakan',
  TooLongDesc = 'Deskripsi campaign terlalu panjang (max 1000)',
  TooLongTittle = 'Nama campaign tidak boleh lebih dari 100 karakter',
  TooLongChannelDesc = 'Deskripsi channel terlalu panjang (max 1000)',
  TooLongTitleChannel = 'Nama channel terlalu panjang, maksimal 100 karakter',
  TooLongSubjectChannel = 'Subjek email tidak boleh lebih dari 100 karakter',
  CampaignTitleAlreadyExist = 'Judul konten campaign ini telah digunakan'
}
