import FooterPreview from './section/footer-preview';
import { useWhatsappEditorContext } from '../../../context/whatsapp-editor-context';
import WhatsAppAuthButtonContent from './whatsapp-auth-button-content';

export default function WhatsAppAuthPreview() {
  const { state, authPreviewMessage } = useWhatsappEditorContext();

  return (
    <div className="relative min-h-fit rounded-lg border-none bg-neutral-0">
      <div className="px-2 py-3">
        <div className="flex flex-col items-start gap-1.5 self-stretch">
          <div
            className="inline-block max-w-full"
            style={{ wordWrap: 'break-word' }}
            dangerouslySetInnerHTML={{ __html: authPreviewMessage }}
          ></div>
          {state.footer && <FooterPreview />}
        </div>
      </div>
      <WhatsAppAuthButtonContent />
    </div>
  );
}
