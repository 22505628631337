import { LexicalEditor } from 'lexical';
import { WhatsAppButtonInterface } from '../components/whatsapp-editor/components/section/button/button.interface';
import { RichTextPrecodingMap } from '../components/rich-text-input/rich-text-input.interface';
import { PrecodingNode } from '../components/rich-text/nodes/PrecodingNode';
import { FileUploadInterface } from '../components/file-upload/file-upload.interface';
import { PrecodingInterface } from '../shared/precoding.interface';
import { ChannelStatusEnum } from '../shared/automated-campaign.interface';
import { Dayjs } from 'dayjs';
import { InvalidPrecodingEnum } from '../components/rich-text/plugins/InvalidPrecodingPlugin/useValidatePrecoding';

export interface WhatsAppPrecodingContextInterface {
  precoding: RichTextPrecodingMap;
  getPrecodingNumber: (id?: number) => number;
  updatePrecoding: (id: number, value: string) => void;
}

interface SenderStateInterface {
  id: string;
}

export interface StateInterface {
  selectedCategory: string;
  title: string;
  description: string;
  headerType: null | string;
  mediaType: string;
  codeDeliveryType: string;
  addSecurityRecommendation: boolean;
  addCodeExpiration: boolean;
  codeExpiration?: number;
  footer: string;
  body: string;
  content_json: string;
  sender?: SenderStateInterface;
  autoBtnText: string;
  copyBtnText: string;
  language: null | string;
  headerText: string;
  initalBodyJson: string;
  initalHeaderJson: string;
  header_json: string;
  disabledTitle: boolean;
  alreadyExistTitle: boolean;
  isUnEdited: boolean;
  status: ChannelStatusEnum;
  lastStatusChange: Dayjs;
}

export interface WhatsAppEditorContextInterface {
  thumbnail: FileUploadInterface | null;
  setThumbnail: React.Dispatch<React.SetStateAction<FileUploadInterface | null>>;
  state: StateInterface;
  setState: React.Dispatch<React.SetStateAction<StateInterface>>;
  resetState: (exclude: any) => void;
  headerEditor: LexicalEditor;
  resetAllEditor: () => void;
  bodyEditor: LexicalEditor;
  buttons: WhatsAppButtonInterface[];
  authPreviewMessage: string;
  disabledButton: boolean;
  precodingMap: RichTextPrecodingMap;
  setPrecodingMap: React.Dispatch<React.SetStateAction<RichTextPrecodingMap>>;
  selectedPrecoding: PrecodingNode | null;
  setSelectedPrecoding: React.Dispatch<React.SetStateAction<PrecodingNode | null>>;
  initPrecodingMap: (precodingArray: PrecodingInterface[]) => void;
  resetHeaderEditor: () => void;
  readOnly: boolean;
  setReadOnly: (isReadOnly: boolean) => void;
  precodingError: InvalidPrecodingEnum;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  isFieldDisabled: boolean;
}

export interface WhatsAppEditorProviderProps {
  children: React.ReactNode;
}

export enum CodeMessageEnum {
  en_US = '<strong>123456</strong> is your verification code.',
  id = '<strong>123456</strong> adalah kode verifikasi anda.'
}

export enum SecurityMessageEnum {
  en_US = ' For your security, do not share this code.',
  id = ' Untuk keamanan anda, jangan bagikan kode ini.'
}
