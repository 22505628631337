import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const TimesSVG = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.84551 5.99949L11.8226 1.02795C11.9355 0.91502 11.999 0.761859 11.999 0.602157C11.999 0.442455 11.9355 0.289294 11.8226 0.176368C11.7097 0.0634414 11.5566 0 11.3969 0C11.2372 0 11.084 0.0634414 10.9711 0.176368L6 5.15391L1.02888 0.176368C0.972972 0.120452 0.906596 0.0760978 0.833545 0.0458365C0.760494 0.0155753 0.682199 0 0.603129 0C0.52406 0 0.445764 0.0155753 0.372713 0.0458365C0.299663 0.0760978 0.233287 0.120452 0.177376 0.176368C0.0644594 0.289294 0.00102344 0.442455 0.00102344 0.602157C0.00102344 0.761859 0.0644594 0.91502 0.177376 1.02795L5.15449 5.99949L0.177376 10.971C0.121172 11.0268 0.076561 11.0931 0.0461174 11.1662C0.0156739 11.2393 0 11.3177 0 11.3968C0 11.476 0.0156739 11.5544 0.0461174 11.6275C0.076561 11.7005 0.121172 11.7669 0.177376 11.8226C0.233122 11.8788 0.299444 11.9234 0.372517 11.9539C0.44559 11.9843 0.523968 12 0.603129 12C0.682291 12 0.760668 11.9843 0.833742 11.9539C0.906815 11.9234 0.973137 11.8788 1.02888 11.8226L6 6.85107L10.9711 11.8226C11.0269 11.8788 11.0932 11.9234 11.1663 11.9539C11.2393 11.9843 11.3177 12 11.3969 12C11.476 12 11.5544 11.9843 11.6275 11.9539C11.7006 11.9234 11.7669 11.8788 11.8226 11.8226C11.8788 11.7669 11.9234 11.7005 11.9539 11.6275C11.9843 11.5544 12 11.476 12 11.3968C12 11.3177 11.9843 11.2393 11.9539 11.1662C11.9234 11.0931 11.8788 11.0268 11.8226 10.971L6.84551 5.99949Z"
      fill="currentColor"
    />
  </svg>
);

const TimesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={TimesSVG} {...props} />
);

export default TimesIcon;
