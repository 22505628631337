import CommentBox from './comment-box';
import useChecker, { UseCheckerProps } from './useChecker';

interface CheckerProps extends UseCheckerProps {
  children: React.ReactNode;
}

const Checker = ({ children, anchorPrefix, isDynamic }: CheckerProps) => {
  const { onLeave, commentList, selectedElement, ref, removeSelectedElement } = useChecker({
    anchorPrefix,
    isDynamic
  });

  return (
    <div onMouseLeave={onLeave} className="flex flex-1 flex-col">
      <div ref={ref} className="checker">
        {children}
      </div>
      {ref.current &&
        commentList.map((element) => {
          const [comment] = element;
          return (
            <CommentBox
              isDynamic={isDynamic}
              comment={comment}
              key={comment.id}
              anchor={comment.anchor}
              parentRef={ref.current}
              anchorPrefix={anchorPrefix}
            />
          );
        })}
      {selectedElement && (
        <CommentBox
          isDynamic={isDynamic}
          parentRef={ref.current}
          anchor={selectedElement}
          anchorPrefix={anchorPrefix}
          cleanedAnchor={removeSelectedElement}
        />
      )}
    </div>
  );
};

export default Checker;
