import { Paragraph } from '@squantumengine/horizon';
import { fileSizeParser } from '../../../../../utils/math';
import AttachmentDownloadWrapper from './attachment-download-wrapper';
import { EmailAttachmentInterface } from '../attachment-plugin.interface';

interface AttachmentCardNameInterface {
  file: EmailAttachmentInterface;
  readOnly?: boolean;
}

const paragraphClassName = 'truncate text-neutral-950 hover:text-blue-500 hover:underline';

const AttachmentCardName = ({ file, readOnly }: Readonly<AttachmentCardNameInterface>) => {
  const { meta, size } = file;

  return (
    <div className="flex items-center gap-1 overflow-hidden">
      {readOnly ? (
        <Paragraph className={paragraphClassName}>{meta.name}</Paragraph>
      ) : (
        <AttachmentDownloadWrapper file={meta}>
          <Paragraph className={paragraphClassName}>{meta.name}</Paragraph>
        </AttachmentDownloadWrapper>
      )}
      <Paragraph
        data-testid="attachment-file-size"
        weight="semibold"
        className="w-fit text-neutral-950 hover:text-neutral-950"
      >
        ({fileSizeParser(size)})
      </Paragraph>
    </div>
  );
};

export default AttachmentCardName;
