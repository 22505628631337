import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CheckCircleSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      d="M16.4225 5.72852C10.8926 5.72852 6.41211 10.3952 6.41211 16.0618C6.41211 21.7702 10.8926 26.3952 16.4225 26.3952C21.9121 26.3952 26.4329 21.7702 26.4329 16.0618C26.4329 10.3952 21.9121 5.72852 16.4225 5.72852ZM16.4225 7.72852C20.8626 7.72852 24.4954 11.4785 24.4954 16.0618C24.4954 20.6868 20.8626 24.3952 16.4225 24.3952C11.9421 24.3952 8.34961 20.6868 8.34961 16.0618C8.34961 11.4785 11.9421 7.72852 16.4225 7.72852ZM22.0736 13.1868L21.1452 12.2285C20.9837 12.0202 20.6608 12.0202 20.459 12.2285L14.7676 18.0618L12.3457 15.5618C12.1439 15.3535 11.8613 15.3535 11.6595 15.5618L10.7311 16.4785C10.5697 16.6868 10.5697 17.0202 10.7311 17.1868L14.4043 21.0202C14.6061 21.2285 14.8887 21.2285 15.0905 21.0202L22.0736 13.8952C22.235 13.6868 22.235 13.3535 22.0736 13.1868Z"
      fill="currentColor"
    />
  </svg>
);

const CheckCircleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CheckCircleSVG} {...props} />
);

export default CheckCircleIcon;
