import { useCallback, useEffect, useState } from 'react';
import { CommentBoxProps } from '.';
import { findElementById } from '../checker.utils';

const initialRect = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
  y: 0,
  x: 0
} as Partial<DOMRect> as DOMRect;

const useCommentBox = (props: CommentBoxProps) => {
  const { anchor, anchorPrefix, parentRef, isDynamic } = props;
  const [rect, setRect] = useState(initialRect);
  const [element, setElement] = useState<Element | null | undefined>(null);

  const handleSetElement = useCallback(() => {
    if (isDynamic) {
      let cleanedAnchor = anchor;
      cleanedAnchor = anchor.replace(anchorPrefix, '');
      setElement(findElementById(parentRef, cleanedAnchor.split('_')));
      return findElementById(parentRef, cleanedAnchor.split('_'));
    } else {
      setElement(document.querySelector(`.${anchor}`));
    }
  }, [isDynamic, anchor, anchorPrefix, parentRef]);

  useEffect(() => {
    setTimeout(() => {
      handleSetElement();
    }, 100);
    handleSetElement();
  }, [handleSetElement]);

  const elementResize = useCallback(() => {
    if (element) {
      const rect = element.getBoundingClientRect();
      setRect(rect);
    }
  }, [element]);

  useEffect(() => {
    elementResize();
    const container = document.querySelector('.sqemp-layout-content');
    if (!container) return;

    window.addEventListener('resize', elementResize);
    container.addEventListener('scroll', elementResize);

    return () => {
      window.removeEventListener('resize', elementResize);
      container.removeEventListener('scroll', elementResize);
    };
  }, [anchor, elementResize, element]);

  return { rect, element };
};

export default useCommentBox;
