export enum LogicalOperatorEnum {
  OR = 'OR',
  AND = 'AND'
}

export type LogicalOpResultType<T> = {
  children: (T | LogicalOpResultType<T>)[];
  operator: LogicalOperatorEnum;
};

function generalLogicalOperatorWrapper<T>(children: T, operator: LogicalOperatorEnum) {
  return { children, operator };
}

function logicalOperatorORWrapper<T>(...children: (T | LogicalOpResultType<T>)[]) {
  return generalLogicalOperatorWrapper([...children], LogicalOperatorEnum.OR);
}

function logicalOperatorANDWrapper<T>(...children: (T | LogicalOpResultType<T>)[]) {
  return generalLogicalOperatorWrapper([...children], LogicalOperatorEnum.AND);
}

export const logicalOperatorWrapper = {
  OR: logicalOperatorORWrapper,
  AND: logicalOperatorANDWrapper
};
