import {
  checkerMakerChannelLabelText,
  checkerMakerChannelLabelType
} from '../pages/wait-for-approval/waiting-for-approval-card';
import { CampaignTypeEnum, ChannelStatusEnum } from '../shared/automated-campaign.interface';
import { CampaignTagBackendEnum, CampaignTagEnum } from '../shared/campaign.interface';
import { ChannelStatusInterface } from '../shared/channel.interface';
import { ChannelKeyEnum } from '../shared/master-api.interface';
import { Token } from '@squantumengine/horizon';

export const generateChannelStatusValue = (
  status: string,
  channelType?: ChannelKeyEnum
): ChannelStatusInterface => {
  switch (status) {
    case ChannelStatusEnum.ACTIVE:
      return {
        label: 'Aktif',
        color: Token.LABEL_TYPE.info,
        tooltip: ''
      };
    case ChannelStatusEnum.WAITING_REVIEW:
      return {
        label: 'Dalam peninjauan sistem',
        color: Token.LABEL_TYPE.warning,
        tooltip:
          channelType === ChannelKeyEnum.WHATSAPP
            ? 'Menandakan jika template sedang ditinjau oleh Meta. Proses ini membutuhkan waktu hingga 24 jam.'
            : ''
      };
    case ChannelStatusEnum.REJECTED_REVIEW:
      return {
        label: 'Ditolak sistem',
        color: Token.LABEL_TYPE.danger,
        tooltip: ''
      };
    case ChannelStatusEnum.DRAFT:
      return {
        label: 'Draf',
        color: Token.LABEL_TYPE.default,
        tooltip: ''
      };
    case ChannelStatusEnum.PENDING:
      return {
        label: 'Terjadwal',
        color: Token.LABEL_TYPE.warning,
        tooltip: ''
      };
    case ChannelStatusEnum.IN_PROGRESS:
      return {
        label: 'Sedang dikirim',
        color: Token.LABEL_TYPE.info,
        tooltip: ''
      };
    case ChannelStatusEnum.FINISH:
      return {
        label: 'Selesai',
        color: Token.LABEL_TYPE.info,
        tooltip: ''
      };
    case ChannelStatusEnum.STOPPED:
      return {
        label: 'Dihentikan',
        color: Token.LABEL_TYPE.danger,
        tooltip: ''
      };
    case ChannelStatusEnum.ADJUSTMENT:
      return {
        label: checkerMakerChannelLabelText[ChannelStatusEnum.ADJUSTMENT],
        color: checkerMakerChannelLabelType[ChannelStatusEnum.ADJUSTMENT],
        tooltip: ''
      };
    case ChannelStatusEnum.REVIEW:
      return {
        label: checkerMakerChannelLabelText[ChannelStatusEnum.REVIEW],
        color: checkerMakerChannelLabelType[ChannelStatusEnum.REVIEW],
        tooltip: ''
      };
    case ChannelStatusEnum.EXPIRED:
      return {
        label: checkerMakerChannelLabelText[ChannelStatusEnum.EXPIRED],
        color: checkerMakerChannelLabelType[ChannelStatusEnum.EXPIRED],
        tooltip: ''
      };
    default:
      return {
        label: 'Nonaktif',
        color: Token.LABEL_TYPE.default,
        tooltip:
          channelType === ChannelKeyEnum.WHATSAPP
            ? 'Template dinonaktifkan karena feedback negatif berulang dari penerima. Template pesan dengan status ini tidak dapat dikirim. '
            : ''
      };
  }
};

export const getCampaignTypeLabel = (type?: string, defaultValue = '') => {
  switch (type) {
    case CampaignTypeEnum.MANUAL:
      return 'Manual Campaign';
    case CampaignTypeEnum.AUTOMATED:
      return 'Automated Campaign';
    default:
      return defaultValue;
  }
};

export const getBackendCampaignTypeKey = (tag: CampaignTagBackendEnum) => {
  const key = Object.keys(CampaignTagBackendEnum).find(
    (key) => CampaignTagBackendEnum[key as keyof typeof CampaignTagBackendEnum] === tag
  );
  return key || tag;
};

export const mapCampaignTag = (tag: CampaignTagEnum) => {
  const key = Object.keys(CampaignTagEnum).find(
    (key) => CampaignTagEnum[key as keyof typeof CampaignTagEnum] === tag
  );
  return CampaignTagBackendEnum[key as keyof typeof CampaignTagBackendEnum] as
    | CampaignTagBackendEnum
    | undefined;
};

export const isTestButtonEnabled = (status: string) => {
  return (
    status === ChannelStatusEnum.ACTIVE ||
    status === ChannelStatusEnum.PENDING ||
    status === ChannelStatusEnum.IN_PROGRESS ||
    status === ChannelStatusEnum.FINISH
  );
};

export const isEditButtonEnabled = (status: ChannelStatusEnum) => {
  const cannotEditStatus = [
    ChannelStatusEnum.PENDING,
    ChannelStatusEnum.IN_PROGRESS,
    ChannelStatusEnum.FINISH
  ];
  return !cannotEditStatus.includes(status);
};
