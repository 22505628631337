export const colors = {
  red: {
    10: '#FFF4F4',
    20: '#F4D6D6',
    50: '#C64D3E',
    80: '#BB0000',
    imperial: '#EA3546'
  },
  grey: {
    10: '#F6F6F6',
    20: '#ECECEC',
    50: '#D2D2D2',
    80: '#B8B8B8',
    100: '#949494'
  },
  green: {
    10: '#E8FFF8',
    20: '#A0CDC0',
    lime: '#459B2C',
    chat: '#C1ECDF',
    shades: '#1A936F',
    emerald: '#2DB089',
    emeraldDark: '#097C5A'
  },
  yellow: {
    10: '#FDF6E8',
    20: '#F9E2B0',
    50: '#E8B25C',
    80: '#DF9E0C'
  },
  black: {
    90: '#313131',
    rich: '#0A2730'
  },
  blue: {
    10: '#CCE6F5',
    starLight: '#2684BA'
  },
  white: {
    100: '#FFFFFF',
    background: '#F9F9F9'
  },
  orange: {
    danger: '#FF5E05'
  },
  'dark-border': '#DADADA'
};
