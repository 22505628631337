import { Button, Icon } from '@squantumengine/horizon';
import { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

interface BasicHeaderProps {
  title?: string;
  tags?: ReactNode;
  leftActions?: ReactNode;
  children?: ReactNode;
}

interface OnClickHeaderProps extends BasicHeaderProps {
  onClickBack: () => void;
}

interface URLHeaderProps extends BasicHeaderProps {
  backUrl: string;
}

type HeaderProps = OnClickHeaderProps | URLHeaderProps;

function CampaignHeader(props: HeaderProps) {
  const { title, tags, leftActions, children } = props;
  const navigate = useNavigate();

  const onClickBack = useCallback(() => {
    const backUrl = (props as URLHeaderProps).backUrl;
    if ((props as OnClickHeaderProps).onClickBack) (props as OnClickHeaderProps).onClickBack();
    else if (backUrl) navigate(backUrl);
  }, [props, navigate]);

  const isBackButton = useMemo(
    () => !!(props as URLHeaderProps).backUrl || !!(props as OnClickHeaderProps).onClickBack,
    [props]
  );

  return (
    <div className="flex w-full items-center justify-between gap-4">
      <div className="flex gap-4">
        {isBackButton && (
          <Button
            className="p-1"
            size="sm"
            variant="text"
            onClick={onClickBack}
            data-testid="back-button"
          >
            <Icon name="arrow-left" />
          </Button>
        )}
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <h3 className="m-0 max-w-[60%] text-2xl font-semibold desktop-sm:max-w-full">
              {title}
            </h3>
            {tags}
          </div>
          {leftActions}
        </div>
      </div>
      {children}
    </div>
  );
}

export default CampaignHeader;
