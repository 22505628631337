import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getCheckerMakerTemplateDetail } from '../../api/checker-maker';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { GetTemplateDetailResponse } from '../../shared/checker-maker.interface';

export function useGetCheckerMakerTemplateDetail<T = {}>(
  channelType: ChannelKeyEnum,
  id: string
): UseQueryResult<GetTemplateDetailResponse<T>> {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getCheckerMakerTemplateDetail, id],
    queryFn: () => getCheckerMakerTemplateDetail(channelType as any, id),
    enabled: !!id
  });
}
