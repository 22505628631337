import { useState } from 'react';
import { decryptToken, encryptToken } from '../utils/oAuth';
import { local } from '../utils/storage';

// Hook
export const useLocalStorage = function <T>(
  key: string,
  initialValue: T,
  encrypted = false,
  secret = process.env.REACT_APP_ENCRYPT_TOKEN
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      let item = local.getItem(key);

      if (encrypted && item) item = decryptToken(item, secret);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      // eslint-disable-next-line no-console
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        let valueToStoreString = JSON.stringify(valueToStore);

        if (encrypted) valueToStoreString = encryptToken(valueToStoreString, secret);

        local.setItem(key, valueToStoreString);
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
};
