import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const WarningSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M3.72494 17.586H16.2749C17.5583 17.586 18.3583 16.1943 17.7166 15.086L11.4416 4.24434C10.7999 3.136 9.19994 3.136 8.55827 4.24434L2.28327 15.086C1.6416 16.1943 2.4416 17.586 3.72494 17.586ZM9.99994 11.7527C9.5416 11.7527 9.1666 11.3777 9.1666 10.9193V9.25267C9.1666 8.79434 9.5416 8.41934 9.99994 8.41934C10.4583 8.41934 10.8333 8.79434 10.8333 9.25267V10.9193C10.8333 11.3777 10.4583 11.7527 9.99994 11.7527ZM10.8333 14.2527C10.8333 14.7129 10.4602 15.086 9.99994 15.086C9.5397 15.086 9.1666 14.7129 9.1666 14.2527C9.1666 13.7924 9.5397 13.4193 9.99994 13.4193C10.4602 13.4193 10.8333 13.7924 10.8333 14.2527Z"
      fill="#FFC634"
    />
  </svg>
);

const WarningIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={WarningSVG} {...props} />
);

export default WarningIcon;
