import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getNotificationList } from '../../api/notification';
import { getInfiniteQueryNextParams } from '../../utils/getInfiniteQueryNextParams';

export const useGetNotificationList = () => {
  const queryClient = useQueryClient();
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS_ENUM.getNotificationList],
    queryFn: async ({ pageParam }) => {
      const response = await getNotificationList(pageParam);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.getNotificationCount] });
      return response;
    },
    initialPageParam: 1,
    getNextPageParam: getInfiniteQueryNextParams,
    refetchInterval: 6_000 // 1 minute
  });
};
