import { Dispatch, SetStateAction, useCallback } from 'react';
import {
  CheckerMakerNotificationEventEnum,
  CheckerMakerNotificationInterface,
  NotificationInterface
} from '../../../shared/notification.interface';
import { ActionPayload, NotificationConfig } from '@squantumengine/horizon';
import { useToastMessage } from '../../../hooks/useQueryHelper';
import { getErrorMessage } from '../../../utils/error';
import { getCheckerMakerTemplateDetail } from '../../../api/checker-maker';
import { useNavigate } from 'react-router';
import { useReadNotification } from '../../../hooks/notification/useReadNotification';
import {
  CheckerMakerReviewStatusEnum,
  GetTemplateDetailDataResponse
} from '../../../shared/checker-maker.interface';

export enum NotificationActionEnum {
  READ = 'READ'
}

const useNotificationComponent = ({
  setOpenNotif
}: {
  setOpenNotif: Dispatch<SetStateAction<boolean>>;
}) => {
  const { toastMessage } = useToastMessage();
  const navigate = useNavigate();
  const { mutateAsync: readNotificationAsync, mutate: readNotification } = useReadNotification();

  const onClickCheckerMaker = useCallback(
    async (data: CheckerMakerNotificationInterface) => {
      try {
        const notificationId = data.id;
        const { channel_type, review_id } = data.data;

        const response = (await getCheckerMakerTemplateDetail(
          channel_type,
          review_id
        )) as GetTemplateDetailDataResponse;

        !data.is_seen && (await readNotificationAsync(notificationId));
        const { id } = response.data.channel;

        if (response.data.status === CheckerMakerReviewStatusEnum.DELETED) {
          navigate('/404');
        } else {
          navigate(`/campaign/manual/${channel_type}/${id}`);
        }

        setOpenNotif(false);
      } catch (e) {
        toastMessage('error', getErrorMessage(e));
      }
    },
    [toastMessage, navigate, readNotificationAsync, setOpenNotif]
  );

  const readAllNotifications = useCallback(async () => {
    readNotification(undefined, {
      onError: (e) => toastMessage('error', getErrorMessage(e))
    });
  }, [readNotification, toastMessage]);

  const onActionClick = useCallback(
    (payload: ActionPayload) => {
      switch (payload.action) {
        case NotificationActionEnum.READ:
          readAllNotifications();
          break;
      }
    },
    [readAllNotifications]
  );

  const onClick = useCallback(
    async (notification: NotificationConfig<NotificationInterface>) => {
      switch (notification.action?.event) {
        case CheckerMakerNotificationEventEnum.PN_APPROVAL_REQUEST:
        case CheckerMakerNotificationEventEnum.PN_APPROVAL_STATUS:
        case CheckerMakerNotificationEventEnum.EMAIL_APPROVAL_REQUEST:
        case CheckerMakerNotificationEventEnum.EMAIL_APPROVAL_STATUS:
        case CheckerMakerNotificationEventEnum.WA_APPROVAL_REQUEST:
        case CheckerMakerNotificationEventEnum.WA_APPROVAL_STATUS:
          return onClickCheckerMaker(notification.action as CheckerMakerNotificationInterface);
      }
    },
    [onClickCheckerMaker]
  );

  return { onClick, onActionClick };
};

export default useNotificationComponent;
