import { createContext, useContext, useMemo, useState } from 'react';
import { PushNotificationEditorContextInterface } from './push-notification-editor-context.interface';
import useCreateEmptyEditor from '../hooks/useCreateEmptyEditor';
import { RichTextPrecodingMap } from '../components/rich-text-input/rich-text-input.interface';
import { FileUploadInterface } from '../components/file-upload/file-upload.interface';
import useEditorListener from '../components/push-notification/useEditorListener';
import { PushNotificationChannelResponseInterface } from '../shared/push-notification-configuration.interface';
import { ChannelStatusEnum } from '../shared/automated-campaign.interface';
import useReadOnlyURL from '../hooks/useReadOnlyURL';
import {
  useMergePrecodingValidationErrors,
  useValidatePrecoding
} from '../components/rich-text/plugins/InvalidPrecodingPlugin/useValidatePrecoding';

export const PushNotificationEditorContext = createContext<PushNotificationEditorContextInterface>({
  // @ts-ignore
  headerEditor: null,
  // @ts-ignore
  bodyEditor: null
});

// hardcoded on BE as well
const DEFAULT_CATEGORY = 'Default';

export const PushNotificationEditorProvider = ({
  children,
  isDisabled
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
}) => {
  const [status, setStatus] = useState<ChannelStatusEnum>(ChannelStatusEnum.UNDEFINED);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [deepLink, setDeepLink] = useState<string>('');
  const [senderId, setSenderId] = useState<string | undefined>(undefined);
  const [image, setImage] = useState<FileUploadInterface | null>(null);
  const [category, setCategory] = useState<string>(DEFAULT_CATEGORY);
  const [headerEditor] = useCreateEmptyEditor('push notif header');
  const [bodyEditor] = useCreateEmptyEditor('push notif body');
  const [isSilence, setIsSilence] = useState<boolean>(false);
  const [isActionRequired, setIsActionRequired] = useState<boolean>(false);
  const [buttonCta, setButtonCta] = useState<string | undefined>(undefined);
  const [initialData, setInitialData] = useState<
    PushNotificationChannelResponseInterface['data'] | null
  >(null);

  const [precodingMap, setPrecodingMap] = useState<RichTextPrecodingMap>({});
  const [readOnly, setReadOnly] = useReadOnlyURL();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const headerContent = useEditorListener(headerEditor, precodingMap);
  const bodyContent = useEditorListener(bodyEditor, precodingMap);

  const headerPrecodingError = useValidatePrecoding(headerContent, precodingMap);
  const bodyPrecodingError = useValidatePrecoding(bodyContent, precodingMap);
  const precodingError = useMergePrecodingValidationErrors([
    headerPrecodingError,
    bodyPrecodingError
  ]);

  const isFieldDisabled = useMemo(
    () => isDisabled || readOnly || isSubmitting,
    [readOnly, isSubmitting, isDisabled]
  );

  const contextValue = useMemo(() => {
    return {
      status,
      setStatus,
      headerEditor,
      bodyEditor,
      precodingMap,
      setPrecodingMap,
      deepLink,
      setDeepLink,
      image,
      setImage,
      category,
      setCategory,
      readOnly,
      setReadOnly,
      senderId,
      setSenderId,
      name,
      setName,
      description,
      setDescription,
      headerContent,
      bodyContent,
      isSilence,
      setIsSilence,
      isActionRequired,
      setIsActionRequired,
      buttonCta,
      setButtonCta,
      initialData,
      setInitialData,
      precodingError,
      isSubmitting,
      setIsSubmitting,
      isFieldDisabled
    };
  }, [
    status,
    headerEditor,
    bodyEditor,
    precodingMap,
    deepLink,
    image,
    category,
    readOnly,
    setReadOnly,
    senderId,
    name,
    description,
    headerContent,
    bodyContent,
    isSilence,
    isActionRequired,
    buttonCta,
    initialData,
    precodingError,
    isSubmitting,
    isFieldDisabled
  ]);

  return (
    <PushNotificationEditorContext.Provider value={contextValue}>
      {children}
    </PushNotificationEditorContext.Provider>
  );
};

export const usePushNotificationEditorContext = () => useContext(PushNotificationEditorContext);
