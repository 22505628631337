import axios from 'axios';
import {
  datadogRequestInterceptor,
  onFulfilledDefaultDatadogInterceptor,
  onRejectedDefaultDatadogInterceptor
} from '../datadog/axiosInterceptor';
// import { getAuthClient } from '@squantumengine/sqeid-react-browser';

const axiosAuthInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/auth/`,
  headers: {
    'Content-Type': 'application/json'
  }
});

// const sqeidRequestInterceptor = async (request: any) => {
//   const clientAuth = await getAuthClient();

//   request.headers['Auth-Client'] = btoa(JSON.stringify(clientAuth));

//   return request;
// };

const axiosRequestInterceptor = async (request: any) => {
  request = datadogRequestInterceptor(request);
  // request = await sqeidRequestInterceptor(request);
  return request;
};

axiosAuthInstance.interceptors.response.use(
  onFulfilledDefaultDatadogInterceptor,
  onRejectedDefaultDatadogInterceptor
);

axiosAuthInstance.interceptors.request.use(axiosRequestInterceptor);

export default axiosAuthInstance;
