import { Divider } from 'antd';
import { EmailContentInterface } from '../../shared/channel-detail-interface';
import { CampaignTypeEnum } from '../../shared/automated-campaign.interface';
import AttachmentPreviewGrid from '../rich-text/plugins/AttachmentPlugin/attachment-preview-grid';

function EmailContent({
  emailSender,
  emailCategory,
  emailSubject,
  renderedHtml,
  width,
  emailPreheader,
  campaignType,
  attachments
}: Readonly<EmailContentInterface>) {
  const normalizedWidth = width === '100%' ? undefined : width;
  return (
    <div className="h-full w-full">
      <div
        style={{
          width: normalizedWidth ?? '100%',
          boxSizing: normalizedWidth ? 'content-box' : 'border-box',
          height: normalizedWidth ? 'calc(100% - 40px)' : '100%'
        }}
        className="mx-auto flex max-w-[1048px] flex-shrink-0 flex-grow basis-0 flex-col items-start gap-5 self-stretch rounded-lg bg-white p-6"
      >
        <div className="flex max-w-full flex-col flex-wrap items-start gap-3">
          <div className="flex max-w-full items-start gap-1.5 self-stretch">
            <p className="m-0 min-w-[180px] text-base">Email Pengirim</p>
            <p
              className="m-0 min-w-0 flex-auto overflow-hidden text-ellipsis text-base font-semibold"
              title={emailSender}
            >
              {emailSender}
            </p>
          </div>
          {campaignType === CampaignTypeEnum.MANUAL && (
            <div className="flex max-w-full items-start gap-1.5 self-stretch">
              <p className="m-0 min-w-[180px] text-base">Kategori</p>
              <p
                className="m-0 min-w-0 flex-auto overflow-hidden text-ellipsis text-base font-semibold"
                title={emailCategory}
              >
                {emailCategory}
              </p>
            </div>
          )}
          <div className="flex max-w-full items-start gap-1.5 self-stretch">
            <p className="m-0 min-w-[180px] text-base">Subjek Email</p>
            <p className="m-0 text-base font-semibold">{emailSubject}</p>
          </div>
          <div className="flex max-w-full items-start gap-1.5 self-stretch">
            <p className="m-0 min-w-[180px] text-base">Preheader</p>
            <p className="m-0 text-base font-semibold">{emailPreheader}</p>
          </div>
        </div>
        <Divider className="m-0 bg-neutral-300" />
        <div className="preview-email-content-container h-full w-full overflow-auto">
          <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />
        </div>
        <Divider className="m-0 bg-neutral-300" />
        <AttachmentPreviewGrid attachments={attachments} />
      </div>
    </div>
  );
}

export default EmailContent;
