import { Icon, Paragraph, Spinner, Token } from '@squantumengine/horizon';
import { EmailAttachmentInterface } from '../attachment-plugin.interface';
import { Button } from 'antd';
import { getAttachmentErrorMessage } from '../attachment-plugin.helper';
import { EditorAttachmentCardProps } from './attachment-card.interface';
import AttachmentCardName from './attachment-card-name';

const { COLORS } = Token;

function AttachmentCardStateIcon(file: Readonly<EmailAttachmentInterface>) {
  if (file.errorType)
    return (
      <div data-testid="email-attachment-card-error-icon" className="h-6 w-6 shrink-0">
        <Icon name="ban" color={COLORS.red[500]} />
      </div>
    );
  if (file.isLoading)
    return (
      <div
        data-testid="email-attachment-card-spinner"
        className="flex h-6 w-6 shrink-0 items-center justify-center"
      >
        <Spinner size="sm" />
      </div>
    );
  return (
    <div data-testid="email-attachment-card-success-icon" className="h-6 w-6 shrink-0">
      <Icon name="check-circle" color={COLORS.green[500]} />
    </div>
  );
}

const EditorAttachmentCard = ({ file, onDelete }: Readonly<EditorAttachmentCardProps>) => {
  const { id, errorType } = file;
  return (
    <div data-testid="attachment-card" className="w-full">
      <div
        style={{ borderColor: errorType ? COLORS.red[500] : COLORS.neutral[200] }}
        className="box-border flex w-full items-center justify-between gap-4 overflow-hidden rounded-lg border border-solid px-4 py-2"
      >
        <AttachmentCardName file={file} />
        <div className="flex items-center gap-[10px]">
          <div className="flex h-[18px] w-[18px] items-center justify-center">
            <AttachmentCardStateIcon {...file} />
          </div>
          <Button
            data-testid="attachment-delete-button"
            onClick={() => onDelete(id)}
            type="text"
            className="p-0"
            size="small"
          >
            <div className="flex h-[18px] w-[18px] items-center justify-center">
              <Icon name="close-large" />
            </div>
          </Button>
        </div>
      </div>
      {errorType && (
        <Paragraph className="mb-3 mt-[10px] text-red-500">
          {getAttachmentErrorMessage(errorType)}
        </Paragraph>
      )}
    </div>
  );
};

export default EditorAttachmentCard;
