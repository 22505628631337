import AttachmentCardName from './attachment-card-name';
import { ReadOnlyAttachmentCardProps } from './attachment-card.interface';
import AttachmentDownloadWrapper from './attachment-download-wrapper';

const ReadOnlyAttachmentCard = ({ file }: Readonly<ReadOnlyAttachmentCardProps>) => {
  const { meta, thumbnail } = file;

  return (
    <AttachmentDownloadWrapper file={meta}>
      <div
        data-testid="attachment-card"
        className="group box-border flex w-full flex-col overflow-hidden rounded-lg border border-solid border-neutral-200 hover:border-blue-500"
      >
        <div className="aspect-video w-full overflow-hidden border-0 border-b border-solid border-neutral-200">
          <img
            className="h-full w-full object-cover object-top "
            src={thumbnail}
            alt={meta.name + ' preview'}
          />
        </div>
        <div className="box-border flex w-full items-center justify-between gap-4 overflow-hidden px-4 py-2 group-hover:bg-neutral-100">
          <AttachmentCardName file={file} readOnly />
        </div>
      </div>
    </AttachmentDownloadWrapper>
  );
};

export default ReadOnlyAttachmentCard;
