import { isDatadogLogEnabled, sendLogError, sendLogInfo, sendLogUnknownError } from '../datadog';

export const createResponseData = (response: any) => {
  const { config, data, status } = response;

  return {
    host: window.location.hostname,
    path: window.location.pathname,
    api_path: config?.url,
    method: config?.method,
    status_code: status,
    data
  };
};

export const onFulfilledDatadogResponseInterceptor = (response: any) => {
  if (!isDatadogLogEnabled) return;

  const { data, status } = response;

  sendLogInfo(`[${status}] ${JSON.stringify(data)}`, createResponseData(response));
};

export const onRejectedDatadogResponseInterceptor = (error: any) => {
  if (!isDatadogLogEnabled) return;

  if (!error?.response) {
    sendLogUnknownError(JSON.stringify(error?.request || error?.message || ''));
    return;
  }

  const { data, status } = error.response;

  sendLogError(`[${status}] ${JSON.stringify(data)}`, createResponseData(error.response));
};

export const datadogRequestInterceptor = (request: any) => {
  if (!isDatadogLogEnabled) return request;

  const { url, method } = request;

  sendLogInfo('Request', {
    host: window.location.hostname,
    path: window.location.pathname,
    api_path: url,
    method
  });

  return request;
};

export const onFulfilledDefaultDatadogInterceptor = (response: any) => {
  onFulfilledDatadogResponseInterceptor(response);
  return response;
};

export const onRejectedDefaultDatadogInterceptor = (error: any) => {
  onRejectedDatadogResponseInterceptor(error);
  return Promise.reject(error);
};
