import axiosInstance from '../config/axios/axiosWithToken';
import {
  BroadcastDetailResponseInterface,
  BroadcastListResponseInterface,
  BroadcastPayloadInterface,
  BroadcastQueryInterface,
  BroadcastResponseInterface,
  DeleteBroadcastResponseInterface
} from '../shared/broadcast.interface';

export const createBroadcast: (
  payload: BroadcastPayloadInterface
) => Promise<BroadcastResponseInterface> = async (payload) => {
  const response = await axiosInstance.post(`email/broadcast`, payload);

  return response.data;
};

export const editEmailBroadcast: (
  payload: BroadcastPayloadInterface
) => Promise<BroadcastResponseInterface> = async (payload) => {
  if (!payload.id) throw new Error('Broadcast ID is required');
  const response = await axiosInstance.patch(`email/broadcast/${payload.id}`, payload);

  return response.data;
};

export const getBroadcastList: (
  params: BroadcastQueryInterface
) => Promise<BroadcastListResponseInterface> = async (params) => {
  const response = await axiosInstance.get(`email/broadcast`, {
    params
  });
  return response.data;
};

export const deleteBroadcast: (id: string) => Promise<DeleteBroadcastResponseInterface> = async (
  id
) => {
  const response = await axiosInstance.delete(`email/broadcast/${id}`);
  return response.data;
};

export const createPushNotifBroadcast: (
  payload: BroadcastPayloadInterface
) => Promise<BroadcastResponseInterface> = async (payload) => {
  const response = await axiosInstance.post(`push_notification/broadcast`, payload);
  return response.data;
};

export const editPushNotifBroadcast: (
  payload: BroadcastPayloadInterface
) => Promise<BroadcastResponseInterface> = async (payload) => {
  if (!payload.id) throw new Error('Broadcast ID is required');
  const response = await axiosInstance.patch(`push_notification/broadcast/${payload.id}`, payload);
  return response.data;
};

export const getPushNotifBroadcastList: (
  params: BroadcastQueryInterface
) => Promise<BroadcastListResponseInterface> = async (params) => {
  const response = await axiosInstance.get(`push_notification/broadcast`, {
    params
  });
  return response.data;
};

export const deletePushNotifBroadcast: (
  id: string
) => Promise<DeleteBroadcastResponseInterface> = async (id) => {
  const response = await axiosInstance.delete(`push_notification/broadcast/${id}`);
  return response.data;
};

export const createWhatsappBroadcast: (
  payload: BroadcastPayloadInterface
) => Promise<BroadcastResponseInterface> = async (payload) => {
  const response = await axiosInstance.post(`whatsapp/broadcast`, payload);
  return response.data;
};

export const editWhatsappBroadcast: (
  payload: BroadcastPayloadInterface
) => Promise<BroadcastResponseInterface> = async (payload) => {
  if (!payload.id) throw new Error('Broadcast ID is required');
  const response = await axiosInstance.patch(`whatsapp/broadcast/${payload.id}`, payload);
  return response.data;
};

export const getWhatsappBroadcastList: (
  params: BroadcastQueryInterface
) => Promise<BroadcastListResponseInterface> = async (params) => {
  const response = await axiosInstance.get(`whatsapp/broadcast`, {
    params
  });
  return response.data;
};

export const deleteWhatsappBroadcast: (
  id: string
) => Promise<DeleteBroadcastResponseInterface> = async (id) => {
  const response = await axiosInstance.delete(`whatsapp/broadcast/${id}`);
  return response.data;
};

export const getPushNotifBroadcastDetail: (
  id: string
) => Promise<BroadcastDetailResponseInterface> = async (id) => {
  const response = await axiosInstance.get(`push_notification/broadcast/${id}`);
  return response.data;
};

export const getEmailBroadcastDetail: (
  id: string
) => Promise<BroadcastDetailResponseInterface> = async (id) => {
  const response = await axiosInstance.get(`email/broadcast/${id}`);
  return response.data;
};

export const getWhatsappBroadcastDetail: (
  id: string
) => Promise<BroadcastDetailResponseInterface> = async (id) => {
  const response = await axiosInstance.get(`whatsapp/broadcast/${id}`);
  return response.data;
};
