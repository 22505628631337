export interface UploadImageProps {
  image: string;
}

export interface UploadImageResponseProps {
  message: string;
  errors: string[];
  data: {
    id?: string;
    url?: string;
  };
}

export interface UploadPushNotifImageProps {
  name?: string;
  extension: string;
  md5?: string;
}

export interface UploadPushNotifImageResponseProps {
  message: string;
  errors: string[];
  data?: {
    id: string;
    upload_url: string;
  };
}

export interface UploadEmailAttachmentResponse {
  message: string;
  data: {
    id: string;
    upload_url: string;
  };
}

export enum EmailAttachmentUploadStatusEnum {
  WAITING_UPLOAD = 'waiting_upload',
  UPLOADED = 'uploaded',
  FILE_SIZE_TOO_BIG = 'file_size_too_big',
  FILE_CORRUPTED = 'file_corrupted'
}

export interface UploadEmailAttachmentStatusResponse {
  id: string;
  name: string;
  extension: string;
  download_url: string;
  size: string;
  status: EmailAttachmentUploadStatusEnum;
  uploaded: string;
  thumbnail: string;
}
