import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { fetchAutomatedCampaignDetail } from '../../api';
import { useQuery } from '@tanstack/react-query';
import { redirectTo404 } from '../../utils/error';
import { useNavigate } from 'react-router';

export const useGetAutomatedCampaignDetail = (id: string) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.automatedCampaignDetail, id],
    queryFn: async () => {
      try {
        return (await fetchAutomatedCampaignDetail(id)).data;
      } catch (error) {
        redirectTo404(navigate, error);
      }
    },
    enabled: !!id,
    retry: 1
  });
};
