import axiosInstance from '../config/axios/axiosWithToken';
import {
  FetchChannelAnalyticsPayload,
  FetchChannelAnalyticsResponse
} from '../hooks/analytics-query';
import {
  AnalyticsClickPerformance,
  AnalyticsExportDocumentResponse,
  AnalyticsExportStatusResponse,
  AnalyticsSummaryResponse
} from '../shared/analytics.interface';
import { ChannelKeyEnum } from '../shared/master-api.interface';

export const fetchAnalyticsSummary: (
  channelType: ChannelKeyEnum,
  channelId: string,
  from: string,
  to: string,
  broadcastId: string
) => Promise<AnalyticsSummaryResponse> = async (channelType, channelId, from, to, broadcastId) => {
  const response = await axiosInstance.get(
    `analytic/${channelType}/${channelId}/summary?from=${from}&to=${to}&broadcast_id=${broadcastId}`
  );
  return response.data;
};

export const fetchChannelAnalytics: (
  channelType: ChannelKeyEnum,
  channelId: string,
  payload: FetchChannelAnalyticsPayload
) => Promise<FetchChannelAnalyticsResponse> = async (channelType, channelId, payload) => {
  const response = await axiosInstance.get(`analytic/${channelType}/${channelId}/delivery`, {
    params: payload
  });
  return response.data;
};

export const exportChannelAnalytics: (
  channelId: string,
  from: string,
  to: string,
  broadcastId: string,
  channelType?: ChannelKeyEnum
) => Promise<AnalyticsExportStatusResponse> = async (
  channelId,
  from,
  to,
  broadcastId,
  channelType
) => {
  const response = await axiosInstance.get(
    `analytic/${channelType}/${channelId}/export?from=${from}&to=${to}&broadcast_id=${broadcastId}`
  );
  return response.data.data;
};

export const fetchChannelAnalyticsDocument: (
  exportId: string
) => Promise<AnalyticsExportDocumentResponse> = async (exportId) => {
  const response = await axiosInstance.get(`analytic/export_status/${exportId}`);
  return response.data.data;
};

export const fetchAnalyticsClickPerformance: (
  channelId: string,
  from: string,
  to: string,
  broadcastId: string,
  channelType?: ChannelKeyEnum
) => Promise<AnalyticsClickPerformance> = async (channelId, from, to, broadcastId, channelType) => {
  const response = await axiosInstance.get(
    `analytic/${channelType}/${channelId}/click_performance?from=${from}&to=${to}&broadcast_id=${broadcastId}`
  );
  return response.data.data;
};
