import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const AddSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
    <path
      d="M32.5 23C32.5 22.625 32.125 22.25 31.75 22.25H26.25V16.75C26.25 16.375 25.875 16 25.5 16H23.5C23.0625 16 22.75 16.375 22.75 16.75V22.25H17.25C16.8125 22.25 16.5 22.625 16.5 23V25C16.5 25.4375 16.8125 25.75 17.25 25.75H22.75V31.25C22.75 31.6875 23.0625 32 23.5 32H25.5C25.875 32 26.25 31.6875 26.25 31.25V25.75H31.75C32.125 25.75 32.5 25.4375 32.5 25V23ZM40 24C40 15.4375 33.0625 8.5 24.5 8.5C15.9375 8.5 9 15.4375 9 24C9 32.5625 15.9375 39.5 24.5 39.5C33.0625 39.5 40 32.5625 40 24ZM37 24C37 30.9375 31.375 36.5 24.5 36.5C17.5625 36.5 12 30.9375 12 24C12 17.125 17.5625 11.5 24.5 11.5C31.375 11.5 37 17.125 37 24Z"
      fill="currentColor"
    />
  </svg>
);

const AddIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AddSVG} {...props} />
);

export default AddIcon;
