import NotificationContent from '../../components/campaign-details/notification';
import { PushNotificationEditorProvider } from '../../context/push-notification-editor-context';

function NotificationDetail() {
  return (
    <PushNotificationEditorProvider>
      <NotificationContent />
    </PushNotificationEditorProvider>
  );
}

export default NotificationDetail;
