import { GetNextPageParamFunction } from '@tanstack/react-query';

export const getInfiniteQueryNextParams: GetNextPageParamFunction<number, any> = (
  lastPage,
  pages
) => {
  const itemsTotal = lastPage.pagination.total_items;
  const fetchedTotal = pages.reduce((a, b) => a + b.pagination.item_count, 0);
  return fetchedTotal >= itemsTotal ? undefined : lastPage.pagination.page + 1;
};
