import axiosInstance from '../config/axios/axiosWithToken';
import { FetchUploadContactUrlResponseInterface } from '../shared/contact.interface';
import {
  ExportStatusDataInterface,
  GetDownloadUnsubscribeUrlResponse,
  GetUnsubscribeListPayload,
  GetUnsubscribeListResponse,
  ImportStatusDataInterface,
  SetUnsubscribeConfigPayload,
  SetUnsubscribeConfigResponse,
  SubmitUnsubscribePaylod,
  SubmitUnsubscribeResponse,
  UnsubscribeConfigInterface,
  UnsubscribeGuestConfigInterface
} from '../shared/unsubscribe.interface';

export const getUnsubscribeConfig: () => Promise<UnsubscribeConfigInterface> = async () => {
  const response = await axiosInstance.get(`email/unsubscribe/configuration`);

  return response.data;
};

export const setUnsubscribeConfig: (
  payload: SetUnsubscribeConfigPayload
) => Promise<SetUnsubscribeConfigResponse> = async (payload) => {
  const response = await axiosInstance.put(`email/unsubscribe/configuration`, payload);

  return response.data;
};

export const getUnsubscribeList: (
  payload: GetUnsubscribeListPayload
) => Promise<GetUnsubscribeListResponse> = async (payload) => {
  const response = await axiosInstance.get(
    `email/unsubscribe/user?page=${payload.page}&limit=${payload.pageSize}&q=${payload.query}`
  );

  return response.data;
};

export const getUploadUnsubscribePresignedUrl: (
  name: string
) => Promise<FetchUploadContactUrlResponseInterface> = async (name) => {
  const response = await axiosInstance.get(`/email/unsubscribe/user/signed_url?name=${name}`);
  return response.data;
};

export const checkUploadUnsubscribeImportStatus: (fileId: string) => Promise<
  {
    message: string;
  } & ImportStatusDataInterface
> = async (fileId) => {
  const response = await axiosInstance.post(`/email/unsubscribe/user/import`, { file_id: fileId });
  return response.data;
};

export const getUnsubscribeImportStatus: () => Promise<
  ImportStatusDataInterface['data']
> = async () => {
  const response = await axiosInstance.get(`/email/unsubscribe/user/import`);
  return response.data.data;
};
export const getUnsubscribeConfigGuest: (
  token: string
) => Promise<UnsubscribeGuestConfigInterface> = async (token) => {
  axiosInstance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
  const response = await axiosInstance.get(`/email/guest/unsubscribe`);
  return response.data;
};

export const submitUnsubscribe: (
  payload: SubmitUnsubscribePaylod
) => Promise<SubmitUnsubscribeResponse> = async (payload) => {
  if (!payload.token) return;
  axiosInstance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${payload.token}`;
    return config;
  });
  const apiPayload = {
    category: payload.category,
    reason: payload.reason
  };
  const response = await axiosInstance.post(`email/guest/unsubscribe`, apiPayload);
  return response.data;
};

export const getUnsubscribeDownloadUrl: () => Promise<GetDownloadUnsubscribeUrlResponse> =
  async () => {
    const response = await axiosInstance.get(`/email/unsubscribe/user/import`);
    return response.data;
  };

export const updateUnsubscribePreferences: (id: string, category: string[]) => Promise<void> = (
  id,
  category
) => {
  return axiosInstance.patch(`email/unsubscribe/user/${id}`, { category });
};

export const deleteUnsubscribe: (id: string) => Promise<void> = (id) => {
  return axiosInstance.delete(`email/unsubscribe/user/${id}`);
};

export const getStatusExportUnsubscribe: () => Promise<
  ExportStatusDataInterface['data']
> = async () => {
  const response = await axiosInstance.get(`/email/unsubscribe/user/export`);
  return response.data.data;
};

export const exportUnsubscribe: (id: string) => Promise<GetDownloadUnsubscribeUrlResponse> = async (
  id
) => {
  const response = await axiosInstance.get(`/email/unsubscribe/user/export/${id}`);
  return response.data;
};
