import { Dispatch, SetStateAction } from 'react';
import { randomID } from '../../../../utils/math';
import {
  EmailAttachmentErrorEnum,
  EmailAttachmentInterface,
  EmailAttachmentInterfaceWithFile
} from './attachment-plugin.interface';
import { emailUploadHandler } from '../../../../utils/upload';
import {
  getEmailAttachmentPresignedUrl,
  getEmailAttachmentUploadStatus
} from '../../../../api/file-upload';
import { EmailDetailAttachmentInterface } from '../../../../shared/automated-campaign.interface';
import { retry } from '../../../../utils/retryAPI';
import { EmailAttachmentUploadStatusEnum } from '../../../../shared/file-upload.interface';
import { getFileExtension } from '../../../../utils/fileUtils';

export const MAX_ATTACHMENT_COUNT = 5;
export const MAX_TOTAL_ATTACHMENT_SIZE = 10 * 1024 * 1024; // 10MB
const VALID_EXTENSIONS_REGEX = /(\.pdf|\.xls|\.xlsx|\.zip|\.rar|\.jpg|\.jpeg|\.png|\.doc|\.docx)$/i;
export const VALID_EXTENSIONS = '.pdf,.xls,.xlsx,.zip,.rar,.jpg,.jpeg,.png,.doc,.docx';

export const generateNewAttachment = (
  file: File,
  currentAttachmentSize: number
): EmailAttachmentInterfaceWithFile => {
  const id = randomID();

  const newFile: EmailAttachmentInterfaceWithFile = {
    id,
    meta: {
      id,
      name: file.name,
      url: URL.createObjectURL(file)
    },
    size: file.size,
    isLoading: true,
    thumbnail: '',
    file
  };

  if (currentAttachmentSize + file.size > MAX_TOTAL_ATTACHMENT_SIZE) {
    newFile.errorType = EmailAttachmentErrorEnum.FILE_TOO_LARGE;
    newFile.isLoading = false;
    return newFile;
  }

  if (!VALID_EXTENSIONS_REGEX.test(file.name)) {
    newFile.errorType = EmailAttachmentErrorEnum.INVALID_FILE_TYPE;
    newFile.isLoading = false;
    return newFile;
  }

  return newFile;
};

export const getAttachmentErrorMessage = (errorType: EmailAttachmentErrorEnum) => {
  switch (errorType) {
    case EmailAttachmentErrorEnum.FILE_TOO_LARGE:
      return 'Ukuran dokumen melebihi ketentuan. Kecilkan ukuran dokumen, lalu unggah ulang.';
    case EmailAttachmentErrorEnum.INVALID_FILE_TYPE:
      return 'Format dokumen tidak sesuai ketentuan. Dokumen harus dalam format pdf, xlx, xlsx, zip, rar, doc, docx.';
    case EmailAttachmentErrorEnum.FAILED_TO_UPLOAD:
      return 'Gagal mengunggah berkas.';
    default:
      return '';
  }
};

const waitForAttachmentUpload = async (id: string) => {
  const data = await getEmailAttachmentUploadStatus(id);
  if (data.status === EmailAttachmentUploadStatusEnum.UPLOADED && !!data.thumbnail) return data;
  throw new Error();
};

export const uploadAttachmentToBE = async (
  attachment: EmailAttachmentInterfaceWithFile,
  setAttachment: Dispatch<SetStateAction<Map<string, EmailAttachmentInterface>>>
) => {
  // cloned to prevent direct mutation
  const newAttachment = structuredClone(attachment);

  try {
    const response = await emailUploadHandler(attachment.file, getEmailAttachmentPresignedUrl);
    const data = await retry(waitForAttachmentUpload, [response.id], 10);
    newAttachment.meta = {
      ...newAttachment.meta,
      ...response
    };
    newAttachment.thumbnail = data.thumbnail;
  } catch {
    newAttachment.errorType = EmailAttachmentErrorEnum.FAILED_TO_UPLOAD;
  } finally {
    newAttachment.isLoading = false;

    setAttachment((prev) => {
      const newMap = new Map(prev);
      newMap.set(attachment.id, newAttachment);
      return newMap;
    });
  }
};

export const attachmentListToMap = (
  attachmentList: EmailDetailAttachmentInterface[]
): Map<string, EmailAttachmentInterface> => {
  const normalizedList: [string, EmailAttachmentInterface][] = attachmentList.map((data) => {
    let filename = data.name;
    const fileExtension = getFileExtension(filename);
    if (!fileExtension) filename += `.${data.extension}`;

    return [
      data.id,
      {
        id: data.id,
        size: +data.size,
        isLoading: false,
        thumbnail: data.thumbnail,
        meta: {
          id: data.id,
          name: filename,
          url: data.download_url
        }
      }
    ];
  });

  return new Map(normalizedList);
};
