import { datadogLogs } from '@datadog/browser-logs';

const { REACT_APP_DATADOG_LOG_ENABLED } = process.env;

export enum LogType {
  INFO = 'info',
  ERROR = 'error'
}
type LogData = {
  host: string;
  path: string;
  method: string;
  api_path: string;
  status_code?: string | number;
};

export const checkIsEnvEnabled = (env?: string) => {
  return !!(env && env !== '');
};

export const isDatadogLogEnabled = checkIsEnvEnabled(REACT_APP_DATADOG_LOG_ENABLED);

export const sendLog = (logType: LogType, description: string, data: LogData) => {
  const { host, path, method, api_path, status_code } = data;
  datadogLogs.logger[logType](description, {
    host,
    http: {
      url_details: { path, method },
      api_path,
      status_code
    }
  });
};

export const sendLogInfo = (description: string, data: LogData) =>
  sendLog(LogType.INFO, description, data);

export const sendLogError = (description: string, data: LogData) =>
  sendLog(LogType.ERROR, description, data);

export const sendLogUnknownError = (data: any) =>
  datadogLogs.logger.error('An unknown error occured', {
    host: window.location.hostname,
    path: window.location.pathname,
    data
  });
