import { AxiosResponse } from 'axios';
import { CampaignTypeEnum } from './automated-campaign.interface';
import { TaggingInterface } from './tagging-config.interface';

export interface CampaignInterface {
  id: string;
  name: string;
  budget: number;
  schedule: number;
  note: string;
  email_subject: string;
  email_content: string;
  message?: string;
}

export interface addCampaignRequest {
  name: string;
  budget: number;
  schedule: number;
  note?: string;
  email_subject: string;
  email_content: string;
}

export interface addCampaignResponse extends AxiosResponse {
  data: CampaignInterface;
  message: string;
}

export interface detailCampaignInterface extends AxiosResponse {
  data: CampaignInterface;
  message: string;
}

export interface listCampaignProps extends AxiosResponse {
  [x: string]: any;
  message: string;
  data: CampaignProps[];
  pagination: Pagination;
}

export interface addAutomatedCampaignRequest {
  name: string;
  type?: string;
  description: string;
  tag?: CampaignTagBackendEnum;
}

export interface addAutomatedCampaignResponse {
  message: string;
  errors: {
    message: string;
    error: string;
  }[];
  data: {
    id: string;
    name: string;
    description: string;
    status: string;
  };
}

export interface CampaignProps {
  created_at: string;
  description: string;
  id: string;
  name: string;
  status: string;
  updated_at: string;
  type: CampaignTypeEnum;
  creator: string;
  total_channel: number;
  tag?: CampaignTagBackendEnum;
  labels?: TaggingInterface[];
  last_execution_at: string;
}

export interface Pagination {
  page: number;
  item_count: number;
  total_items: number;
}

export enum CampaignTagEnum {
  SQECC = 'cc'
}

export enum CampaignTagBackendEnum {
  SQECC = 'sqecc'
}

export interface FilterProps {
  types: string[];
  labels: string[];
  tags: string[];
  statuses: string[];
}

export interface FilterOption {
  type: string;
  label: string;
  isActive: boolean;
}

export interface Filter {
  queryParam: string;
  category: string;
  options: FilterOption[];
}
