import { ChannelDetailInterface } from '../../../shared/automated-campaign.interface';
import { useWhatsAppButtonStore } from '../../../store/whatsapp-button-store';
import { HEADER_TYPE_ENUM } from './header.enum';
import { FileUploadInterface } from '../../file-upload/file-upload.interface';
import { parseUnixDateInMs } from '../../../utils/date';
import { emptyState } from '../../../hooks/useCreateEmptyEditor';

const getInitialJSON = (json?: string) => {
  try {
    // @ts-ignore
    return JSON.parse(json);
  } catch {
    return { body_json: emptyState, header_json: emptyState };
  }
};

export const setInitialValue = ({
  dataChannel,
  setState,
  setThumbnail
}: {
  dataChannel?: ChannelDetailInterface['data'];
  setThumbnail: React.Dispatch<React.SetStateAction<FileUploadInterface | null>>;
  setState: any;
}) => {
  const { name, description, template, sender, status } = dataChannel ?? {};
  const content = JSON.parse(template?.content ?? '{}');
  const contentJson = getInitialJSON(template?.content_json);
  const content_json = contentJson.body_json;
  const header_json = contentJson.header_json;
  const header = content.find?.((item: { type: any }) => item.type === 'HEADER');
  const body = content.find?.((item: { type: any }) => item.type === 'BODY');
  const footer = content.find?.((item: { type: any }) => item.type === 'FOOTER');
  const buttons = content.find?.((item: { type: any }) => item.type === 'BUTTONS')?.buttons || [];
  useWhatsAppButtonStore.getState().init(buttons);

  const getHeaderType = () => {
    switch (header?.format) {
      case HEADER_TYPE_ENUM.text:
        return 'TEXT';
      case HEADER_TYPE_ENUM.image:
      case HEADER_TYPE_ENUM.video:
      case HEADER_TYPE_ENUM.document:
        return 'MEDIA';
      default:
        return null;
    }
  };
  setThumbnail(
    header?.format !== 'TEXT'
      ? {
          id: header?.file,
          url: (template as any)?.thumbnail?.url,
          name: (template as any)?.thumbnail?.name
        }
      : null
  );

  // somehow the data can be empty if directly initialized
  setTimeout(() => {
    setState((prev: any) => {
      return {
        ...prev,
        title: name,
        description: description,
        selectedCategory: (template as any)?.category,
        language: (template as any)?.language,
        footer: footer?.text,
        sender: {
          id: sender?.id
        },
        headerType: getHeaderType(),
        headerText: header?.format === 'TEXT' ? header?.text : '',
        mediaType: header?.format !== 'TEXT' ? header?.format : '',
        body: body?.text,
        header_json,
        content_json,
        initalBodyJson: content_json,
        initalHeaderJson: header_json,
        addSecurityRecommendation: body?.add_security_recommendation,
        addCodeExpiration: Boolean(footer?.code_expiration_minutes),
        codeExpiration: footer?.code_expiration_minutes || null,
        copyBtnText: buttons.find((button: any) => button.otp_type === 'COPY_CODE')?.text || '',
        lastStatusChange: parseUnixDateInMs(template?.last_status_change),
        status
      };
    });
  }, 100);
};
