import { Button, Icon } from '@squantumengine/horizon';

interface TestDataListItemProps {
  testData: string;
  onDelete: (testData: string) => void;
  isTestDataFullWidth?: boolean;
}

function TestDataListItem({ testData, onDelete }: TestDataListItemProps) {
  return (
    <div className="group flex h-8 w-full items-center justify-between rounded-lg border-[1px] border-solid border-neutral-200 px-4 py-2">
      <p className="truncate text-sm">{testData}</p>
      <Button
        variant="text"
        className="bg-transparent p-0 text-neutral-950"
        onClick={() => onDelete(testData)}
        data-testid="delete-button"
      >
        <Icon name="close-small" />
      </Button>
    </div>
  );
}

export default TestDataListItem;
