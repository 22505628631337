import { NotificationConfig } from '@squantumengine/horizon';
import {
  CheckerMakerNotificationEventEnum,
  NotificationInterface
} from '../../shared/notification.interface';
import { ReactNode } from 'react';
import { generateCheckerMakerNotificationData } from './checker-maker';

export interface NotificationCardProps<T> extends NotificationConfig<T> {
  prefix?: ReactNode;
}

const normalizeNotificationData = (
  notification: NotificationInterface
): NotificationCardProps<NotificationInterface> => {
  switch (notification.event) {
    case CheckerMakerNotificationEventEnum.PN_APPROVAL_REQUEST:
    case CheckerMakerNotificationEventEnum.PN_APPROVAL_STATUS:
    case CheckerMakerNotificationEventEnum.EMAIL_APPROVAL_REQUEST:
    case CheckerMakerNotificationEventEnum.EMAIL_APPROVAL_STATUS:
    case CheckerMakerNotificationEventEnum.WA_APPROVAL_REQUEST:
    case CheckerMakerNotificationEventEnum.WA_APPROVAL_STATUS:
      return generateCheckerMakerNotificationData(notification);
  }
};

export const normalizeNotificationList = (
  notifications: NotificationInterface[]
): NotificationCardProps<NotificationInterface>[] => {
  return notifications.map(normalizeNotificationData);
};
