import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const RepeatSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6871 6.10393C12.5829 5.97893 12.4162 5.95809 12.3121 6.08309C12.2287 6.14559 12.1246 6.24976 11.9371 6.43726C11.8537 6.52059 11.8537 6.64559 11.9162 6.74976C12.1662 7.10393 12.3121 7.54143 12.3329 7.99976C12.3329 9.20809 11.3121 10.1664 10.1246 10.1664H6.33291V8.83309C6.33291 8.62476 6.06208 8.49976 5.89541 8.66642L4.06208 10.4998C3.95791 10.6039 3.95791 10.7498 4.06208 10.8539L5.89541 12.6873C6.06208 12.8539 6.31208 12.7289 6.33291 12.4998V11.1664H10.1246C11.8746 11.1664 13.3121 9.77059 13.3121 8.02059C13.3329 7.31226 13.0829 6.64559 12.6871 6.10393ZM3.66625 8.02059C3.64541 6.81226 4.66625 5.83309 5.85375 5.83309H9.66625V7.16643C9.66625 7.39559 9.91625 7.52059 10.0829 7.35393L11.9162 5.52059C12.0204 5.41642 12.0204 5.27059 11.9162 5.16643L10.0829 3.33309C9.91625 3.16643 9.66625 3.29143 9.66625 3.49976V4.83309H5.85375C4.10375 4.83309 2.66625 6.24976 2.66625 7.99976C2.64541 8.70809 2.89541 9.37476 3.29125 9.91642C3.39541 10.0414 3.56208 10.0623 3.66625 9.93726C3.74958 9.87476 3.85375 9.77059 4.04125 9.58309C4.12458 9.49976 4.12458 9.37476 4.06208 9.27059C3.81208 8.91642 3.66625 8.47893 3.66625 8.02059Z"
      fill="#E5AC00"
    />
  </svg>
);

const RepeatIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RepeatSVG} {...props} />
);

export default RepeatIcon;
