export enum ErrorCode {
  TooLongDesc = 'invalid_description_len',
  TooLongTittle = 'invalid_name_len',
  TooLongSubject = 'subject_too_long'
}

export enum HTTPErrorCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500
}
