const baseStyle = `
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.7;
  font-family: 'Arial', 'Verdana', sans-serif;
}
`;

export default baseStyle;
