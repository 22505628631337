import { useCallback, useEffect, useMemo, useState } from 'react';

import { useWhatsappEditorContext } from '../../../context/whatsapp-editor-context';
import { whatsAppBodyStyle, whatsAppHeadStyle } from '../../../static/editor-style/whatsapp-style';
import MediaPreview from './section/media-preview';
import WhatsAppButtonContent from './whatsapp-button-content';
import { HEADER_TYPE_ENUM } from '../helper/header.enum';
import FooterPreview from './section/footer-preview';
import {
  parseEditorToHtmlWithPrecoding,
  recolorAllPrecodingBrackets
} from '../../../utils/lexical';

function WhatsappContent() {
  const { headerEditor, bodyEditor, state, precodingMap, selectedPrecoding } =
    useWhatsappEditorContext();
  const [headerContent, setHeaderContent] = useState<string>('');
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [isHeaderEmpty, setIsHeaderEmpty] = useState<boolean>(true);

  const precodingProps = useMemo(
    () => ({
      selectedPrecoding,
      precodingMap
    }),
    [selectedPrecoding, precodingMap]
  );

  const checkEmptyHeader = useCallback(() => {
    const element = document.createElement('div');
    element.innerHTML = headerContent;
    setIsHeaderEmpty(!element.textContent);
    // eslint-disable-next-line
  }, [headerEditor, headerContent]);

  const generateHeaderContent = useCallback(() => {
    checkEmptyHeader();
    const htmlWithPrecoding = parseEditorToHtmlWithPrecoding(
      headerEditor,
      whatsAppHeadStyle,
      precodingProps
    );
    const htmlWithInvalidBrackets = recolorAllPrecodingBrackets(htmlWithPrecoding);
    setHeaderContent(htmlWithInvalidBrackets);
  }, [checkEmptyHeader, headerEditor, precodingProps]);

  const generateBodyContent = useCallback(() => {
    const htmlWithPrecoding = parseEditorToHtmlWithPrecoding(
      bodyEditor,
      whatsAppBodyStyle,
      precodingProps
    );
    const htmlWithInvalidBrackets = recolorAllPrecodingBrackets(htmlWithPrecoding);
    setHtmlContent(htmlWithInvalidBrackets);
  }, [bodyEditor, precodingProps]);

  useEffect(() => {
    generateHeaderContent();
    generateBodyContent();

    headerEditor.registerUpdateListener(generateHeaderContent);
    bodyEditor.registerUpdateListener(generateBodyContent);
  }, [generateHeaderContent, generateBodyContent, headerEditor, bodyEditor]);

  return (
    <div className="relative min-h-fit rounded-lg border-none bg-neutral-0">
      {state.headerType === HEADER_TYPE_ENUM.media ? (
        <MediaPreview />
      ) : (
        !isHeaderEmpty && (
          <>
            <div
              className="inline-block max-w-full p-2 pb-0"
              style={{ wordWrap: 'break-word' }}
              dangerouslySetInnerHTML={{ __html: headerContent }}
            />
            <br />
          </>
        )
      )}
      <div className="px-2 py-[7px]">
        <div className="flex flex-col items-start gap-1 self-stretch">
          <div
            className="inline-block max-w-full"
            style={{ wordWrap: 'break-word' }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
          <FooterPreview />
        </div>
      </div>
      <WhatsAppButtonContent />
    </div>
  );
}

export default WhatsappContent;
