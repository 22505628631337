import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './App.css';
import Router from './Router';
import { Suspense } from 'react';
import { ConfigProvider, Spin } from 'antd';
import { SimasAuthEnv, SimasAuthProvider } from '@squantumengine/sqeid-react-browser';
import { colors } from './static/colors';
import { ToasterProvider, Token } from '@squantumengine/horizon';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

import 'dayjs/locale/id';

dayjs.extend(utc);
dayjs.extend(tz);

const { COLORS } = Token;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2
    }
  }
});

function App() {
  return (
    <SimasAuthProvider
      defaultValues={{
        clientId: process.env.REACT_APP_SQE_ID_CLIENT_ID ?? '',
        env: (process.env.REACT_APP_ENV as SimasAuthEnv) ?? 'development',
        scope: 'email openid name',
        isInternal: true,
        logoutCallbackUri: '/login',
        loginCallbackUri: '/campaign',
        loginUri: '/sso-callback/',
        tokenEncryptionKey: process.env.REACT_APP_ENCRYPT_TOKEN
      }}>
      <ConfigProvider
        theme={{
          token: { fontFamily: 'Inter', colorPrimary: COLORS.blue[500] },
          components: {
            Select: {
              colorPrimary: COLORS.blue[500],
              colorPrimaryActive: COLORS.blue[500],
              colorPrimaryHover: COLORS.blue[500],
              borderRadiusLG: 8,
              controlPaddingHorizontal: 16,
              controlHeightLG: 40,
              colorBorder: COLORS.neutral[300]
            },
            Radio: {
              radioSize: 20,
              colorPrimaryActive: COLORS.red[500],
              colorBorder: COLORS.neutral[950]
            },
            Message: {
              colorBgElevated: colors.red.imperial
            }
          }
        }}>
        <QueryClientProvider client={queryClient}>
          <ToasterProvider
            settings={{
              position: 'top',
              timeout: 3 // 3 seconds
            }}>
            <Suspense
              fallback={
                <div className="flex h-screen w-screen items-center justify-center">
                  <div className="flex flex-col text-center">
                    <Spin size="large" className="mb-[34px]" />
                  </div>
                </div>
              }>
              <Router />
            </Suspense>
          </ToasterProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </SimasAuthProvider>
  );
}

export default App;
