import { FILE_TYPE_ENUM, FileUploadInterface } from './file-upload.interface';
import { Document, Image, Video } from '../whatsapp-editor/editor/icons';
import { convertFileToBase64 } from '../../utils/fileUtils';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { Token } from '@squantumengine/horizon';

// adjust the codebase as necessary if multiple files are allowed.
export const UPLOAD_FILE_COUNT = 1;

export const renderMediaIconPreview = ({
  type,
  size = 56,
  color = Token.COLORS.neutral[300]
}: {
  type: string;
  size?: number;
  color?: string;
}) => {
  switch (type) {
    case FILE_TYPE_ENUM.IMAGE:
      return <Image data-testid="image-icon" width={size} color={color} />;
    case FILE_TYPE_ENUM.VIDEO:
      return <Video data-testid="video-icon" width={size} color={color} />;
    default:
      return <Document data-testid="document-icon" width={size} color={color} />;
  }
};

export const uploadFileAPIHandler = async (
  file: File,
  uploadHandler?: (file: File, base64: string) => Promise<FileUploadInterface>
) => {
  const fileName = file.name;
  const base64File = (await convertFileToBase64(file)) as string;

  if (uploadHandler) {
    const data = await uploadHandler(file, base64File);
    return data;
  }

  return {
    id: '',
    url: base64File,
    name: fileName
  };
};

export const uploadChangeHandler = (info: UploadChangeParam<UploadFile<any>>) => {
  let newFileList = [...info.fileList];

  // Only to show one recent uploaded files, and old ones will be replaced by the new
  newFileList = newFileList.slice(-UPLOAD_FILE_COUNT);

  // 2. Read from response and show file link
  const parsedFileList: FileUploadInterface[] = newFileList.map((file) => {
    return {
      ...file,
      id: file?.response?.id,
      url: file?.response?.url
    };
  });

  return parsedFileList[0] || null;
};
