import { generateRandomSecret } from './oAuth';

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function randomID(length: number = 10) {
  return generateRandomSecret({
    length,
    useSpecialChar: false
  });
}

export function fileSizeParser(size: number, decimal: number = 2) {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return +(size / Math.pow(1024, i)).toFixed(decimal) * 1 + '' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}
