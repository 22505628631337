import { AngleDownIcon } from '../../../../assets/icons';

interface DropdownToggleIconProps {
  isCollapsed: boolean;
  setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
  dataTestId?: string;
}

function DropdownToggleIcon({ isCollapsed, setIsCollapsed, dataTestId }: DropdownToggleIconProps) {
  return (
    <div
      className="flex cursor-pointer items-center justify-center rounded-md p-2 hover:bg-grey-10"
      role="button"
      data-testid={dataTestId}
      onClick={() => {
        setIsCollapsed?.((prev) => !prev);
      }}
      style={{
        transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)'
      }}
    >
      <AngleDownIcon />
    </div>
  );
}

export default DropdownToggleIcon;
