import { Button, Icon, Paragraph, TextField, Token } from '@squantumengine/horizon';
import { Popover } from 'antd';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import useCommentBoxContent, { UseCommentBoxContentProps } from './useCommentBoxContent';

interface CommentBoxContentProps extends UseCommentBoxContentProps {
  isEdit: boolean;
  onCancel: () => void;
}

const CommentBoxContent = (props: Readonly<CommentBoxContentProps>) => {
  const { isEdit, onCancel } = props;
  const { onEmojiClick, onKeyUp, handleSubmit, commentValue, commentText, setCommentText } =
    useCommentBoxContent(props);

  if (!isEdit) return <Paragraph>{commentValue}</Paragraph>;

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2">
      <TextField
        multiline
        value={commentText}
        onChange={setCommentText}
        className="bg-neutral-0"
        inputProps={{ onKeyUp, onMouseUp: onKeyUp }}
        autoFocus
        placeholder="Tulis di sini"
      />
      {!!commentText && (
        <div data-testid className="flex items-center justify-between gap-2">
          <Popover
            trigger="click"
            placement="left"
            content={
              <EmojiPicker
                open
                lazyLoadEmojis
                onEmojiClick={onEmojiClick}
                emojiStyle={EmojiStyle.NATIVE}
                emojiVersion="0.6"
              />
            }
          >
            <Button variant="text" type="button" className="px-1">
              <Icon size="lg" name="smile" color={Token.COLORS.blue[500]} />
            </Button>
          </Popover>
          <div className="flex justify-end gap-2">
            <Button variant="text" type="button" onClick={onCancel}>
              Batalkan
            </Button>
            <Button variant="primary" type="submit">
              Tambahkan
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};

export default CommentBoxContent;
