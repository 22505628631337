import { Icon, Message } from '@squantumengine/horizon';
import {
  CheckerMakerNotificationData,
  CheckerMakerNotificationInterface
} from '../../shared/notification.interface';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { CheckerMakerActivityActionEnum } from '../../shared/checker-maker.interface';
import { NotificationCardProps } from '.';
import { randomID } from '../math';

const channelIcons = {
  [ChannelKeyEnum.EMAIL]: 'email',
  [ChannelKeyEnum.PUSH_NOTIFICATION]: 'bell-on',
  [ChannelKeyEnum.WHATSAPP]: 'whatsapp'
};

export const generateCheckerMakerNotificationPrefixIcon = (data: CheckerMakerNotificationData) => {
  switch (data.action) {
    case CheckerMakerActivityActionEnum.APPROVE:
    case CheckerMakerActivityActionEnum.REQUEST:
      return channelIcons[data.channel_type];
    default:
      return 'exclamation-triangle';
  }
};

const checkerMakerSubject = {
  [CheckerMakerActivityActionEnum.REQUEST]: 'Konten menunggu persetujuan Anda',
  [CheckerMakerActivityActionEnum.APPROVE]: 'Konten Anda telah disetujui',
  [CheckerMakerActivityActionEnum.DECLINE]: 'Konten Anda telah ditolak',
  [CheckerMakerActivityActionEnum.EXPIRED]: 'Konten gagal terbit karena tidak kunjung disetujui'
};

const channelLabel = {
  [ChannelKeyEnum.EMAIL]: 'Email',
  [ChannelKeyEnum.PUSH_NOTIFICATION]: 'Push Notifikasi',
  [ChannelKeyEnum.WHATSAPP]: 'WhatsApp'
};

const notificationRole = {
  [CheckerMakerActivityActionEnum.APPROVE]: 'Reviewer',
  [CheckerMakerActivityActionEnum.DECLINE]: 'Reviewer',
  [CheckerMakerActivityActionEnum.REQUEST]: 'Editor',
  [CheckerMakerActivityActionEnum.EXPIRED]: 'Editor'
};

const generateCheckerMakerMessages = (data: CheckerMakerNotificationData): Message[] => {
  return [
    {
      id: randomID(),
      text: `${channelLabel[data.channel_type]}: ${data.channel_name}`,
      variant: 'DEFAULT',
      tags: [],
      details: []
    },
    {
      id: randomID(),
      text: `${notificationRole[data.action]}: ${data.user_name}`,
      variant: 'MUTED',
      tags: [],
      details: []
    }
  ];
};

export const generateCheckerMakerNotificationData = (
  data: CheckerMakerNotificationInterface
): NotificationCardProps<CheckerMakerNotificationInterface> => {
  return {
    id: data.id,
    subject: checkerMakerSubject[data.data.action],
    eventTime: new Date(+data.created_at).toISOString(),
    isSeen: data.is_seen,
    messages: generateCheckerMakerMessages(data.data),
    prefix: (
      <span className="mr-3">
        <Icon
          name={generateCheckerMakerNotificationPrefixIcon(data.data)}
          size="xl"
          color="currentColor"
        />
      </span>
    ),
    action: data
  };
};
