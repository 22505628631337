import { PushNotificationPreviewInterface } from './push-notification-preview.interface';

function PreviewContentDefault({
  image,
  header,
  body,
  truncate
}: Readonly<PushNotificationPreviewInterface>) {
  return (
    <div className="flex gap-2">
      <div className="w-full flex-1 overflow-hidden">
        <p
          className={'my-1 font-semibold' + (truncate ? ' truncate' : '')}
          dangerouslySetInnerHTML={{ __html: header }}
        />
        <p
          className={'my-1' + (truncate ? ' truncate' : '')}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
      {image && <img className="h-8 w-8 rounded" src={image} alt="" />}
    </div>
  );
}

export default PreviewContentDefault;
