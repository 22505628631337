import { CampaignTypeEnum } from '../shared/automated-campaign.interface';
import { ChannelKeyEnum } from '../shared/master-api.interface';
import { AuthActionScopeEnum } from '../shared/user.interface';
import { EmailChannelIcon, PushNotifIcon, WaChannelIcon } from '../assets/icons';

export const getChannelTypeLabel = (type?: string, defaultValue = '') => {
  switch (type) {
    case ChannelKeyEnum.EMAIL:
      return 'Email';
    case ChannelKeyEnum.PUSH_NOTIFICATION:
      return 'Push Notification';
    case ChannelKeyEnum.WHATSAPP:
      return 'WhatsApp';
    default:
      return defaultValue;
  }
};

export const getChannelConfig = ({
  channelType
}: {
  channelType: ChannelKeyEnum;
  campaignType?: CampaignTypeEnum;
  isCheckerMaker: boolean;
}) => {
  switch (channelType) {
    case ChannelKeyEnum.EMAIL:
      return {
        icon: <EmailChannelIcon />,
        label: 'Email',
        redirect: {
          read: {
            scope: AuthActionScopeEnum.CHANNEL_EMAIL_READ,
            url: ''
          },
          edit: {
            scope: AuthActionScopeEnum.CHANNEL_EMAIL_WRITE,
            url: '/edit'
          },
          delete: {
            scope: AuthActionScopeEnum.CHANNEL_EMAIL_WRITE
          }
        }
      };
    case ChannelKeyEnum.WHATSAPP:
      return {
        icon: <WaChannelIcon />,
        label: 'WhatsApp',
        redirect: {
          read: {
            scope: AuthActionScopeEnum.CHANNEL_WA_READ,
            url: ''
          },
          edit: {
            scope: AuthActionScopeEnum.CHANNEL_WA_WRITE,
            url: '/edit'
          },
          delete: {
            scope: AuthActionScopeEnum.CHANNEL_WA_WRITE
          }
        }
      };
    case ChannelKeyEnum.PUSH_NOTIFICATION:
      return {
        icon: <PushNotifIcon />,
        label: 'Push Notification',
        redirect: {
          read: {
            scope: AuthActionScopeEnum.CHANNEL_PN_READ,
            url: ''
          },
          edit: {
            scope: AuthActionScopeEnum.CHANNEL_PN_WRITE,
            url: '/edit'
          },
          delete: {
            scope: AuthActionScopeEnum.CHANNEL_PN_WRITE
          }
        }
      };
  }
};
