
import { WhatsAppButtonInterface, WhatsAppButtonTypeEnum } from './section/button/button.interface';
import { getButtonPreview } from './section/button/button.helper';
import { useWhatsappEditorContext } from '../../../context/whatsapp-editor-context';


interface WhatsAppAuthButtonContentCardProps {
  button?: WhatsAppButtonInterface;
}

enum buttonCopyTextEnum {
  'en_US'= 'Copy code',
  'id'= "Salin kode"
}

enum buttonAutoFillTextEnum {
  'en_US'= 'Auto-fill',
  'id'= 'Isi otomatis'
}

function ButtonContentCard({ button }: WhatsAppAuthButtonContentCardProps) {
  const buttonData = getButtonPreview(button);
  const { icon: Icon, text } = buttonData;

  return (
    <div className="flex h-[34px] w-full items-center justify-center gap-1 border-0 border-t border-solid border-grey-50 text-blue-star-light first:border-t-0">
      <span className="h-3 w-3">
        <Icon />
      </span>
      <span>{text}</span>
    </div>
  );
}

function WhatsAppAuthButtonContent() {
  const { state } = useWhatsappEditorContext();

  return (
    <div className="flex flex-col border-0 border-t border-solid border-grey-50 px-2">
      {state.codeDeliveryType === 'auto' && (
        <ButtonContentCard button={{ type: WhatsAppButtonTypeEnum.AUTO_FILL, text: state.autoBtnText || buttonAutoFillTextEnum[state.language as keyof typeof buttonAutoFillTextEnum] }} />
      )}
      <ButtonContentCard button={{ type: WhatsAppButtonTypeEnum.COPY, text: state.copyBtnText || buttonCopyTextEnum[state.language as keyof typeof buttonCopyTextEnum]}} />
    </div>
  );
}

export default WhatsAppAuthButtonContent;
