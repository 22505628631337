import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCheckerContext } from './checker-context';
import { findElementByPrefix, generateId } from './checker.utils';
import { CommentInterface } from './checker.interface';

export interface UseCheckerProps {
  anchorPrefix: string;
  isDynamic?: boolean;
}

const acceptedTags = new Set([
  'P',
  'SPAN',
  'H1',
  'H2',
  'H3',
  'H4',
  'H5',
  'H6',
  'B',
  'I',
  'U',
  'STRONG',
  'EM',
  'IMG',
  'BUTTON',
  'A',
  'SVG'
]);

const useChecker = ({ anchorPrefix, isDynamic }: Readonly<UseCheckerProps>) => {
  const { commentAnchors, commentMap, selectedCommentAnchor, setSelectedCommentAnchor, disabled } =
    useCheckerContext();
  const ref = useRef<HTMLDivElement>(null);
  const [selectedElement, setSelectedElement] = useState<null | string>(null);
  const getAnchor = useCallback(
    (element: Element) => {
      if (!ref.current) return;

      const hoveredAction = ref.current.getElementsByClassName('checker-action-hover');
      if (hoveredAction?.length) return;

      if (isDynamic) return generateId(ref.current, element, anchorPrefix);

      // for static, find the className with the valid prefix
      return findElementByPrefix(ref.current, element, anchorPrefix);
    },
    [anchorPrefix, isDynamic]
  );

  const overEventListener = useCallback(
    (e: MouseEvent) => {
      const element: Element = e.target as Element;
      if (isDynamic && !acceptedTags.has(element.tagName)) return;

      const anchor = getAnchor(element);
      if (!anchor) return;

      const existing = commentMap.get(anchor);
      if (existing) return setSelectedElement(null);

      if (selectedCommentAnchor) return;
      if (isDynamic) {
        element.classList.add('checker-hover');
      } else {
        ref.current?.getElementsByClassName(anchor)?.item(0)?.classList.add('checker-hover');
      }
      setSelectedElement(anchor);
    },
    [commentMap, getAnchor, selectedCommentAnchor, isDynamic]
  );

  const leaveEventListener = useCallback(
    (e: MouseEvent) => {
      const element: Element = e.target as Element;
      if (isDynamic) {
        element.classList.remove('checker-hover');
      } else {
        const anchor = getAnchor(element);
        if (!anchor) return;
        ref.current?.getElementsByClassName(anchor)?.item(0)?.classList.remove('checker-hover');
      }
    },
    [isDynamic, getAnchor]
  );

  useEffect(() => {
    const element = ref.current;

    if (element && !disabled) {
      element.addEventListener('mouseover', overEventListener);
      element.addEventListener('mouseout', leaveEventListener);

      return () => {
        element.removeEventListener('mouseover', overEventListener);
        element.removeEventListener('mouseout', leaveEventListener);
      };
    }
  }, [leaveEventListener, overEventListener, disabled]);

  const onLeave = () => {
    const hoveredAction = ref.current?.getElementsByClassName('checker-action-hover');
    if (!hoveredAction?.length) {
      setSelectedElement(null);
      setSelectedCommentAnchor(null);
    }
  };

  const commentList = useMemo(() => {
    const commentList: [CommentInterface, number][] = [];

    commentAnchors.forEach((anchor, idx) => {
      const comment = commentMap.get(anchor);
      if (!comment) return;
      if (!comment.anchor.startsWith(anchorPrefix)) return;
      commentList.push([comment, idx]);
    });

    return commentList;
  }, [commentAnchors, commentMap, anchorPrefix]);

  const removeSelectedElement = useCallback(() => {
    setSelectedElement(null);
  }, []);

  return { onLeave, commentList, selectedElement, ref, removeSelectedElement };
};

export default useChecker;
