import { NoticeType } from 'antd/lib/message/interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getErrorCode, getErrorList, getErrorMessage } from '../../utils/error';
import { ErrorMessage } from '../../shared/errorMessage.enum';
import { ErrorCode, HTTPErrorCode } from '../../shared/errorCode.enum';
import { createEmailChannel } from '../../api/automated-campaign';

const getErrorMessageList = (error: any): string | string[] => {
  const status = getErrorCode(error);

  if (status === HTTPErrorCode.CONFLICT) return ErrorMessage.ChannelTitleAlreadyExist;

  const errorList = getErrorList(error);

  if (status === HTTPErrorCode.BAD_REQUEST && errorList.length) {
    const errorList: string[] = [];

    getErrorList(error).forEach((error: any) => {
      switch (error.code) {
        case ErrorCode.TooLongDesc:
          return errorList.push(ErrorMessage.TooLongChannelDesc);
        case ErrorCode.TooLongTittle:
          return errorList.push(ErrorMessage.TooLongTitleChannel);
        case ErrorCode.TooLongSubject:
          return errorList.push(ErrorMessage.TooLongSubjectChannel);
        default:
          return;
      }
    });

    return errorList;
  }

  const errorMessage = getErrorMessage(error);

  if (errorMessage === 'email content too big')
    return 'Ukuran email melebihi ketentuan. Cek kembali konten email Anda.';

  return errorMessage;
};

export const handleAddNewChannelError =
  (toastMessage: (type: NoticeType, message: string) => void) => (error: any) => {
    const errorMessage = getErrorMessageList(error);

    if (typeof errorMessage === 'string') {
      toastMessage('error', errorMessage);
      return;
    }

    errorMessage.forEach((message) => {
      toastMessage('error', message);
    });
  };

export const useCreateEmailContent = (
  handleError: (value: boolean) => void,
  toastMessage: (type: NoticeType, message: string) => void,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.addNewChannel],
    mutationFn: createEmailChannel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.channelListAutomated] });
      handleError(false);
      onSuccess?.();
    },
    onError: handleAddNewChannelError(toastMessage)
  });
};
