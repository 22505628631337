import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  GetCommentListResponseInterface,
  ParamsCommentListInterface
} from '../../shared/checker-maker.interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { handleAxiosError } from '../../api/error-handler/error-handler';
import { useToastMessage } from '../useQueryHelper';
import { getCommentList } from '../../api/checker-maker';
import { getInfiniteQueryNextParams } from '../../utils/getInfiniteQueryNextParams';

export const useGetCommentList = (
  params: ParamsCommentListInterface,
  { onSuccess }: { onSuccess?: (data: GetCommentListResponseInterface) => void } = {}
) => {
  const { openToast } = useToastMessage();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getCommentList, params],
    queryFn: async () => {
      try {
        const data = await getCommentList(params);
        onSuccess?.(data);
        return data;
      } catch (error) {
        const message = handleAxiosError(error);
        openToast({ label: message, isError: true });
      }
    },
    enabled: !!params.id,
    refetchInterval: 10_000 // 10s
  });
};

export const useGetCommentListInfinite = (params: ParamsCommentListInterface) => {
  const { openToast } = useToastMessage();
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS_ENUM.getCommentList, params],
    queryFn: async ({ pageParam }) => {
      try {
        return await getCommentList({
          ...params,
          page: pageParam
        });
      } catch (error) {
        const message = handleAxiosError(error);
        openToast({ label: message, isError: true });
        return {
          message,
          data: [],
          pagination: {
            page: 0,
            item_count: 0,
            total_items: 0
          },
          request_id: ''
        } as GetCommentListResponseInterface;
      }
    },
    getNextPageParam: getInfiniteQueryNextParams,
    initialPageParam: 1,
    enabled: !!params.id
  });
};
