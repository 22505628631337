import { randomID } from '../../../../../utils/math';
import {
  WhatsAppButtonCopyicon,
  WhatsAppButtonLinkIcon,
  WhatsAppButtonListIcon,
  WhatsAppButtonPhoneIcon,
  WhatsAppButtonReplyIcon
} from '../../../editor/icons';
import {
  WhatsAppButtonInterface,
  WhatsAppButtonTypeEnum,
  WhatsAppButtonWithIdInterface
} from './button.interface';

export const createEmptyButton = (
  type: WhatsAppButtonTypeEnum,
  id?: string
): WhatsAppButtonWithIdInterface => {
  id ||= randomID();

  switch (type) {
    case WhatsAppButtonTypeEnum.QUICK_REPLY:
      return {
        id,
        type: WhatsAppButtonTypeEnum.QUICK_REPLY,
        text: ''
      };
    case WhatsAppButtonTypeEnum.PHONE_NUMBER:
      return {
        id,
        type: WhatsAppButtonTypeEnum.PHONE_NUMBER,
        text: '',
        phone_number: ''
      };
    case WhatsAppButtonTypeEnum.URL:
      return {
        id,
        type: WhatsAppButtonTypeEnum.URL,
        text: '',
        url: ''
      };
    default:
      return {
        id,
        type: WhatsAppButtonTypeEnum.UNDEFINED
      };
  }
};

export const getButtonPreview = (button?: WhatsAppButtonInterface) => {
  switch (button?.type) {
    case WhatsAppButtonTypeEnum.QUICK_REPLY:
      return {
        icon: WhatsAppButtonReplyIcon,
        text: button.text
      };
    case WhatsAppButtonTypeEnum.PHONE_NUMBER:
      return {
        icon: WhatsAppButtonPhoneIcon,
        text: button.text
      };
    case WhatsAppButtonTypeEnum.URL:
      return {
        icon: WhatsAppButtonLinkIcon,
        text: button.text
      };
    case WhatsAppButtonTypeEnum.AUTO_FILL:
      return {
        icon: WhatsAppButtonReplyIcon,
        text: button.text
      }
    case WhatsAppButtonTypeEnum.COPY:
      return {
        icon: WhatsAppButtonCopyicon,
        text: button.text
      }
    default:
      return {
        icon: WhatsAppButtonListIcon,
        text: 'Lihat Opsi Lainnya'
      };
  }
};
