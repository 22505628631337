import { ReactNode } from 'react';

interface PreviewContainerProps {
  image: string;
  children: ReactNode;
  title: string;
}

function PreviewContainer({ image, children, title }: PreviewContainerProps) {
  return (
    <div>
      <p className="mb-[6px] mt-0 font-semibold">{title}</p>
      <div className="relative w-full overflow-hidden">
        <div className="absolute left-0 right-0 top-0">
          <img className="w-full" src={image} alt="" />
        </div>
        <div className="relative z-10 px-6 pb-3 pt-12 text-[8px]">
          <div className="rounded-md bg-white px-[10px] py-[6px]">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default PreviewContainer;
