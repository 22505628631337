import { AddChannelEnum } from '../static/add-channel.enum';

export enum ChannelKeyEnum {
  WHATSAPP = 'whatsapp',
  EMAIL = 'email',
  PUSH_NOTIFICATION = 'push_notification'
}

export interface ChannelInterface {
  key: ChannelKeyEnum;
  id: string;
  name: AddChannelEnum;
  is_enabled: boolean;
  available: boolean;
  available_for_automated: boolean;
  icon: string;
}

export interface ResponseChannelInterface {
  message: string;
  errors: string[];
  data: ChannelInterface[];
}
