import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextNode,
  $applyNodeReplacement
} from 'lexical';
import { InsertPrecodingPayload } from '../plugins/PrecodingPlugin/usePrecodingPlugin';

type SerializedPrecodingNode = Spread<
  {
    detail: any;
    text: string;
    format: number;
    style: any;
    mode: any;
    example: string;
  },
  SerializedTextNode
>;

export function convertPrecodingElement(domNode: HTMLSpanElement): DOMConversionOutput | null {
  const text = domNode.textContent;
  const isPrecoding = domNode.dataset.precoding;
  const example = domNode.dataset.example || '';
  const styles = domNode.style;
  if (text && isPrecoding && example) {
    const node = $createPrecodingNode({ text, example });
    node.setStyle(styles.cssText);
    return { node };
  }

  return null;
}

export class PrecodingNode extends TextNode {
  __example: string;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(text: string, example: string, key?: NodeKey) {
    // remove the {{}} from the text
    text = text.replace(/{{(.*)}}/, '$1');
    super(text, key);
    this.__example = example;
  }
  static getType() {
    return 'precoding';
  }
  static clone(node: PrecodingNode): PrecodingNode {
    return new PrecodingNode(node.__text, node.__example, node.__key);
  }
  static importJSON(serializedNode: SerializedPrecodingNode): PrecodingNode {
    const { text, example } = serializedNode;
    const node = $createPrecodingNode({ text, example });
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }
  exportJSON(): SerializedPrecodingNode {
    return {
      text: this.getText(),
      type: 'precoding',
      version: 1,
      style: this.getStyle(),
      format: this.getFormat(),
      mode: this.getMode(),
      detail: this.getDetail(),
      example: this.getExample()
    };
  }
  static importDOM(): DOMConversionMap<HTMLSpanElement> | null {
    return {
      span: (domNode: HTMLSpanElement) => {
        if (domNode.dataset.precoding === '' || domNode.dataset.text === '') {
          return null;
        }
        return {
          conversion: convertPrecodingElement,
          priority: 2
        };
      },
      strong: (domNode: any) => {
        if (domNode.dataset.precoding === '' || domNode.dataset.text === '') {
          return null;
        }
        return {
          conversion: convertPrecodingElement,
          priority: 2
        };
      },
      em: (domNode: any) => {
        if (domNode.dataset.precoding === '' || domNode.dataset.text === '') {
          return null;
        }
        return {
          conversion: convertPrecodingElement,
          priority: 2
        };
      }
    };
  }
  exportDOM(): DOMExportOutput {
    let element = document.createElement('span');
    if (this.hasFormat('bold')) {
      element.classList.add('PlaygroundEditorTheme__textBold');
    }
    if (this.hasFormat('italic')) {
      element.classList.add('PlaygroundEditorTheme__textItalic');
    }
    if (this.hasFormat('underline')) {
      element.classList.add('PlaygroundEditorTheme__textUnderline');
    }
    element.textContent = `{{${this.getText()}}}`;
    element.dataset.precoding = 'true';
    element.dataset.example = this.getExample();
    element.style.cssText = this.getStyle();
    return { element };
  }
  getText(): string {
    return this.__text;
  }
  getExample(): string {
    return this.__example;
  }
  createDOM(editor: EditorConfig): HTMLElement {
    const element = super.createDOM(editor);
    element.dataset.precoding = 'true';
    element.className = `precoding-node precoding-${this.getKey()} inline-block border border-solid px-0.5 rounded-md`;

    if (this.hasFormat('bold')) {
      element.classList.add('PlaygroundEditorTheme__textBold');
    }
    if (this.hasFormat('italic')) {
      element.classList.add('PlaygroundEditorTheme__textItalic');
    }
    if (this.hasFormat('underline')) {
      element.classList.add('PlaygroundEditorTheme__textUnderline');
    }
    return element;
  }
  isTextEntity(): boolean {
    return true;
  }
  canInsertTextAfter(): boolean {
    return false;
  }
  canInsertTextBefore(): boolean {
    return false;
  }
  setExample(example: string): void {
    this.getWritable().__example = example;
  }
}

export function $isPrecodingNode(node: any) {
  return node instanceof PrecodingNode;
}

export function $createPrecodingNode({ text, example }: InsertPrecodingPayload): PrecodingNode {
  return $applyNodeReplacement(new PrecodingNode(text, example).setMode('token'));
}
