import { Icon, Paragraph, Title, Token } from '@squantumengine/horizon';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { useMemo } from 'react';
import { dateFromToday } from '../../utils/date';
import EmptyState from '../../pages/broadcast/empty-state';

export interface IMetaBroadcast {
  date: string;
  contacts: string[];
  editor: string;
  recurring: string;
  history: {
    id: string;
    date: string;
    description: string;
    author: string;
  }[];
}

export interface IDetailBroadcast {
  broadcast?: IMetaBroadcast;
  anchorPrefix?: string;
  emptyMessage?: string;
}
function DetailBroadcast(props: IDetailBroadcast) {
  const {
    broadcast,
    anchorPrefix = 'content',
    emptyMessage = 'Belum ada broadcast yang dapat ditampilkan.'
  } = props;

  const broadcastData = useMemo(() => {
    if (broadcast) {
      const daysLeft = dateFromToday(get(broadcast, 'date', new Date().toJSON()));
      return {
        daysLeft,
        date: dayjs(broadcast.date).format('DD MMM YYYY, HH:mm:ss'),
        contacts: broadcast.contacts,
        editor: broadcast.editor,
        history: broadcast.history,
        recurring: broadcast.recurring
      };
    }
  }, [broadcast]);

  if (!broadcast) {
    return (
      <div className="flex flex-col gap-6">
        <div className="flex flex-col rounded-lg bg-white p-6 shadow-lg shadow-black-90/5">
          <Title level={5}>Detail broadcast</Title>
          <div className="my-4 h-[1px] w-full bg-neutral-100" />
          <EmptyState emptyText={emptyMessage} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col rounded-lg bg-white p-6 shadow-lg shadow-black-90/5">
        <Title level={5}>Detail broadcast</Title>
        <div className="my-4 h-[1px] w-full bg-neutral-100" />
        <div className={`${anchorPrefix}_broadcastDate flex flex-col gap-2`}>
          <Paragraph weight="bold">
            Tanggal broadcast{' '}
            <span className="text-sm font-bold text-red-500">{broadcastData!.daysLeft}</span>
          </Paragraph>
          <Paragraph className={`${anchorPrefix}_broadcastNextDate`}>
            {broadcastData!.date}
          </Paragraph>
          {broadcastData!.recurring && (
            <div className="flex items-center gap-1">
              <Icon name="sync-alt" size="sm" color={Token.COLORS.yellow[600]} />
              <Paragraph
                weight="bold"
                size="s"
                className={`${anchorPrefix}_broadcastReccuringDate text-yellow-600`}>
                {broadcastData!.recurring}
              </Paragraph>
            </div>
          )}
        </div>
        <div className="my-4 h-[1px] w-full bg-neutral-100" />
        <div className={`${anchorPrefix}_broadcastContacts flex flex-col gap-2`}>
          <Paragraph weight="bold">Daftar nama kontak</Paragraph>
          <ul className="m-0 pl-6">
            {broadcastData!.contacts.map((contact) => (
              <li key={contact}>{contact}</li>
            ))}
          </ul>
        </div>
        <div className="my-4 h-[1px] w-full bg-neutral-100" />
        <div className={`${anchorPrefix}_broadcastEditor flex flex-col gap-2`}>
          <Paragraph weight="bold">Editor</Paragraph>
          <Paragraph>{broadcastData!.editor}</Paragraph>
        </div>
        <div className="my-4 h-[1px] w-full bg-neutral-100" />
        <div className={`${anchorPrefix}_broadcastHistory flex flex-col gap-2`}>
          <Paragraph weight="bold">Riwayat</Paragraph>
          <div className="flex flex-col gap-2">
            {broadcastData!.history.map((history) => (
              <div
                key={history.id}
                className={`${anchorPrefix}_broadcastHistory_item${history.id}`}>
                <Paragraph
                  weight="bold"
                  size="s"
                  className={`${anchorPrefix}_broadcastHistory_item${history.id}_desc`}>
                  {get(history, 'description', '-')}
                </Paragraph>
                <Paragraph className={`${anchorPrefix}_broadcastHistory_item${history.id}_info`}>
                  Oleh: {get(history, 'author', '-')},{' '}
                  {dayjs(+history.date).format('DD MMMM YYYY, HH:mm')}
                </Paragraph>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailBroadcast;
