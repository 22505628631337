import { Button, Paragraph } from '@squantumengine/horizon';
import { Modal } from 'antd';

interface CommentBoxDeleteConfirmationProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function CommentBoxDeleteConfirmation(props: CommentBoxDeleteConfirmationProps) {
  const { open, onCancel, onConfirm } = props;

  return (
    <Modal
      centered
      open={open}
      title={<Paragraph className="text-xl font-semibold">Hapus komentar?</Paragraph>}
      onCancel={onCancel}
      width={521}
      footer={null}
      styles={{ wrapper: { zIndex: 1100 }, mask: { zIndex: 1100 } }}
    >
      <div className="flex flex-col gap-4">
        <Paragraph>Jika dihapus, komentar yang telah Anda berikan tidak akan tersimpan. </Paragraph>
        <div className="flex items-center justify-end gap-2">
          <Button variant="secondary" onClick={onCancel}>
            Batalkan
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Hapus
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CommentBoxDeleteConfirmation;
