import { useState } from 'react';

export interface UseModalInterface {
  visible: boolean;
  openModal: () => void;
  closeModal: () => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
function useModal(): UseModalInterface {
  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  return {
    visible,
    openModal,
    closeModal,
    setVisible
  };
}

export default useModal;
