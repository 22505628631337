export interface PushNotificationPreviewInterface {
  image?: string;
  header: string;
  body: string;
  button?: string;
  truncate?: boolean;
}

export enum DeviceEnum {
  IOS = 'ios',
  ANDROID = 'android'
}
