import { Dropdown } from 'antd';
import { useNavigate } from 'react-router';
import { useAppConfig, useAuth } from '../../hooks/useAuth';
import { useState } from 'react';
import { ArrowUser } from '../../assets/icons';
import UserDropdownOptions from './user-dropdown-options';
import useLogoutHook from '../../hooks/useLogoutHook';
import NotificationComponent from './notification';

function UserDropdown() {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const { userData } = useAuth();
  const { isCheckerMaker } = useAppConfig();
  const { handleLogout, isLoading } = useLogoutHook();

  return (
    <div className="flex items-center gap-4">
      {isCheckerMaker && <NotificationComponent />}
      <Dropdown
        open={open}
        onOpenChange={() => setOpen(!open)}
        trigger={['click']}
        dropdownRender={() => (
          <UserDropdownOptions
            userData={userData}
            onClickPrivacyPolicy={() => {
              navigate('/privacy-policy');
              setOpen(false);
            }}
            onClickTermAndCondition={() => {
              navigate('/term-and-conditions');
              setOpen(false);
            }}
            handleLogout={handleLogout}
            isLoading={isLoading}
          />
        )}>
        <div className="flex cursor-pointer items-center gap-4">
          <img
            className="rounded-full border-2 border-solid border-border-gray object-cover"
            width={52}
            height={52}
            src={userData.profile_photo}
            alt="profile_pic"
          />
          <div>
            <div className="flex text-base">
              <span className="flex-auto overflow-hidden text-ellipsis whitespace-nowrap text-left font-semibold capitalize">
                {`${userData.name}`}
              </span>
            </div>
            <p className="m-0 text-left text-sm capitalize">{userData.role}</p>
          </div>
          <ArrowUser />
        </div>
      </Dropdown>
    </div>
  );
}

export default UserDropdown;
