import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ChooseWhatsappSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      d="M34.6241 14.5478C33.2426 13.1524 31.5971 12.046 29.7835 11.2931C27.97 10.5403 26.0247 10.1561 24.0611 10.1629C15.8338 10.1629 9.12836 16.8684 9.12836 25.0957C9.12836 27.7327 9.8215 30.2943 11.1174 32.5545L9.00781 40.2997L16.9187 38.2202C19.1036 39.4106 21.5598 40.0435 24.0611 40.0435C32.2885 40.0435 38.9939 33.3381 38.9939 25.1108C38.9939 21.1176 37.4418 17.3656 34.6241 14.5478ZM24.0611 37.512C21.831 37.512 19.6461 36.9093 17.7324 35.7792L17.2803 35.5079L12.579 36.7435L13.8297 32.1628L13.5283 31.6956C12.2893 29.7171 11.6314 27.4301 11.6297 25.0957C11.6297 18.2546 17.205 12.6794 24.0461 12.6794C27.3611 12.6794 30.4802 13.9752 32.8158 16.3259C33.9723 17.4771 34.8888 18.8463 35.5122 20.3543C36.1355 21.8623 36.4534 23.479 36.4473 25.1108C36.4775 31.9518 30.9022 37.512 24.0611 37.512ZM30.872 28.2299C30.4953 28.0491 28.657 27.145 28.3255 27.0094C27.9789 26.8888 27.7378 26.8286 27.4816 27.1902C27.2255 27.5669 26.5173 28.4107 26.3063 28.6518C26.0953 28.908 25.8693 28.9381 25.4926 28.7422C25.1159 28.5614 23.9104 28.1546 22.494 26.8888C21.3789 25.8943 20.6406 24.6738 20.4146 24.2971C20.2036 23.9204 20.3844 23.7245 20.5803 23.5286C20.7461 23.3628 20.957 23.0916 21.1379 22.8806C21.3187 22.6697 21.394 22.5039 21.5146 22.2628C21.6351 22.0067 21.5748 21.7957 21.4844 21.6149C21.394 21.4341 20.6406 19.5957 20.3392 18.8423C20.0379 18.119 19.7214 18.2094 19.4954 18.1944H18.7721C18.516 18.1944 18.1242 18.2848 17.7776 18.6615C17.4461 19.0382 16.4817 19.9423 16.4817 21.7806C16.4817 23.619 17.8228 25.3971 18.0036 25.6382C18.1845 25.8943 20.6406 29.6614 24.3776 31.2737C25.2666 31.6655 25.9597 31.8915 26.5022 32.0573C27.3912 32.3436 28.2049 32.2984 28.8529 32.208C29.5761 32.1025 31.0679 31.3039 31.3693 30.4299C31.6857 29.5559 31.6857 28.8176 31.5802 28.6518C31.4748 28.4861 31.2487 28.4107 30.872 28.2299Z"
      fill="currentColor"
    />
  </svg>
);

const ChooseWhatsappIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ChooseWhatsappSVG} {...props} />
);

export default ChooseWhatsappIcon;
