import { useCheckerContext } from '../checker-context';
import { CommentAnchorType, CommentInterface } from '../checker.interface';
import CommentBoxComponent from './comment-box-component';
import useCommentBox from './useCommentBox';

interface CommentBoxBaseProps {
  anchor: CommentAnchorType;
  anchorPrefix: string;
  isDynamic?: boolean;
  parentRef: HTMLDivElement | null;
  cleanedAnchor?: () => void;
}

interface CommentBoxCreateProps extends CommentBoxBaseProps {
  comment?: never;
}

interface CommentBoxEditProps extends CommentBoxBaseProps {
  comment: CommentInterface;
}

export type CommentBoxProps = CommentBoxEditProps | CommentBoxCreateProps;

const CommentBox = (props: Readonly<CommentBoxProps>) => {
  const { anchor, comment, cleanedAnchor } = props;
  const { addComment, removeComment, updateComment, setSelectedCommentAnchor, resolveComment } =
    useCheckerContext();
  const { rect, element } = useCommentBox(props);
  return (
    <CommentBoxComponent
      rect={rect}
      anchor={anchor}
      element={element}
      comment={comment}
      addComment={addComment}
      removeComment={removeComment}
      updateComment={updateComment}
      onOpenChange={setSelectedCommentAnchor}
      cleanedAnchor={cleanedAnchor}
      resolveComment={resolveComment}
    />
  );
};

export default CommentBox;
