import axiosInstance from '../config/axios/axiosWithToken';
import {
  ChannelDetailInterface,
  CreateWhatsAppChannelPayloadInterface,
  GetPresignedURLPayloadInterface,
  GetPresignedURLResponseInterface,
  ResponseAddChannelInterface,
  ResponseSenderInterface
} from '../shared/automated-campaign.interface';
import {
  WhatsappConfigResponseInterface,
  WhatsappConfigurationPayloadInterface
} from '../shared/whatsapp-configuration.interface';

export const getWhatsAppChannel: (payload: any) => Promise<any> = async (payload) => {
  const response = await axiosInstance.get(`whatsapp/channel`, {
    params: payload
  });
  return response.data;
};

export const saveWhatsAppConfig: (
  payload: WhatsappConfigurationPayloadInterface
) => Promise<WhatsappConfigResponseInterface> = async (payload) => {
  const response = await axiosInstance.post(`integrations/whatsapp`, payload);
  return response.data;
};

export const getWhatsAppConfig: () => Promise<WhatsappConfigResponseInterface> = async () => {
  const response = await axiosInstance.get(`integrations/whatsapp`);
  return response.data;
};

export const getWhatsAppSenderList: () => Promise<ResponseSenderInterface> = async () => {
  const response = await axiosInstance.get(`whatsapp/sender`);
  return response.data;
};

export const getWhatsAppChannelDetail: (id: string) => Promise<ChannelDetailInterface> = async (
  id
) => {
  const response = await axiosInstance.get(`whatsapp/channel/${id}`);
  return response.data;
};

export const createWhatsAppChannel: (
  payload: CreateWhatsAppChannelPayloadInterface
) => Promise<ResponseAddChannelInterface> = async (payload) => {
  const response = await axiosInstance.post(`whatsapp/channel`, payload);
  return response.data;
};

export const editWhatsAppChannel: (
  id: string,
  payload: CreateWhatsAppChannelPayloadInterface
) => Promise<ResponseAddChannelInterface> = async (id, payload) => {
  const response = await axiosInstance.patch(`whatsapp/channel/${id}`, payload);
  return response.data;
};

export const getPresignedURL: (
  payload: GetPresignedURLPayloadInterface
) => Promise<GetPresignedURLResponseInterface> = async (payload) => {
  const response = await axiosInstance.post(`whatsapp/signed_url`, payload);
  return response.data.data;
};
