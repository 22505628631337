import axiosInstance from '../config/axios/axiosWithToken';
import {
  CategoryListInterface,
  CategoryListQuery,
  DeleteCategoryInterface,
  PushNotifCategoryInterface,
  PushNotifCategoryListInterface
} from '../shared/category.interface';

export const getCategoryList: (query: CategoryListQuery) => Promise<CategoryListInterface> = async (
  query
) => {
  const { page, limit = 100, q = '' } = query;
  const response = await axiosInstance.get(`email/category?page=${page}&limit=${limit}&q=${q}`);
  return response.data;
};

export const addNewCategory: (payload: { name: string }) => Promise<CategoryListInterface> = async (
  payload
) => {
  const response = await axiosInstance.post(`email/category`, payload);
  return response.data;
};

export const deleteCategory: (id: string) => Promise<DeleteCategoryInterface> = async (id) => {
  const response = await axiosInstance.delete(`email/category/${id}`);
  return response.data;
};

export const getPushNotifCategoryList: (
  query: CategoryListQuery
) => Promise<PushNotifCategoryListInterface> = async (query) => {
  const { page, limit = 100, q = '' } = query;
  const response = await axiosInstance.get(
    `push_notification/category?page=${page}&limit=${limit}&q=${q}`
  );
  return response.data;
};

export const addNewPushNotifCategory: (payload: {
  name: string;
  sound_id?: string;
}) => Promise<PushNotifCategoryInterface> = async (payload) => {
  const response = await axiosInstance.post(`push_notification/category`, payload);
  return response.data;
};

export const editPushNotifCategory: (payload: {
  id: string;
  sound_id: string;
}) => Promise<PushNotifCategoryInterface> = async (payload) => {
  const response = await axiosInstance.patch(`push_notification/category/${payload.id}`, {
    sound_id: payload.sound_id
  });
  return response.data;
};

export const deletePushNotifCategory: (id: string) => Promise<DeleteCategoryInterface> = async (
  id
) => {
  const response = await axiosInstance.delete(`push_notification/category/${id}`);
  return response.data;
};
