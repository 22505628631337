export enum WhatsAppButtonTypeEnum {
  QUICK_REPLY = 'QUICK_REPLY',
  PHONE_NUMBER = 'PHONE_NUMBER',
  URL = 'URL',
  AUTO_FILL = 'AUTO_FILL',
  COPY = 'COPY_CODE',
  UNDEFINED = 'UNDEFINED'
}

export interface WhatsAppAutoFillCodeButtonInterface {
  type: WhatsAppButtonTypeEnum.AUTO_FILL;
  text: string;
}

export interface WhatsAppCopyCodeButtonInterface {
  type: WhatsAppButtonTypeEnum.COPY;
  text: string;
}

export interface WhatsAppQuickReplyButtonInterface {
  type: WhatsAppButtonTypeEnum.QUICK_REPLY;
  text: string;
}

export interface WhatsAppPhoneNumberButtonInterface {
  type: WhatsAppButtonTypeEnum.PHONE_NUMBER;
  text: string;
  phone_number: string;
}

export interface WhatsAppURLButtonInterface {
  type: WhatsAppButtonTypeEnum.URL;
  text: string;
  url: string;
}

export interface WhatsAppUndefinedButtonInterface {
  type: WhatsAppButtonTypeEnum.UNDEFINED;
}

export type WhatsAppButtonInterface =
  | WhatsAppQuickReplyButtonInterface
  | WhatsAppPhoneNumberButtonInterface
  | WhatsAppURLButtonInterface
  | WhatsAppAutoFillCodeButtonInterface
  | WhatsAppCopyCodeButtonInterface;

export type WhatsAppButtonWithUndefinedInterface =
  | WhatsAppButtonInterface
  | WhatsAppUndefinedButtonInterface;

export type WhatsAppButtonWithIdInterface = WhatsAppButtonWithUndefinedInterface & { id: string };

export enum WhatsAppButtonGroupEnum {
  QUICK_REPLY = 'QUICK_REPLY',
  CTA = 'CTA'
}

export enum WhatsAppURLButtonTypeEnum {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC'
}
