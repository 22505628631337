import { useNavigate } from 'react-router';
import { useToastMessage } from '../../../hooks/useQueryHelper';
import { useWhatsappEditorContext } from '../../../context/whatsapp-editor-context';
import useModal from '../../../hooks/useModal';
import useCampaignParams from '../../../hooks/useCampaignParams';

export const useHeaderEditHelper = () => {
  const navigate = useNavigate();
  const { toastMessage } = useToastMessage();
  const { channelId, channelType, campaignType } = useCampaignParams();
  const { visible, closeModal, openModal } = useModal();
  const { setState, state, setReadOnly } = useWhatsappEditorContext();
  const handleTestTemplate = () => {
    navigate(`/campaign/${campaignType}/${channelType}/${channelId}/test`);
  };

  return {
    visible,
    state,
    channelId,
    closeModal,
    openModal,
    setState,
    handleTestTemplate,
    toastMessage,
    setReadOnly
  };
};
