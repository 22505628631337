import axiosInstance from '../config/axios/axiosWithToken';
import {
  CheckerMakerReviewStatusEnum,
  EditCommentResponseInterface,
  GetCommentListResponseInterface,
  GetReviewActivityResponseInterface,
  GetTemplateDetailResponse,
  GetTemplateReviewListResponseInterface,
  ParamsCommentListInterface,
  PostCheckerMakerInterface,
  PostCheckerMakerResponseInterface
} from '../shared/checker-maker.interface';
import { ChannelKeyEnum } from '../shared/master-api.interface';

export const getTemplateReviewList: (
  query: string,
  status: CheckerMakerReviewStatusEnum,
  page: number,
  limit: number
) => Promise<GetTemplateReviewListResponseInterface> = async (query, status, page, limit) => {
  const response = await axiosInstance.get(
    `review?q=${query}&page=${page}&limit=${limit}&status=${status}`
  );
  return response.data;
};

export const getTemplateReviewCount: (
  status: CheckerMakerReviewStatusEnum
) => Promise<number> = async (status) => {
  const response = await axiosInstance.get(`review/counter?status=${status}`);
  return response.data.data.count;
};

export const setCampaignApprovalConfig = async (approval: boolean) => {
  await axiosInstance.patch('settings/campaign_approval', { campaign_approval: approval });
};

export const setCheckerReviewAction = async (
  channel: ChannelKeyEnum,
  reviewId: string,
  action: 'approve' | 'decline'
) => {
  await axiosInstance.post(`${channel}/review/${reviewId}/${action}`);
};

export const sendChannelTemplateToChecker = async (
  channel: ChannelKeyEnum,
  channelId: string,
  broadcastId: string
) => {
  await axiosInstance.post(`${channel}/review`, {
    channel_id: channelId,
    broadcast_id: broadcastId
  });
};

export async function getCheckerMakerTemplateDetail<T = {}>(
  channel: ChannelKeyEnum,
  id: string
): Promise<GetTemplateDetailResponse<T>> {
  const response = await axiosInstance.get(`${channel}/review/${id}`);
  return response.data;
}

export const postComment: (
  payload: PostCheckerMakerInterface
) => Promise<PostCheckerMakerResponseInterface> = async (payload) => {
  const response = await axiosInstance.post(`review/${payload.id}/comment`, payload);
  return response.data;
};

export const editComment: (
  payload: PostCheckerMakerInterface
) => Promise<EditCommentResponseInterface> = async (payload) => {
  const response = await axiosInstance.patch(`comment/${payload.id}`, payload);
  return response.data;
};

export const deleteComment: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.delete(`comment/${id}`);
  return response.data;
};

export const resolveComment: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.patch(`comment/${id}/resolve`);
  return response.data;
};

export const getCommentList: (
  params: ParamsCommentListInterface
) => Promise<GetCommentListResponseInterface> = async (params) => {
  const response = await axiosInstance.get(`review/${params.id}/comment`, {
    params
  });
  return response.data;
};

export const getActivityList: (
  channel: ChannelKeyEnum,
  reviewId?: string,
  page?: number,
  limit?: number
) => Promise<GetReviewActivityResponseInterface> = async (
  channel,
  reviewId = '',
  page = 1,
  limit = 100
) => {
  const response = await axiosInstance.get(
    `${channel}/review/${reviewId}/activity?page=${page}&limit=${limit}`
  );
  return response.data;
};
