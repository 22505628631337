import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editWhatsAppChannel } from '../../api/whatsapp';
import {
  CreateWhatsAppChannelPayloadInterface,
  ResponseAddChannelInterface
} from '../../shared/automated-campaign.interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';

export const useEditWhatsAppContent = ({
  channelId,
  onSuccess,
  onError
}: {
  channelId: string;
  onSuccess?: (data: ResponseAddChannelInterface) => void;
  onError?: (error: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.editWhatsappContent, channelId],
    mutationFn: (payload: CreateWhatsAppChannelPayloadInterface) => {
      return editWhatsAppChannel(channelId, payload);
    },
    onSuccess: (data: ResponseAddChannelInterface) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.channelListAutomated] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS_ENUM.whatsappChannelDetail, channelId]
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.getWhatsappDetail] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.getChannelList] });

      onSuccess?.(data);
    },
    onError: (error: AxiosError) => {
      onError?.(error);
    }
  });
};
