import { useQuery } from '@tanstack/react-query';
import { getSettings } from '../../api/settings';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { GetSettingsInterface } from '../../shared/settings.interface';

export const useGetSettings = ({
  refetchInterval,
  onSuccess
}: {
  refetchInterval?: number;
  onSuccess: (data: GetSettingsInterface) => void;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getSettings],
    queryFn: async () => {
      const data = await getSettings();
      onSuccess(data);
      return data;
    },
    refetchOnWindowFocus: true,
    refetchInterval
  });
};
