import { Paragraph } from '@squantumengine/horizon';
import { useMemo, useState } from 'react';

export default function ShowMoreText({
  text,
  className,
  showMoreText,
  showLessText
}: Readonly<{ text: string; className?: string; showMoreText: string; showLessText: string }>) {
  const [showMore, setShowMore] = useState(false);
  const isTooLong = useMemo(() => text.length > 250, [text]);

  return (
    <div className={className}>
      <Paragraph>
        {showMore ? text + ' ' : text.slice(0, 251) + ' '}
        {isTooLong && (
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowMore((prev) => !prev);
            }}
            role="button"
            className="cursor-pointer font-semibold underline underline-offset-2"
          >
            {showMore ? showLessText : showMoreText}
          </span>
        )}
      </Paragraph>
    </div>
  );
}
