import { Modal } from 'antd';
import CloseIcon from '../../../../assets/icons/close-icon';
import Button from '../../../buttons';
import { Dayjs } from 'dayjs';

function UneditableTemplateModal({
  open,
  editableDate,
  onClose
}: Readonly<{
  open: boolean;
  editableDate: Dayjs;
  onClose: () => void;
}>) {
  return (
    <Modal
      title={<h2 className="m-0 p-0 text-2xl font-semibold">Tidak dapat mengedit konten</h2>}
      centered
      open={open}
      onCancel={onClose}
      maskClosable={false}
      width={521}
      closeIcon={<CloseIcon />}
      footer={null}
      forceRender
    >
      <div className="flex flex-col gap-4">
        <p className="m-0 text-base">
          Anda baru dapat mengedit konten ini pada {editableDate.format('DD MMMM YYYY')} pukul{' '}
          {editableDate.format('hh:mm')}. Setelah itu, konten Anda akan dikirim dan ditinjau ulang
          oleh Meta.
        </p>
        <Button type="primary" onClick={onClose}>
          Mengerti
        </Button>
      </div>
    </Modal>
  );
}

export default UneditableTemplateModal;
