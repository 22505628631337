import { useMemo } from 'react';
import { UseInfiniteQueryResult } from '@tanstack/react-query';

export interface InfiniteScrollDropdownData<T> {
  data: T[];
  handleScroll: (e: React.UIEvent<HTMLElement, UIEvent>) => void;
  isLoading: boolean;
}

function useInfiniteScrollDropdown<T>(
  queryData: UseInfiniteQueryResult
): InfiniteScrollDropdownData<T> {
  const { fetchNextPage, isLoading: isQueryLoading, isFetching } = queryData;

  const fetchNextData = () => {
    if (isQueryLoading || isFetching) return;
    fetchNextPage();
  };

  const handleScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const THRESHOLD = 50;
    const { scrollHeight, scrollTop, offsetHeight } = e.currentTarget;
    const isNearBottom = scrollHeight - offsetHeight - scrollTop < THRESHOLD;
    if (isNearBottom) fetchNextData();
  };

  const isLoading = useMemo(() => isQueryLoading || isFetching, [isQueryLoading, isFetching]);

  const data: T[] = useMemo(
    // @ts-ignore
    () => queryData.data?.pages.flatMap((page: any) => page.data) || [],
    [queryData.data]
  );

  return { data, handleScroll, isLoading };
}

export default useInfiniteScrollDropdown;
