import { ChannelKeyEnum } from '../../shared/master-api.interface';
import EmailDetail from './email-page';
import NotificationDetail from './notification-page';
import WhatsappDetail from './whatsapp-page';
import { CampaignDetailProvider } from '../campaign-detail/context/campaign-detail-context';
import { CheckerProvider } from '../../components/checker/checker-context';
import CampaignDetailsDrawer from './campaign-details-drawer';
import useCampaignParams from '../../hooks/useCampaignParams';
import { CampaignTypeEnum } from '../../shared/automated-campaign.interface';
import useIsScopeAuthorized from '../../components/role-wrapper/useIsScopeAuthorized';
import { ALL_CHANNEL_SCOPES, AuthActionScopeEnum } from '../../shared/user.interface';

import { Email, Whatsapp, PushNotification, ChannelDetail } from '../../pages';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import TaggedFlowWrapper from './tagged-flow-wrapper';

export enum ChannelOperationEnum {
  READ = 'read',
  WRITE = 'write',
  CREATE = 'create'
}

const channelPageConfig = {
  [ChannelKeyEnum.EMAIL]: {
    scope: {
      [ChannelOperationEnum.READ]: AuthActionScopeEnum.CHANNEL_EMAIL_READ,
      [ChannelOperationEnum.WRITE]: AuthActionScopeEnum.CHANNEL_EMAIL_WRITE,
      [ChannelOperationEnum.CREATE]: AuthActionScopeEnum.CHANNEL_EMAIL_CREATE
    },
    component: {
      [ChannelOperationEnum.READ]: {
        [CampaignTypeEnum.MANUAL]: <EmailDetail />,
        [CampaignTypeEnum.AUTOMATED]: <ChannelDetail />
      },
      [ChannelOperationEnum.CREATE]: {
        [CampaignTypeEnum.MANUAL]: <Email />,
        [CampaignTypeEnum.AUTOMATED]: <Email />
      },
      [ChannelOperationEnum.WRITE]: <Email />
    }
  },
  [ChannelKeyEnum.WHATSAPP]: {
    scope: {
      [ChannelOperationEnum.READ]: AuthActionScopeEnum.CHANNEL_WA_READ,
      [ChannelOperationEnum.WRITE]: AuthActionScopeEnum.CHANNEL_WA_WRITE,
      [ChannelOperationEnum.CREATE]: AuthActionScopeEnum.CHANNEL_WA_CREATE
    },
    component: {
      [ChannelOperationEnum.READ]: {
        [CampaignTypeEnum.MANUAL]: <WhatsappDetail />,
        [CampaignTypeEnum.AUTOMATED]: <Whatsapp />
      },
      [ChannelOperationEnum.CREATE]: {
        [CampaignTypeEnum.MANUAL]: <Whatsapp />,
        [CampaignTypeEnum.AUTOMATED]: (
          <TaggedFlowWrapper>
            <Whatsapp />
          </TaggedFlowWrapper>
        )
      },
      [ChannelOperationEnum.WRITE]: <Whatsapp />
    }
  },
  [ChannelKeyEnum.PUSH_NOTIFICATION]: {
    scope: {
      [ChannelOperationEnum.READ]: AuthActionScopeEnum.CHANNEL_PN_READ,
      [ChannelOperationEnum.WRITE]: AuthActionScopeEnum.CHANNEL_PN_WRITE,
      [ChannelOperationEnum.CREATE]: AuthActionScopeEnum.CHANNEL_PN_CREATE
    },
    component: {
      [ChannelOperationEnum.READ]: {
        [CampaignTypeEnum.MANUAL]: <NotificationDetail />,
        [CampaignTypeEnum.AUTOMATED]: <PushNotification />
      },
      [ChannelOperationEnum.CREATE]: {
        [CampaignTypeEnum.MANUAL]: <PushNotification />,
        [CampaignTypeEnum.AUTOMATED]: <PushNotification />
      },
      [ChannelOperationEnum.WRITE]: <PushNotification />
    }
  }
};

interface CampaignDetailsPageInterface {
  operation: ChannelOperationEnum;
}

function CampaignDetailsPage({ operation }: Readonly<CampaignDetailsPageInterface>) {
  const navigate = useNavigate();
  const { channelType, campaignType } = useCampaignParams();
  const { scope, component } = channelPageConfig[channelType as ChannelKeyEnum] || {};
  const isAuthorized = useIsScopeAuthorized(
    scope ? scope[operation] : ALL_CHANNEL_SCOPES.CHANNEL_READ
  );
  const isParamsValid = !!scope && !!isAuthorized;

  useEffect(() => {
    if (!isParamsValid) navigate('/404');
  }, [isParamsValid, navigate]);

  if (!isParamsValid) return null;

  const element =
    operation === ChannelOperationEnum.WRITE
      ? component[operation]
      : component[operation][campaignType as CampaignTypeEnum];

  if (campaignType === CampaignTypeEnum.MANUAL)
    return (
      <CampaignDetailProvider>
        <CheckerProvider channelType={channelType as ChannelKeyEnum} initialDisabled={true}>
          {element}
          <CampaignDetailsDrawer />
        </CheckerProvider>
      </CampaignDetailProvider>
    );

  return element;
}

export default CampaignDetailsPage;
