import { Video } from '../../editor/icons';
import { HEADER_TYPE_ENUM } from '../../helper/header.enum';

interface ThumbnailInterface {
  url: string;
  type: string;
}
function Thumbnail({ url, type }: Readonly<ThumbnailInterface>) {
  const renderTypeFile = (type: string) => {
    switch (type) {
      case HEADER_TYPE_ENUM.image:
      case HEADER_TYPE_ENUM.document:
        return <img src={url} alt="Thumbnail" className="h-40 w-full max-w-full object-cover" />;
      case HEADER_TYPE_ENUM.video:
        return (
          <div
            className="h-40 w-full max-w-full overflow-hidden bg-gray-400"
            data-testid="video-preview"
          >
            <img
              src={url}
              alt="Thumbnail"
              className="absolute h-40 w-full max-w-full rounded-t-lg object-cover opacity-70"
            />
            <div className="relative grid h-40 w-full place-content-center">
              <Video width={56} color="#FFFFFF" className="relative z-10" />
            </div>
          </div>
        );
    }
  };
  return <div>{renderTypeFile(type)}</div>;
}

export default Thumbnail;
