function InstructionDesc({ no, desc }: { no: string; desc: string }) {
  const text = `${no} ${desc}`;
  return (
    <div className="flex gap-1 text-base font-semibold">
      <span>{text}</span>
    </div>
  );
}

export default InstructionDesc;
