import { LexicalEditor, createEditor } from 'lexical';
import { useMemo } from 'react';
import { createWhatsAppEditorConfig } from '../components/whatsapp-editor/nodes/WhatsAppNodes';

export const emptyState = `{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}`;

function useCreateEmptyEditor(name: string): [LexicalEditor, () => void] {
  const editor = useMemo(() => {
    const editor = createEditor(createWhatsAppEditorConfig(name));
    const newState = editor.parseEditorState(emptyState);
    editor.setEditorState(newState);
    return editor;
  }, [name]);

  const resetEditor = () => {
    const newState = editor.parseEditorState(emptyState);
    editor.setEditorState(newState);
  };

  return [editor, resetEditor];
}

export default useCreateEmptyEditor;
