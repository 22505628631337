import axios, { AxiosError } from 'axios';
import {
  datadogRequestInterceptor,
  onFulfilledDatadogResponseInterceptor,
  onRejectedDatadogResponseInterceptor
} from '../datadog/axiosInterceptor';
import { local } from '../../utils/storage';
import { REDIRECT_TO_403_KEY } from '../../pages/403';
import { jwtDecode } from 'jwt-decode';
import { decryptToken } from '../../utils/oAuth';
import { HTTPErrorCode } from '../../shared/errorCode.enum';

const localData = JSON.parse(local.getItem('token') ?? '{}');

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'id',
    Authorization: `Bearer ${localData.access_token}`
  }
});

axiosInstance.interceptors.response.use(
  (response: any) => {
    onFulfilledDatadogResponseInterceptor(response);
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === HTTPErrorCode.UNAUTHORIZED || error.response === undefined) {
      local.removeItem('user');
      window.location.href = '/login';
    }

    if (error.response?.status === HTTPErrorCode.FORBIDDEN) {
      try {
        sessionStorage.setItem(REDIRECT_TO_403_KEY, 'true');
      } catch {
        throw error;
      }

      window.location.href = '/403';
    }

    onRejectedDatadogResponseInterceptor(error);

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(datadogRequestInterceptor);

export const setAuthorizationHeader = (token: string) => {
  try {
    jwtDecode(token);
    axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  } catch {
    // if fails, then the token needs to be decrypted
    const decryptedToken = decryptToken(token, process.env.REACT_APP_ENCRYPT_TOKEN);
    axiosInstance.defaults.headers.Authorization = `Bearer ${decryptedToken}`;
  }
};

export default axiosInstance;
