import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { useToastMessage } from '../useQueryHelper';
import { handleAxiosError } from '../../api/error-handler/error-handler';
import { deleteComment } from '../../api/checker-maker';

export const useDeleteComment = () => {
  const { openToast } = useToastMessage();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.deleteComment],
    mutationFn: deleteComment,
    onError: (error) => {
      const message = handleAxiosError(error);
      openToast({ label: message, isError: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS_ENUM.getCommentList] });
      openToast({ label: 'Sukses menghapus komentar' });
    }
  });
};
