/**
 * This route will be removed when CC has integrated WA channel lists without campaign layer
 * Hence, a lot of hardcoding are done and the logic is not really optimized
 */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { CampaignTagEnum } from '../../shared/campaign.interface';
import RedirectTraffic from '.';

interface RedirectTrafficInterface {
  from: string;
  to: string;
  preserveParams?: boolean;
}

function RedirectTrafficFromCC({ from, to, preserveParams }: Readonly<RedirectTrafficInterface>) {
  const [URLSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectUrl = URLSearchParams.get('redirectUrl') || '';
  const organizationId = URLSearchParams.get('orId') || '';
  const source = (URLSearchParams.get('sc') as CampaignTagEnum) || '';

  const isCCFlow = Boolean(redirectUrl && organizationId && source);

  useEffect(() => {
    const query = location.search;
    if (isCCFlow) navigate('/campaign/automated/whatsapp' + query);
  }, [isCCFlow, location.search, navigate]);

  if (!isCCFlow) return <RedirectTraffic from={from} to={to} preserveParams={preserveParams} />;

  return null;
}

export default RedirectTrafficFromCC;
