import { useQuery } from '@tanstack/react-query';
import { fetchChannelList } from '../../api';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { ChannelListParamsInterface } from '../../shared/automated-campaign.interface';

export const useGetChannelList = (id: string, params?: ChannelListParamsInterface) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.channelListAutomated, id, params?.status],
    queryFn: () => fetchChannelList(id, params),
    enabled: !!id,
    select: ({ data }) => {
      return data;
    }
  });
};
