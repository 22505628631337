import { useEffect } from 'react';
import { usePushNotificationEditorContext } from '../../context/push-notification-editor-context';
import { useGetPushNotificationTemplate } from '../../hooks/automated-campaign-query/useGetPushNotificationTemplate';
import { initializeNotificationContext } from '../../utils/pushNotification';

function useInitializeTemplate(channelId: string = '') {
  const { data } = useGetPushNotificationTemplate(channelId);
  const context = usePushNotificationEditorContext();

  useEffect(() => {
    if (data) {
      initializeNotificationContext(context, data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
}

export default useInitializeTemplate;
