import { Typography, Icon, Token, Button } from '@squantumengine/horizon';
import { Popover } from 'antd';
import { useState } from 'react';
import { parseUnixDateInMs } from '../../../utils/date';

interface CommentCardInterface {
  open?: boolean;
  comment?: string;
  createdAt?: string;
  creator?: string;
  highlight?: string;
  onDelete?: () => void;
  onResolved?: () => void;
}

const CommentCard = ({
  open,
  comment,
  createdAt,
  creator,
  highlight,
  onDelete,
  onResolved
}: CommentCardInterface) => {
  const { Paragraph } = Typography;
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div
      data-testid="comment-card"
      className={`${
        open ? 'border-blue-100 bg-blue-50' : 'border-neutral-200 bg-neutral-50'
      } flex flex-col items-start gap-4 self-stretch rounded-sm border border-solid p-3`}
    >
      <div className="flex w-full flex-col gap-0.5">
        <div className="flex w-full justify-between gap-2">
          <Paragraph className="m-0 font-bold text-blue-500">{creator}</Paragraph>

          {open && (
            <div className="ml-auto flex justify-end gap-2">
              <Button
                data-testid="resolve-comment-button"
                onClick={onResolved}
                variant="text"
                className="h-6 w-6 rounded-none px-0"
              >
                <Icon name="check" size="md" color={Token.COLORS.blue[500]} />
              </Button>
              <Popover
                open={openMenu}
                trigger="click"
                placement="bottomRight"
                onOpenChange={setOpenMenu}
                content={
                  <div className="flex flex-col">
                    <Button
                      variant="text"
                      onClick={onDelete}
                      className="min-w-40 justify-start px-2 font-normal text-black-rich hover:text-blue-600"
                    >
                      Hapus komentar
                    </Button>
                  </div>
                }
              >
                <Button
                  data-testid="open-comment-menu-button"
                  variant="text"
                  className="h-6 w-6 rounded-none px-0"
                >
                  <Icon name="kebab-vertical" />
                </Button>
              </Popover>
            </div>
          )}
        </div>
        <Paragraph className="m-0 text-xs text-neutral-600">
          {parseUnixDateInMs(Number(createdAt)).format('DD MMM YYYY - HH:mm')}
        </Paragraph>
      </div>
      <div
        className="max-w-full text-neutral-500"
        dangerouslySetInnerHTML={{ __html: highlight?.replaceAll(/checker/g, '') ?? '' }}
      />
      <Paragraph>{comment}</Paragraph>
      {!open && (
        <Paragraph size="s" className="gap-0.5s flex items-center text-neutral-950">
          <Icon name="check" color={Token.COLORS.neutral[600]} size="sm" />
          <span>Selesai</span>
        </Paragraph>
      )}
    </div>
  );
};

export default CommentCard;
