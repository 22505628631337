import { Link } from 'react-router-dom';
import { Button, Icon, Label } from '@squantumengine/horizon';
import { generateChannelStatusValue } from '../../utils/automatedCampaign';

interface BaseCampaignContentHeaderProps {
  headerTitle: string;
  status?: string;
  children?: React.ReactNode;
  headerChild?: React.ReactNode;
}

interface CampaignContentHeaderURLProps extends BaseCampaignContentHeaderProps {
  backUrl: string;
}

interface CampaignContentHeaderOnClickProps extends BaseCampaignContentHeaderProps {
  onClickBack: () => void;
}

type CampaignContentHeaderProps = CampaignContentHeaderOnClickProps | CampaignContentHeaderURLProps;

const CampaignContentHeader = (props: Readonly<CampaignContentHeaderProps>) => {
  const { headerTitle, status, children, headerChild } = props;

  const onClickBack = (props as CampaignContentHeaderOnClickProps).onClickBack;
  const statusValue = generateChannelStatusValue(status ?? '');

  return (
    <div className="flex h-fit w-full items-center justify-between">
      <div className="flex gap-6">
        {onClickBack ? (
          <Button className="p-1" size="sm" variant="text" onClick={onClickBack}>
            <Icon name="arrow-left" />
          </Button>
        ) : (
          <Link
            to={`/campaign${(props as CampaignContentHeaderURLProps).backUrl}`}
            className="flex h-8 w-8 items-center justify-center"
          >
            <Button className="p-1" size="sm" variant="text">
              <Icon name="arrow-left" />
            </Button>
          </Link>
        )}
        <div className="flex flex-wrap items-center gap-2">
          <h3 className="m-0 text-2xl font-semibold">{headerTitle}</h3>
          {status && <Label border type={statusValue.color as any} label={statusValue.label} />}
          {headerChild}
        </div>
      </div>
      <div className="flex items-center justify-end gap-2">{children}</div>
    </div>
  );
};
export default CampaignContentHeader;
