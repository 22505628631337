import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const NotificationBellSVG = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.0625 14.394C37.375 14.394 37.625 14.3315 37.8125 14.2065L41.3125 12.2065C41.75 11.9565 42.0625 11.4565 42.0625 10.9565C42.0625 10.0815 41.375 9.45654 40.5625 9.45654C40.25 9.45654 40 9.51904 39.8125 9.64404L36.3125 11.644C35.875 11.894 35.5625 12.394 35.5625 12.9565C35.5625 13.769 36.25 14.394 37.0625 14.394ZM11.5625 20.144C11.5625 19.3315 10.875 18.644 10.0625 18.644H6.0625C5.1875 18.644 4.5625 19.3315 4.5625 20.144C4.5625 21.019 5.1875 21.644 6.0625 21.644H10.0625C10.875 21.644 11.5625 21.019 11.5625 20.144ZM7.75 12.2065L11.25 14.2065C11.4375 14.3315 11.6875 14.4565 12 14.4565C12.8125 14.4565 13.5 13.769 13.5 12.9565C13.5 12.394 13.1875 11.894 12.75 11.644L9.25 9.64404C9.0625 9.51904 8.75 9.39404 8.5 9.39404C7.6875 9.39404 7 10.0815 7 10.894C7 11.4565 7.3125 11.9565 7.75 12.2065ZM43.0625 18.644H39.0625C38.1875 18.644 37.5625 19.3315 37.5625 20.144C37.5625 21.019 38.1875 21.644 39.0625 21.644H43.0625C43.875 21.644 44.5625 21.019 44.5625 20.144C44.5625 19.3315 43.875 18.644 43.0625 18.644ZM34.5 21.144C34.5 16.3315 31.125 12.4565 26.5 11.4565V10.144C26.5 9.01904 25.625 8.14404 24.5 8.14404C23.4375 8.14404 22.5 9.01904 22.5 10.144C22.5 10.144 22.5 10.144 22.5625 10.144V11.4565C17.9375 12.4565 14.5625 16.3315 14.5625 21.144C14.5625 27.5815 12.25 29.519 11.0625 30.8315C10.75 31.144 10.5 31.644 10.5 32.144H10.5625C10.5625 33.2065 11.375 34.144 12.5625 34.144H36.5C37.75 34.144 38.5 33.2065 38.5625 32.144C38.5625 31.644 38.3125 31.144 38 30.8315C36.8125 29.519 34.5 27.5815 34.5 21.144ZM14.75 31.144C16.0625 29.394 17.5 26.519 17.5625 21.2065C17.5625 21.2065 17.5625 21.2065 17.5625 21.144C17.5625 17.3315 20.6875 14.144 24.5625 14.144C28.375 14.144 31.5625 17.3315 31.5625 21.144C31.5625 21.2065 31.5 21.2065 31.5 21.2065C31.5625 26.519 33 29.4565 34.3125 31.144H14.75ZM24.5625 40.144C26.75 40.144 28.5625 38.394 28.5625 36.144H20.5625C20.5625 38.394 22.3125 40.144 24.5625 40.144Z"
      fill="currentColor"
    />
  </svg>
);

const NotificationBell = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={NotificationBellSVG} {...props} />
);

export default NotificationBell;
