import { ReactNode } from 'react';
import { ReactComponent as Clone } from '../../assets/icons/clone.svg';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { Button } from '@squantumengine/horizon';

interface CopyProps {
  icon?: ReactNode;
  title: string;
  value: string;
  className?: string;
}

function Copy({ icon = <Clone />, title, value, className }: Readonly<CopyProps>) {
  const { copy } = useCopyToClipboard();
  return (
    <Button variant="text" onClick={() => copy(value)} className={className}>
      <span className="-translate-x-1 translate-y-1">{icon}</span>
      <span className="font-medium leading-4">{title}</span>
    </Button>
  );
}

export default Copy;
