import axiosInstance from '../config/axios/axiosWithToken';
import axios from 'axios';
import {
  ContactPayload,
  ContactUploadResponseInterface,
  ContactUploadStatusResponseInterface,
  FetchContactListPayloadInterface,
  FetchContactListResponseInterface,
  FetchUploadContactUrlResponseInterface,
  ContactDetailInterface,
  ContactPatchPayload,
  ExportContactQuery,
  GetAllContactResponse,
  GetAllContactFileIdResponse
} from '../shared/contact.interface';
import { ChannelKeyEnum } from '../shared/master-api.interface';

export const fetchContactList: (
  payload: FetchContactListPayloadInterface
) => Promise<FetchContactListResponseInterface> = async (payload) => {
  const { page, limit, q = '', import_status = '', channel_type = '', all_user = false } = payload;

  const response = await axiosInstance.get(
    `contact?page=${page}&limit=${limit}&q=${q}&import_status=${import_status}&channel_type=${channel_type}&all_user=${all_user}`
  );
  return response.data;
};

export const fetchContactDetail: (id: string) => Promise<ContactDetailInterface> = async (id) => {
  const response = await axiosInstance.get(`contact/${id}`);
  return response.data.data;
};

export const getContactUploadUrl: (params: {
  name: string;
}) => Promise<FetchUploadContactUrlResponseInterface> = async (params) => {
  const response = await axiosInstance.get(`contact/upload_url`, { params });
  return response.data;
};

export const addNewContact: (
  payload: ContactPayload
) => Promise<ContactUploadResponseInterface> = async (payload) => {
  const response = await axiosInstance.post(`contact`, payload);
  return response.data;
};

export const patchContact: (
  contactId: string,
  payload: ContactPatchPayload
) => Promise<ContactUploadResponseInterface> = async (contactId, payload) => {
  const response = await axiosInstance.post(`contact/${contactId}/user`, payload);
  return response.data;
};

export const putContactFile: (data: any, url: string, contentType: string) => Promise<any> = async (
  data,
  url,
  contentType
) => {
  const response = await axios.put(url, data, {
    headers: {
      'Content-Type': contentType
    }
  });
  return response.data;
};

export const getContactUploadStatus: (
  id: string
) => Promise<ContactUploadStatusResponseInterface> = async (id) => {
  const response = await axiosInstance.get(`contact/${id}`);
  return response.data;
};

export const deleteContact: (id: string) => Promise<void> = async (id) => {
  await axiosInstance.delete(`contact/${id}`);
};

export const getContactFileUrl: (id: string) => Promise<{ download_url: string }> = async (id) => {
  const response = await axiosInstance.get(`contact/${id}/export`);
  return response.data.data;
};

export const getAllContact: (params: {
  channel_type: ChannelKeyEnum;
}) => Promise<GetAllContactResponse> = async (params) => {
  const response = await axiosInstance.get(`contact/all_user`, { params });
  return response.data;
};

export const getAllContactFileId: (
  params: ExportContactQuery
) => Promise<GetAllContactFileIdResponse> = async (params) => {
  const response = await axiosInstance.get(`contact/export`, {
    params
  });
  return response.data.data;
};

export const getAllContactFileUrl: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.get(`contact/export/${id}`);

  return response.data.data;
};
