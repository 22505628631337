import { useContext, useEffect, useState } from 'react';
import { EditorConfigContext } from '../../../../context/editor-config-context';
import {
  useGetAutomatedCampaignDetail,
  useGetEmailChannelDetail
} from '../../../../hooks/automated-campaign-query';
import {
  EmailTemplateWidthStringType,
  EmailDetailInterface
} from '../../../../shared/automated-campaign.interface';
import { clamp } from '../../../../utils/math';
import { parseCSSSizeString } from '../../../../utils/cssSizeConverter';
import { EDITOR_WIDTH_LIMIT } from '../../../../static/rich-text-editor-config';
import { useGenerateChannelDetail } from '../../plugins/ActionsButtonPlugin/hooks/useGenerateChannelDetail';
import useCampaignParams from '../../../../hooks/useCampaignParams';

function limitedWidth(width: number): number {
  return clamp(EDITOR_WIDTH_LIMIT.MIN, width, EDITOR_WIDTH_LIMIT.MAX);
}

export function parseTemplateWidth(width?: EmailTemplateWidthStringType): number | undefined {
  const defaultValue = undefined;

  if (!width || width === '100%') return defaultValue;
  if (typeof width === 'number') return limitedWidth(width);
  if (typeof width !== 'string') return defaultValue;

  const { amount, unit } = parseCSSSizeString(width);
  if (unit !== 'px') return defaultValue;

  return limitedWidth(amount);
}

function useInitialHtml() {
  const { campaignId, channelId } = useCampaignParams();
  const { data, isFetched, isLoading } = useGetEmailChannelDetail(channelId);
  const { data: campaignDetail } = useGetAutomatedCampaignDetail(campaignId);
  useGenerateChannelDetail({ channelDetail: data, channelId });

  const { setMaxWidth } = useContext(EditorConfigContext);
  const [initialHtml, setInitialHtml] = useState('');
  const [initialJSON, setInitialJSON] = useState('');

  const setInitialHtmlFromData = () => {
    if (!data?.template) return;

    const { content, content_json, width } = data.template || {};
    const parsedWidth = parseTemplateWidth(width);
    setMaxWidth(parsedWidth);
    setInitialHtml(content);
    setInitialJSON(content_json);
  };

  useEffect(() => {
    isFetched && setInitialHtmlFromData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  return {
    campaignData: campaignDetail,
    channelData: data as EmailDetailInterface,
    initialHtml,
    initialJSON,
    isLoading
  };
}

export default useInitialHtml;
