import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

export const formatUnixTimestamp = (
  dateInMs: number,
  tz: string = 'Asia/Jakarta',
  locale: string = 'id',
  format: string = 'DD MMM YYYY, HH:mm'
): string => {
  return parseUnixDateInMs(dateInMs).tz(tz).locale(locale).format(format);
};

export const parseUnixDateInMs = (dateInMs?: number) =>
  dateInMs ? dayjs.unix(dateInMs / 1000) : dayjs();

const humanizeDurationStringifier = (ms: number) => {
  const day = Math.floor(ms / (24 * 60 * 60 * 1000));
  const hour = Math.floor(ms / (60 * 60 * 1000)) % 24;
  const minute = Math.floor(ms / (60 * 1000)) % 60;

  if (day > 0) {
    let string = `${day} hari`;
    if (hour > 0) string += ` ${hour} jam`;
    return string;
  }

  if (hour > 0) {
    let string = `${hour} jam`;
    if (minute > 0) string += ` ${minute} menit`;
    return string;
  }

  return `${Math.max(minute, 1)} menit`;
};

export const humanizeDuration = (ms: number, defaultValue: string = ''): string => {
  if (!ms) return defaultValue;
  if (ms < 0) ms = -ms;
  return humanizeDurationStringifier(ms);
};

export const dateFromToday = (date: string | number) => {
  const today = dayjs();
  const targetDate = dayjs(date);

  if (today.isAfter(targetDate)) return 'Kadaluarsa';
  if (today.isSame(targetDate, 'day')) return 'Hari ini';

  const diff = Math.ceil(targetDate.diff(today, 'day', true));
  return diff <= 3 ? `${diff} hari lagi` : '';
};
