import { NavigateFunction } from 'react-router';
import { ErrorMessage } from '../shared/errorMessage.enum';
import { HTTPErrorCode } from '../shared/errorCode.enum';

export const getErrorMessage = (error: any) =>
  error?.response?.data?.message || ErrorMessage.SystemError;

export const getErrorCode = (error: any) =>
  error?.response?.status || HTTPErrorCode.INTERNAL_SERVER_ERROR;

export const getErrorList = (error: any) => error?.response?.data?.errors || [];

export const redirectTo404 = (navigate: NavigateFunction, error: any) => {
  if (getErrorCode(error) === HTTPErrorCode.NOT_FOUND) {
    navigate('/404');
  }
};
