import { RouteScopeType } from '../../utils/route';
import useIsScopeAuthorized from './useIsScopeAuthorized';

interface ScopeWrapperProps {
  children: React.ReactNode;
  scope: RouteScopeType;
  defaultValue?: any;
}

function ScopeWrapper({ children, scope, defaultValue = null }: Readonly<ScopeWrapperProps>) {
  const hasAccess = useIsScopeAuthorized(scope);

  return hasAccess ? children : defaultValue;
}

export default ScopeWrapper;
